import { NotificationManager } from "react-notifications";
import { v4 as uuidv4 } from "uuid";
import * as THREE from "three";
import API, { graphqlOperation } from "@aws-amplify/api";
import { Storage } from "aws-amplify";

import IPartInfo from "../../interfaces/IPartInfo";
import { IScene } from "../../Scene";
import { IMatrix } from "../../interfaces/IMatrix";
import { PLYLoader } from "../../loaders/PLYLoader";
import dataStorage from "../../S3DataStore/S3DataStore";

import { getParts } from "../../graphql/queries";
import { checkCollisionAccurate, hullApproximation } from "../../WorkSpace/GetHullApproximate";
import { getBuildVolumeGridAndVisualizer } from "../../utils";

import { Line2 } from 'three/examples/jsm/lines/Line2.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';

const STLLoader = require("three-stl-loader")(THREE);
const stlLoader = new STLLoader();
const plyLoader = new PLYLoader();
const partColors = {
	standard: 0x005100,
	error: 0x700000,
	warn: 0x707000,
	scaledWarn: 0xf88379
};

interface IPartMeshPairing {
	part: IPartInfo;
	mesh?: THREE.Mesh;
	hull?: Object;
}

export interface IWorkspaceMeshManager {
	getListOfMeshes(): THREE.Mesh[];
	findPartForMesh(mesh: THREE.Mesh): IPartInfo;
	selectedMatrix: THREE.Group | null;
	updateParts(arg0: IPartInfo[], arg1: any): void;
	updateSelectedMatrix(arg0: IMatrix | null): void;
	snapObjectToFloor(arg0: THREE.Object3D): void;
	addListener(arg0: any): void;
	validateAllMeshes(): void;
	setBuildAreaVolume(volume: THREE.Box3): void;
	setMaxPartSize(size: THREE.Vector3): void;
	setMaxPartWeight(weight: number): void;
	setMaximumToolRadius(radius: number): void;
	setMaterialScaling(coeffXY: number, coeffZ: number): void;
	setScaling(mesh: THREE.Mesh, coeffXYZ: number): void;
	setMaterialDensity(density: number): void;
	getBuildAreaVolume(): THREE.Box3;
	updateScreen(arg0: boolean): void;
	syncPartAndMesh(part);
	centerAll(): void;
	packParts(): void;
	packOne(all: boolean): void;
	removeHighlightMesh(): void;
	highlightMeshNum(num: number): void;
	mirrorAll(): void;
}



class WorkspaceMeshManager implements IWorkspaceMeshManager {
	partMeshPairs: IPartMeshPairing[] = [];
	selectedMatrix: THREE.Group | null = null;
	scene: IScene;
	dispatch: any;
	listeners: any[];
	buildAreaVolume: THREE.Box3;
	maximumToolRadius: number
	materialScale: THREE.Vector3;
	partScale: THREE.Vector3;
	scaleMesh: THREE.Mesh | null;
	materialDensity: number;
	maxPartSize: THREE.Box3;
	maxPartWeight: number;

	purgeArea?: THREE.Mesh;

	highlightedMeshNumber: number = 0;
	lineSegments: THREE.Line2 | null = null;
	lineSegmentsMesh: THREE.Mesh | null = null;

	constructor(scene: IScene, dispatch, buildVolume: THREE.Box3) {
		this.updateParts = this.updateParts.bind(this);
		this.updateScreen = this.updateScreen.bind(this);

		this.setBuildAreaVolume = this.setBuildAreaVolume.bind(this);
		this.setMaxPartSize = this.setMaxPartSize.bind(this);
		this.setMaxPartWeight = this.setMaxPartWeight.bind(this);
		this.getListOfMeshes = this.getListOfMeshes.bind(this);
		this.findPartForMesh = this.findPartForMesh.bind(this);
		this.validateAllMeshes = this.validateAllMeshes.bind(this);
		this.setMaterialScaling = this.setMaterialScaling.bind(this);
		this.setScaling = this.setScaling.bind(this);
		this.setMaterialDensity = this.setMaterialDensity.bind(this);
		this.getBuildAreaVolume = this.getBuildAreaVolume.bind(this);
		this.setMaximumToolRadius = this.setMaximumToolRadius.bind(this)
		this.syncPartAndMesh = this.syncPartAndMesh.bind(this)
		this.centerAll = this.centerAll.bind(this)
		this.packParts = this.packParts.bind(this)
		this.packOne = this.packOne.bind(this)
		this.mirrorAll = this.mirrorAll.bind(this)

		this.scene = scene;
		this.maximumToolRadius = 0;
		this.dispatch = dispatch;
		this.listeners = [];
		this.materialScale = new THREE.Vector3(1, 1, 1);
		this.partScale = new THREE.Vector3(1, 1, 1);
		this.scaleMesh = null;
		this.materialDensity = 7.45;

		this.buildAreaVolume = buildVolume;
		this.maxPartSize = new THREE.Vector3(152.4, 152.4, 101.6);
		this.maxPartWeight = 8150;
		this.rescaleVolumeVisualizers();
	}

	setMaximumToolRadius(newRadius : number){
		this.maximumToolRadius = newRadius
	}

	updateScreen(screenChanged: boolean) {
		if (screenChanged) {
			let canvas = this.scene.renderer.domElement;
			let width = canvas.clientWidth;
			let height = canvas.clientHeight;
			if (width !== canvas.width || height !== canvas.height) {
				this.scene.renderer.setSize(width, height, false);
				this.scene.camera!.aspect = width / height;
				this.scene.camera!.updateProjectionMatrix();
			}
		}
		this.scene.render();
	}

	async updateParts(parts, screenName = '') {
		console.log('WorkspaceMeshManager', screenName)
		console.log(typeof (parts))
		
		if (typeof (parts) =="string"){
			parts = JSON.parse(parts)
			console.log('PARTSSSSSSS' + typeof (parts))
			const path = parts.Key
			const AWSBucketParam = {
				Bucket: Storage["_config"]["AWSS3"]["bucket"],
				Key: path,
				CacheControl: 'no-cache' // or 'max-age=0'
			};
			try {
				const getResult = await Storage.get(AWSBucketParam.Key, { download: true,  cacheControl: 'no-cache' });
				parts = JSON.parse(await (getResult.Body as any).text());
			} catch (error: any) {
				NotificationManager.error('An error occurred during the plate editing process');
				console.error("An error occurred during the plate editing process:", error.message);
			}
		}
		
		

		parts.forEach((part: IPartInfo) => {
			part.properties.Density = this.materialDensity;
			let partMesh = part.mesh;
			if (typeof partMesh === "undefined") {
				if (!this.partMeshDownloadingStarted(part)) {
					this.partMeshPairs.push({
						part: part
					});
					screenName !== '' ? this.downloadNewMesh(part) : this.downloadMesh(part);
				} else {
					const pair = this.findPairForPart(part)!;
					if (pair?.mesh) {
						this.deleteMesh(pair.mesh);
						screenName !== '' ? this.downloadNewMesh(part) : this.downloadMesh(part);
					}
				}
			} else {
				const pair = this.findPairForPart(part);
				if (pair?.mesh) {
					this.syncPartAndMesh(part);
				} else {
					this.partMeshPairs.push({
						part: part,
						mesh: partMesh
					});
					this.scene.scene.add(partMesh);
				}
			}
		});

		let deleteList: IPartMeshPairing[] = [];
		for (let pair of this.partMeshPairs) {
			const part = pair.part;
			if (parts.indexOf(part) < 0) {
				deleteList.push(pair);
			}
		}

		for (const trash of deleteList) {
			if (trash.mesh) {
				this.deleteMesh(trash.mesh!);
			}
			this.partMeshPairs = this.partMeshPairs.filter(pair => pair !== trash);
		}
		this.validateAllMeshes();
	}

	packParts() {
		const boxes = this.partMeshPairs.map(pair => new THREE.Box3().setFromObject(pair.mesh, true));
		if (boxes.length < 1)
			return;

		const spacing = Math.ceil(this.maximumToolRadius * 2);
		const bvSize = new THREE.Vector3();
		this.buildAreaVolume.getSize(bvSize);
		let floors = [new THREE.Vector3()];
		let bbox = new THREE.Box3();
		let packed_boxes = boxes.map(box => {
			const box_width = box.max.x - box.min.x;
			const box_height = box.max.y - box.min.y;
			for (let i = 0; i < floors.length; i++) {
				const floor = floors[i];
				if (floor.y > 0) {
					if (floor.y + box_height + spacing > bvSize.y) {
						// add new floor
						if (i === floors.length - 1)
							floors.push(new THREE.Vector3(bbox.max.x + spacing, 0));
						continue;
					}
					else if (i < floors.length - 1 && floor.x + box_width + spacing > floors[i + 1].x)
						continue;
					else
						floor.y += spacing;
				}
				let packed_box = new THREE.Box3().copy(box);
				packed_box.translate(new THREE.Vector3().subVectors(floor, box.min));
				floor.y += box_height;
				bbox.union(packed_box);
				return packed_box;
			}
		});

		const bvCenter = new THREE.Vector3();
		this.buildAreaVolume.getCenter(bvCenter);
		const bbCenter = new THREE.Vector3();
		bbox.getCenter(bbCenter);

		for (let i = 0; i < boxes.length; i++) {
			let part = this.partMeshPairs[i].part as IPartInfo;
			const center = new THREE.Vector3();
			boxes[i].getCenter(center);
			const loc = new THREE.Vector3();
			packed_boxes[i].getCenter(loc);
			loc.x = loc.x + 2 * (bbCenter.x - loc.x);
			const position = part.properties.translate;
			const move = new THREE.Vector3().addVectors(position, bvCenter).add(loc).sub(center).sub(bbCenter);
			part.properties.translate = { x: move.x, y: move.y, z: position.z - boxes[i].min.z};
			this.syncPartAndMesh(part, false);
			part.userData.newPart = false;
		}
		this.validateAllMeshes();
	}

	packOne(all: boolean = false) {
		const eps = 1e-4;
		const check_box = (box, check, spacing) => {
			return box.min.x - spacing >= check.max.x ||
				box.max.x + spacing <= check.min.x ||
				box.min.y - spacing >= check.max.y ||
				box.max.y + spacing <= check.min.y;
		}

		const check_all = (box, checks, spacing) => {
			for (let i = 0; i < checks.length; i++) {
				if (!check_box(box, checks[i], spacing))
					return false;
			}
			return true;
		}

		const is_better = (pt1, pt2) => {
			return pt2 === undefined || pt1.x < pt2.x - eps || (pt1.x < pt2.x + eps && pt1.y < pt2.y);
		}

		let boxes = this.partMeshPairs.map(pair => new THREE.Box3().setFromObject(pair.mesh, true));
		if (boxes.length < 1)
			return;

		let new_boxes = [] as THREE.Box3[];
		if (all) {
			new_boxes = boxes;
			boxes = [];
		}
		else {
			this.mirrorAll(false);
			boxes = this.partMeshPairs.map(pair => new THREE.Box3().setFromObject(pair.mesh, true));
			new_boxes.push(boxes.pop());
		}

		const spacing = Math.ceil(this.maximumToolRadius * 2);
		const bvSize = new THREE.Vector3();
		this.buildAreaVolume.getSize(bvSize);
		let bbox = new THREE.Box3();
		boxes.forEach((box) => { bbox.union(box) });

		new_boxes.forEach((box) => {
			let best;
			let size = new THREE.Vector3();
			box.getSize(size);

			const check_point = (pt) => {
				if (pt.y - bbox.min.y + size.y <= bvSize.y && is_better(pt, best)) {
					let pt_box = new THREE.Box3().copy(box);
					pt_box.translate(new THREE.Vector3().subVectors(pt, box.min));
					if (check_all(pt_box, boxes, spacing - eps))
						best = pt;
				}
			}
			if (!bbox.isEmpty() && box === new_boxes[0])
				check_point(new THREE.Vector3(bbox.min.x, bbox.min.y, 0));
			boxes.forEach((nbr) => {
				const top = new THREE.Vector3(nbr.min.x, nbr.max.y + spacing, 0);
				check_point(top);
				const right = new THREE.Vector3(nbr.max.x + spacing, nbr.min.y, 0);
				check_point(right);
			});
			if (best === undefined) {
				best = bbox.isEmpty() ? new THREE.Vector3() : new THREE.Vector3(bbox.max.x + spacing, bbox.min.y, 0);
			}

			let move = new THREE.Vector3().subVectors(best, box.min);
			let part = this.partMeshPairs[boxes.length].part as IPartInfo;
			part.userData.newPart = false;
			const position = part.properties.translate;
			part.properties.translate = { x: position.x + move.x, y: position.y + move.y, z: position.z + box.min.z };
			this.syncPartAndMesh(part, false);
			box.translate(move);
			bbox.union(box);
			boxes.push(box);
		});

		this.mirrorAll(false);
		this.centerAll();
	}

	validateAllMeshes() {
		let newParts = 0;
		for (let index in this.partMeshPairs) {
			const pair = this.partMeshPairs[index];
			if (pair.mesh === undefined)
				return;
			this.checkMesh(pair);
			if (pair.part.userData.newPart)
				newParts++;
		}
		if (newParts > 0) {
			this.packOne(newParts > 1);
			return;
		}

		let extendedArea = new THREE.Box3().copy(this.buildAreaVolume);
		extendedArea.min.z -= 0.0001 // some extension
		const isOutsideBuildEnvelope = (mesh) => {
			const meshBoundingBox = new THREE.Box3().setFromObject(mesh, true);
			let ok = extendedArea.containsBox(meshBoundingBox);
			if (ok && this.purgeArea !== undefined) {
				const purgeBoundingBox = new THREE.Box3().setFromObject(this.purgeArea);
				ok = !purgeBoundingBox.intersectsBox(meshBoundingBox);
			};
			return !ok;
		};
		const isTooLarge= (mesh) => {
			const meshBoundingBox = new THREE.Box3().setFromObject(mesh, true);
			let meshSize = new THREE.Vector3();
			meshBoundingBox.getSize(meshSize);
			return meshSize.x > this.maxPartSize.x * this.materialScale.x ||
				meshSize.y > this.maxPartSize.y * this.materialScale.y ||
				meshSize.z > this.maxPartSize.z * this.materialScale.z;
		};
		const isTooHeavy = (part) => {
			if (part.mesh.userData.metrics?.volume) {
				const weight = part.properties.Density * part.mesh.userData.metrics.volume / 1000;
				return weight > this.maxPartWeight;
			};
			return false;
		};
		const isScaled = (part) => {
			return part.properties.ScaleFactor <= 0.99 || part.properties.ScaleFactor >= 1.01;
		};
		for (let i = 0; i < this.partMeshPairs.length; i++) {
			const pair = this.partMeshPairs[i]
			if (pair.hull === undefined) {
				this.snapObjectToFloor(pair.mesh, false);
				pair.hull = hullApproximation(pair.mesh, 0.1, this.maximumToolRadius);
			}
			pair.mesh.userData.intersectsOtherMesh = false;
		}

		for (let i = 0; i < this.partMeshPairs.length; i++) {
			const pair = this.partMeshPairs[i]
			for (let j = i + 1; j < this.partMeshPairs.length; j++) {
				const other = this.partMeshPairs[j]
				if (checkCollisionAccurate(pair.hull, other.hull)) {
					pair.mesh.userData.intersectsOtherMesh = true
					other.mesh.userData.intersectsOtherMesh = true
				}
			}
			pair.part.userData.newPart = false;
			pair.mesh.userData.outsideBuildEnvelope = isOutsideBuildEnvelope(pair.mesh);
			pair.mesh.userData.tooLarge = isTooLarge(pair.mesh);
			pair.mesh.userData.tooHeavy = isTooHeavy(pair.part);
			pair.mesh.userData.scaled = isScaled(pair.part);
			WorkspaceMeshManager.updateMeshColor(pair.mesh)
		}

		this.updateScreen(true);
	}

	private partMeshDownloadingStarted(part: IPartInfo): boolean {
		return this.partMeshPairs.findIndex(
			(pair: IPartMeshPairing) => pair.part.UUID === part.UUID
		) >= 0;
	}

	getBuildAreaVolume(): THREE.Box3 {
		return this.buildAreaVolume
	}

	private findPairForPart(part: IPartInfo): IPartMeshPairing | undefined {
		return this.partMeshPairs.find((pair: IPartMeshPairing) => pair.part === part);
	}

	checkMesh(pair) {
		if (pair?.part?.mesh !== pair?.mesh) {
			console.log('Sync part mesh', pair.mesh)
			pair!.part!.mesh = pair.mesh;
		}
	}

	findPartForMesh(mesh: THREE.Mesh): IPartInfo {
		const pair = this.partMeshPairs.find(search_pair => search_pair.mesh === mesh)!;
		this.checkMesh(pair);
		return pair?.part;
	}

	getListOfMeshes(): THREE.Mesh[] {
		return this.partMeshPairs
			.map((pair: IPartMeshPairing) => pair.mesh)
			.filter((result: THREE.Mesh | undefined) => result) as THREE.Mesh[];
	}

	updateSelectedMatrix(matrix: IMatrix | null) {
		if (matrix === null) {
			if (this.selectedMatrix) {
				this.scene.scene.remove(this.selectedMatrix);
				for (let listener of this.listeners) {
					listener.onDelete(this.selectedMatrix);
				}
			}
			this.selectedMatrix = null;
		} else {
			if (this.selectedMatrix === null) {
				this.selectedMatrix = new THREE.Group();
			}
			matrix.vizualizationGroup = this.selectedMatrix;

			let children = [...this.selectedMatrix.children]
			for (const child of children) {
				this.selectedMatrix.remove(child);
			}

			this.scene.scene.remove(this.selectedMatrix);

			const baseMesh = matrix.baseMesh;
			let box = new THREE.Box3().setFromObject(baseMesh);
			let meshSize = new THREE.Vector3();
			box.getSize(meshSize);

			for (let row = 0; row < matrix.rows; row++) {
				for (let column = 0; column < matrix.columns; column++) {
					let mesh = baseMesh.clone();
					mesh.material = (mesh.material as THREE.Material).clone();

					mesh.translateX(column * (meshSize.x + matrix.columnGap));
					mesh.translateY(-row * (meshSize.y + matrix.rowGap));

					this.selectedMatrix.add(mesh);
				}
			}

			this.scene.scene.add(this.selectedMatrix);
		}
		this.scene.render();
	}

	setBuildAreaVolume(volume: THREE.Box3) {
		this.buildAreaVolume = volume;
		this.rescaleVolumeVisualizers();
		let newSizeVector = new THREE.Vector3()
		volume.getSize(newSizeVector);
		this.scene.updateBuildPlateSize(volume);
		this.validateAllMeshes();
	}

	setMaxPartSize(size: THREE.Vector3) {
		if (size) {
			this.maxPartSize = size;
		}
		else {
			this.maxPartSize = new THREE.Vector3(152.4, 152.4, 101.6);
		}
		this.validateAllMeshes();
	}

	setMaxPartWeight(weight: number) {
		if (weight) {
			this.maxPartWeight = weight;
		}
		else {
			this.maxPartWeight = 8150;
		}
		this.validateAllMeshes();
	}

	setMaterialScaling(coeffXY, coeffZ) {
		if (!coeffXY) {
				coeffXY = 1
		}
		if (!coeffZ) {
			coeffZ = 1
		}
		this.materialScale = new THREE.Vector3(coeffXY, coeffXY, coeffZ);
		this.applyScaling();
	}

	setScaling(mesh, coeffXYZ) {
		if (!coeffXYZ) {
			coeffXYZ = 1
		}
		this.partScale = new THREE.Vector3(coeffXYZ, coeffXYZ, coeffXYZ);
		this.scaleMesh = mesh;
		this.validateAllMeshes()
	}

	setMaterialDensity(density) {
		if (density) {
			this.materialDensity = density;
		}
		else {
			this.materialDensity = 7.45;
		}
		this.partMeshPairs.forEach((pair) => {
			pair.part.properties.Density = this.materialDensity;
		});
		this.validateAllMeshes()
	}

	applyScaling() {
		for (let index in this.partMeshPairs) {
			const pair = this.partMeshPairs[index]
			if (pair.mesh !== undefined) {
				this.checkMesh(pair);
				const oldBox = new THREE.Box3().setFromObject(pair.mesh, true);
				let oldCenter = new THREE.Vector3();
				oldBox.getCenter(oldCenter);
				this.syncPartAndMesh(pair.part, false);
				const newBox = new THREE.Box3().setFromObject(pair.mesh, true);
				let newCenter = new THREE.Vector3();
				newBox.getCenter(newCenter);
				if (oldCenter !== newCenter || newBox.min.z != 0) {
					pair.part.properties.translate = {
						x: pair.part.properties.translate.x + oldCenter.x - newCenter.x,
						y: pair.part.properties.translate.y + oldCenter.y - newCenter.y,
						z: pair.part.properties.translate.z - newBox.min.z
					}
					this.syncPartAndMesh(pair.part, false);
				}
			}
		}
		this.validateAllMeshes()
	}

	private rescaleVolumeVisualizers() {
		getBuildVolumeGridAndVisualizer(this.scene, this.buildAreaVolume);
	}

	highlightMeshNum(index: number) {
		if (index >= this.partMeshPairs.length)  return;

		this.highlightedMeshNumber = index;
		const pair = this.partMeshPairs[index]
		const mesh: THREE.Mesh | null = pair?.mesh;
		if (!mesh) return;
		this.removeHighlightMesh();
		this.addBottomHighlight(mesh);
	}

	removeHighlightMesh() {
		if (!this.lineSegments) return;

		this.scene.scene.remove(this.lineSegments);
		this.lineSegments.geometry.dispose();
		(this.lineSegments.material as LineMaterial).dispose();
		this.lineSegments = null;
		this.lineSegmentsMesh = null;
		this.scene.render();
	}

	private addBottomHighlight(mesh: THREE.Mesh) {
		if (this.lineSegments) return;

		const boundingBox = new THREE.Box3();
		mesh.updateMatrixWorld(true);
		boundingBox.setFromObject(mesh);

		const min = boundingBox.min;
		const max = boundingBox.max;

		const bottomVertices = [
			new THREE.Vector3(min.x, min.y, min.z),
			new THREE.Vector3(max.x, min.y, min.z),
			new THREE.Vector3(max.x, max.y, min.z),
			new THREE.Vector3(min.x, max.y, min.z),
		];

		const bottomEdges = [
			bottomVertices[0], bottomVertices[1],
			bottomVertices[1], bottomVertices[2],
			bottomVertices[2], bottomVertices[3],
			bottomVertices[3], bottomVertices[0],
		];

		const bottomEdgePositions = new Float32Array(bottomEdges.length * 3);
		for (let i = 0; i < bottomEdges.length; i++) {
			bottomEdgePositions[i * 3] = bottomEdges[i].x;
			bottomEdgePositions[i * 3 + 1] = bottomEdges[i].y;
			bottomEdgePositions[i * 3 + 2] = bottomEdges[i].z;
		}

		const bottomEdgesGeometry = new THREE.BufferGeometry();
		bottomEdgesGeometry.setAttribute('position', new THREE.BufferAttribute(bottomEdgePositions, 3));

		this.lineSegmentsMesh = mesh;

		const bottomPositions = bottomEdgesGeometry.attributes.position.array;

		if (!this.lineSegments) {
			const lineGeometry = new LineGeometry();
			lineGeometry.setPositions(bottomPositions);

			const lineMaterial = new LineMaterial({
				color: 0xffff00,
				linewidth: 25.01,
				resolution: new THREE.Vector2(window.innerWidth, window.innerHeight),
			});

			this.lineSegments = new Line2(lineGeometry, lineMaterial);
		}

		this.scene.scene.add(this.lineSegments);
		this.scene.render();
	}

	private static updateMeshColor(mesh: THREE.Mesh) {
		const material = mesh.material as THREE.MeshLambertMaterial;
		if (mesh.userData.outsideBuildEnvelope || mesh.userData.intersectsOtherMesh) {
			material.color.setHex(partColors.error);
		} else if (mesh.userData.tooLarge || mesh.userData.tooHeavy) {
			material.color.setHex(partColors.warn);
		} else if (mesh.userData.scaled) {
			material.color.setHex(partColors.scaledWarn);
		} else {
			material.color.setHex(partColors.standard);
		}
		if (!material.transparent) {
			material.transparent = true;
			material.opacity = 0.7;
			material.side = THREE.DoubleSide;
			material.needsUpdate = true;
		}
	}

	public newDownloadObjectFile = async (fileType: string) => {

		const getResult = await Storage.get(fileType, {
			download: true,
		})
		return await (getResult as any).Body.arrayBuffer();
	};


	public graphqlGetParts = async (PartID: string) => {
		return API.graphql(
			graphqlOperation(getParts, {
				id: PartID,
			})
		);
	};


	private downloadNewMesh = async (part: IPartInfo) => {
		const uuidLoading = uuidv4();
		this.dispatch({
			type: 'addLoading',
			cargo: {
				uuidLoading: uuidLoading
			}
		})

		this.graphqlGetParts(part.properties.PartID).then(
			obj => {
				if (!obj["data"]["getParts"]) return;
				// console.log('graphqlGetParts obj~~~',obj)
				const p = obj["data"]["getParts"];
				const filesList = p.files;
				const filesListObj = JSON.parse(filesList);
				const typeToLoad = filesListObj['data_small'] ? "data_small" : "data"
				let meshFileName = filesListObj[typeToLoad];
				console.log('Started~~~')
				this.newDownloadObjectFile(meshFileName)
					.then((meshFile: ArrayBuffer) => {
						// dataStorage().getObjectFilePath(
						//     "Parts",
						//     part.properties.PartID,
						//     typeToLoad
						// ).then( meshFileName => {
						console.log('completed~~~')
						let ext = meshFileName.split(".").pop().toLowerCase();
						let mesh = WorkspaceMeshManager.parseMesh(meshFile, ext);


						mesh.name = `Part ${part.UUID}.${ext}`;
						let userData: any = mesh.userData;
						userData.UUID = part.UUID;
						userData.PartID = part.properties.PartID;

						if (p.metrics && p.metrics[0]) {
							mesh.userData.metrics = JSON.parse(p.metrics[0]);
						}
						part.mesh = mesh;
						const pair = this.findPairForPart(part)
						pair!.mesh = mesh;

						if (pair?.part) {
							this.syncPartAndMesh(pair?.part)
						}
						this.scene.scene.add(mesh);
						this.snapObjectToFloor(mesh);
						WorkspaceMeshManager.updateMeshColor(mesh)
						this.scene.render()
					})

					.catch((ex) => {
						NotificationManager.error(
							`Couldn't download mesh for Part ${part.properties.PartID}. ${ex}`
						);
					})
					.finally(() => {
						this.dispatch({
							type: 'removeLoading',
							cargo: {
								uuidLoading: uuidLoading
							}
						})
					})
				// })
			}
		)
			.catch(() => {
				NotificationManager.error(
					`Couldn't download mesh for Part ${part.properties.PartID}.`
				);
			});

	}

	private downloadMesh(part: IPartInfo) {
		const uuidLoading = uuidv4();
		this.dispatch({
			type: 'addLoading',
			cargo: {
				uuidLoading: uuidLoading
			}
		})


		dataStorage().objectStores['Parts'].getObject(part.properties.PartID).then(
			obj => {
				const typeToLoad = obj.files['data_small'] ? "data_small" : "data"
				dataStorage()
					.downloadObjectFile("Parts", part.properties.PartID, typeToLoad)
					.then((meshFile: ArrayBuffer) => {
						dataStorage().getObjectFilePath(
							"Parts",
							part.properties.PartID,
							typeToLoad
						).then(meshFileName => {
							let ext = meshFileName.split(".").pop().toLowerCase();
							let mesh = WorkspaceMeshManager.parseMesh(meshFile, ext);

							mesh.name = `Part ${part.UUID}.${ext}`;
							let userData: any = mesh.userData;
							userData.UUID = part.UUID;
							userData.PartID = part.properties.PartID;

							if (obj.metrics && obj.metrics[0]) {
								mesh.userData.metrics = JSON.parse(obj.metrics[0]);
							}
							part.mesh = mesh;
							let pair = this.findPairForPart(part)!
							pair!.mesh = mesh;

							this.syncPartAndMesh(part);
							this.scene.scene.add(mesh);
							this.snapObjectToFloor(mesh);
						})

							.catch((ex) => {
								NotificationManager.error(
									`Couldn't download mesh for Part ${part.properties.PartID}. ${ex}`
								);
							})
							.finally(() => {
								this.dispatch({
									type: 'removeLoading',
									cargo: {
										uuidLoading: uuidLoading
									}
								})
							})
					})
			}
		)
			.catch(() => {
				NotificationManager.error(
					`Couldn't download mesh for Part ${part.properties.PartID}.`
				);
			});

	}

	syncPartAndMesh(part: IPartInfo, validate: boolean = true) {

		let partMesh = part.mesh;

		if (!partMesh)
			return

		if (part.properties.ScaleFactor)
			part.properties.scale = new THREE.Vector3(this.materialScale.x * part.properties.ScaleFactor, this.materialScale.y * part.properties.ScaleFactor, this.materialScale.z * part.properties.ScaleFactor)
		else
			part.properties.scale = new THREE.Vector3(this.materialScale.x, this.materialScale.y, this.materialScale.z)

		if (!part?.properties?.rotate) {
			part.properties.rotate = new THREE.Vector3()
		}
		if (!part?.properties?.translate) {
			part.properties.translate = new THREE.Vector3()
		}
		let scale = new THREE.Matrix4().makeScale(part.properties.scale.x, part.properties.scale.y, part.properties.scale.z);
		let translate = new THREE.Matrix4().makeTranslation(part.properties.translate.x, part.properties.translate.y, part.properties.translate.z);
		let rotate = new THREE.Matrix4().makeRotationFromEuler(new THREE.Euler(part.properties.rotate.x, part.properties.rotate.y, part.properties.rotate.z));
		partMesh.position.set(part.properties.translate.x, part.properties.translate.y, part.properties.translate.z);
		partMesh.rotation.set(part.properties.rotate.x, part.properties.rotate.y, part.properties.rotate.z);
		partMesh.matrixAutoUpdate = false
		partMesh.matrixWorldNeedsUpdate = true
		const oldMatrix = partMesh.matrix;
		partMesh.matrix = translate.multiply(scale.multiply(rotate));
		if (part.userData.newPart || !oldMatrix.equals(partMesh.matrix)) {
			const pair = this.findPairForPart(part) as IPartMeshPairing;
			pair.hull = undefined;
			if (validate)
				this.validateAllMeshes();
			else
				this.scene.render();
		}
	}

	addListener(component: any) {
		this.listeners.push(component);
	}

	private deleteMesh(mesh: THREE.Mesh) {
		this.scene.scene.remove(mesh);
		mesh.geometry.dispose();
		(mesh.material as THREE.MeshLambertMaterial).dispose();

		for (let listener of this.listeners) {
			listener.onDelete(mesh);
		}
	}

	private static parseMesh(buffer: ArrayBuffer, ext: string): THREE.Mesh {
		switch (ext) {
			case "stl":
				let stlobject = stlLoader.parse(buffer);
				return new THREE.Mesh(
					stlobject,
					new THREE.MeshLambertMaterial({ color: partColors.standard })
				);
			case "ply":
				let plyObject = plyLoader.parse(buffer);

				if (plyObject.attributes.normal === undefined) {
					plyObject.computeVertexNormals();
				}

				return new THREE.Mesh(
					plyObject,
					new THREE.MeshLambertMaterial({ color: partColors.standard })
				);
			default:
				NotificationManager.error(`.${ext} format is unsuported`);
				return new THREE.Mesh();
		}
	}

	snapObjectToFloor(snapedMesh: THREE.Object3D, validate: boolean = true) {
		let snappedBoundingBox = new THREE.Box3().setFromObject(snapedMesh, true);
		if (snappedBoundingBox.min.z !== 0) {
			const part = this.findPartForMesh(snapedMesh)
			part.properties.translate.z -= snappedBoundingBox.min.z
			this.syncPartAndMesh(part, validate)
		}
	}

	centerAll() {
		let bbox = new THREE.Box3();
		this.partMeshPairs.forEach((pair) => {
			bbox.union(new THREE.Box3().setFromObject(pair.mesh, true));
		});
		const bbCenter = new THREE.Vector3();
		bbox.getCenter(bbCenter);
		const bvCenter = new THREE.Vector3();
		this.buildAreaVolume.getCenter(bvCenter);
		const move = new THREE.Vector3().subVectors(bvCenter, bbCenter);
		if (move.x !== 0 || move.y !== 0) {
			for (let i = 0; i < this.partMeshPairs.length; i++) {
				const pair = this.partMeshPairs[i];
				pair.part.properties.translate = {
					x: pair.part.properties.translate.x + move.x,
					y: pair.part.properties.translate.y + move.y,
					z: pair.part.properties.translate.z
				};
				this.syncPartAndMesh(pair.part, false);
			}
		}
		this.validateAllMeshes();
	}

	mirrorAll(validate: boolean = true) {
		const bvCenter = new THREE.Vector3();
		this.buildAreaVolume.getCenter(bvCenter);
		this.partMeshPairs.forEach((pair) => {
			let bbox = new THREE.Box3().setFromObject(pair.mesh, true);
			const bbCenter = new THREE.Vector3();
			bbox.getCenter(bbCenter);
			pair.part.properties.translate = {
				x: pair.part.properties.translate.x + 2 * (bvCenter.x - bbCenter.x),
				y: pair.part.properties.translate.y,
				z: pair.part.properties.translate.z
			};
			this.syncPartAndMesh(pair.part, false);
		});
		if (validate)
			this.validateAllMeshes();
	}
}

export default WorkspaceMeshManager;
