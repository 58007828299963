import React from "react";
import { Object3D, MathUtils, Box3} from "three";
import { Mode } from "./WorkspaceSceneController";
import IPartInfo from "../interfaces/IPartInfo";

export interface ICoordinateEntry {
  name: string;
  currentValue: number;
  onChange: (arg0: number) => void;
  inputDom: HTMLInputElement | null;
  inputRefCallback: React.RefCallback<HTMLInputElement>;
}
interface IProps {
  mesh: Object3D;
  planeZ: Object3D;
  mode: Mode;
  scaleFactor: number;
  setScaleFactor: (mesh: Object3D, scale: number) => void;
  partForMesh: (mesh: Object3D) => IPartInfo;
  updater: () => void;
  refreshToken: boolean;
}

export const CoordinateDisplay: React.FunctionComponent<IProps> = (props) => {
  let [coordinateSet, changeCoordinateSet] = React.useState<ICoordinateEntry[]>(
    []
  );
  function truncated(num, decimalPlaces) {    
    const numPowerConverter = Math.pow(10, decimalPlaces);
    return ~~(num * numPowerConverter)/numPowerConverter;
  }
  function truncateNumber(num, decimalPlaces) {
    return Number(num.toFixed(decimalPlaces));
  }
  React.useEffect(() => {
    switch (props.mode) {
      case Mode.Translate:
        coordinateSet = ["x", "y"].map(
          (coord, index): ICoordinateEntry => {
            return Object({
              name: coord,
              currentValue: truncated( props.mesh.position[coord], 2 ),
              onChange: (value: number) => {
                props.mesh.position[coord] = value;
                props.updater();
                coordinateSet[index].currentValue = value
              },
              inputDom: null,
              inputRefCallback: (el) => (coordinateSet[index].inputDom = el),
            });
          }
        );
        changeCoordinateSet(coordinateSet);
        break;
      case Mode.Rotate:
        coordinateSet = ["x", "y", "z"].map((coord, index): ICoordinateEntry => {
          return Object(
        {
            name: coord,
            currentValue: truncated(MathUtils.radToDeg(props.mesh.rotation[coord]),2),
            onChange: (value: number) => {
              props.mesh.rotation[coord] = MathUtils.degToRad(value);
              props.updater();
            },
            inputDom: null,
            inputRefCallback: (el) => (coordinateSet[index].inputDom = el  + 33),
          })
        })
        changeCoordinateSet(coordinateSet);
        break;
      case Mode.Scale:
        coordinateSet = ["z"].map(
          (coord, index): ICoordinateEntry => {
            return Object({
              name: "scale",
              currentValue: truncated(props.partForMesh(props.mesh)?.properties?.ScaleFactor ?? 1, 2),
              onChange: (value: number) => {
                if (value < 0.01)
                  value = 0.1;

                props.setScaleFactor(props.mesh, value);
                
                props.updater();
                coordinateSet[index].currentValue = value
              },
              inputDom: null,
              inputRefCallback: (el) => (coordinateSet[index].inputDom = el),
            });
          }
        );
        changeCoordinateSet(coordinateSet);
        break;
      case Mode.DualZ:
        coordinateSet = ["z"].map((coord, index): ICoordinateEntry => {
          return Object(
        {
            name: coord,
            currentValue: truncated(props.planeZ.position.z, 2),
            onChange: (value: number) => {
              const boundingBox = new Box3().setFromObject(props.mesh, true);
              props.planeZ.position.z = Math.max(0, Math.min(boundingBox.max.z, value));
              props.updater();
            },
            inputDom: null,
            inputRefCallback: (el) => (coordinateSet[index].inputDom = el),
          })
        })
        changeCoordinateSet(coordinateSet);
        break;
    }
  }, [props.mode, JSON.stringify(props.mesh.rotation), JSON.stringify(props.mesh.position), JSON.stringify(props.mesh.scale), JSON.stringify(props.planeZ.position.z), props.scaleFactor]);

  React.useEffect(() => {
    let propertyName = props.mode === Mode.Scale ? "scale" : props.mode === Mode.Translate ? "position" :  "rotation";
    for (let coordinateEntry of coordinateSet) {
      let coordinateValue = props.mesh[propertyName][coordinateEntry.name];
      if (props.mode === Mode.Rotate) {
        coordinateValue = MathUtils.radToDeg(coordinateValue);
      } else if (props.mode === Mode.DualZ) {
        coordinateValue = truncated(props.planeZ.position.z, 2);
      }
      coordinateValue = truncated(coordinateValue, 2);
      if (props.mode === Mode.Scale) {
        coordinateValue = truncateNumber(props.partForMesh(props.mesh)?.properties?.ScaleFactor ?? 1, 2);
        props.setScaleFactor(props.mesh, coordinateValue);
      }
      let input = coordinateEntry.inputDom;
      if (input) {
        input!.value = coordinateValue;
      } else {
        coordinateEntry.inputRefCallback = (el) => {
          if (el) {
            coordinateEntry.inputDom = el;
            el.value = coordinateValue;
          }
        };
      }
    }
  }, [props.refreshToken, props.mode, JSON.stringify(props.mesh.rotation), JSON.stringify(props.mesh.position), JSON.stringify(props.mesh.scale), JSON.stringify(props.planeZ.position.z), props.scaleFactor]);

  return (
    <div style={{ position: "absolute", top: 10, left: 75 }}>
      <div style={{ border: "solid", backgroundColor: "white" }}>
        {coordinateSet.map((coordinateEntry, i) => (
          <div key={i} style={{marginLeft: 5}}>
            {coordinateEntry.name.toUpperCase()}:
            <input
              type="number"
              ref={coordinateEntry.inputRefCallback}
              style={{ width: 100, marginLeft: 5 }}
              defaultValue={coordinateEntry.currentValue}
              step={0.1}
              {...((props.mode === Mode.DualZ || props.mode === Mode.Scale) && { min: 0 })}
              {...(props.mode === Mode.Scale && {
                onInput : (ev) => {
                  const input = ev.target as HTMLInputElement;
                  const value = parseFloat(input.value);
  
                  if (value < 0.01) {
                    input.value = '0.1';
                  }
                }
              })}
              onChange={(ev) => {
                  let number = parseFloat((ev.target as any).value);
                  if (!Number.isNaN(number)) {
                    coordinateEntry.onChange(number);
                  }
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
