import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ProgressBar from "react-bootstrap/ProgressBar";
import LoadingComponent from "../../LoadingComponent/LoadingComponent";
import {
  onAddPartCloseDialog,
  onAddPartFileChange,
  onAddPartNameChange,
  onAddPart,
} from "../Actions/Parts";
import { IState, IAddPartModalStatus } from "../Interfaces";

const Props = (state: IState, ownProps: any) => {
  const addPartModalStatus = state.data.modals
    .addPartModalStatus as IAddPartModalStatus;

  const props = {
    isModalOpen: addPartModalStatus.isOpen,
    inputText: addPartModalStatus.inputText,
    hasError: addPartModalStatus.hasError,
    errorMessage: addPartModalStatus.errorMessage,
    isValid: addPartModalStatus.isValid,
    addPartStatus: addPartModalStatus.addPartStatus,
    addPartProgress: addPartModalStatus.addPartProgress,
  };

  return props;
};

const Actions = {
  onAddPartCloseDialog: onAddPartCloseDialog,
  onAddPartFileChange: onAddPartFileChange,
  onAddPartNameChange: onAddPartNameChange,
  onAddPart: onAddPart,
};

const ImportPartMethod = (props) => {
  const [visible, setVisible] = useState(true);

  const onAddPartFileChange = (event) => {
    setVisible(true);
    const file = event.target.files && event.target.files[0];
    props.onAddPartFileChange(file);
  }
  
  const onAddPartNameChange = (event) => {
    const value = event.target.value.split(/[^a-zA-Z0-9._-]/).join("");
    props.onAddPartNameChange(value);
  }
  
  const onDeleteImportPart = () => {
    setVisible(false);
    props.onAddPartNameChange("");
    props.onAddPartFileChange('');
  }

  return (

    <>
      {props.isModalOpen ? (
        <>
          <Modal
            show={props.isModalOpen}
            onHide={() => props.onAddPartCloseDialog()}
            backdrop="static"
            keyboard={false}
            dialogClassName="partsDialog"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <Modal.Title>Import Parts</Modal.Title>
              <span className="close-btn"
                onClick={() =>
                  props.onAddPartCloseDialog()
                }>
              </span>
            </Modal.Header>
            <Modal.Body>
              <div>
                <label>
                  Part Name
                </label>
                <div className="pb-4">
                  <input
                    className="f-w"
                    type="text"
                    value={props.inputText}
                    onChange={(event) => onAddPartNameChange(event)}
                    placeholder='Enter Name Here'
                  />
                </div>
                <label>Add Files (STL, STEP)</label>
                <div className="drop-zone mb-3">
                  <input
                    type="file"
                    onChange={(event) => onAddPartFileChange(event)}
                    multiple={false}
                  />
                  <img src="/upload-icon.svg" alt="upload-file" />
                  <p>Drag and Drop, or click to Browse.</p>
                </div>
                {visible && (
                  <label hidden={!props.inputText}>
                    <img src="/tick.svg" alt="import file" className="pr-3" />{props.inputText}
                    <span className="remove-import" onClick={onDeleteImportPart}></span>
                  </label>
                )}
                <div
                  className="invalid-feedback d-block"
                  hidden={!props.hasError}
                >
                  {props.errorMessage}
                </div>
              </div>
              {props.addPartProgress && (
                <>
                  <ProgressBar
                    variant="success"
                    now={props.addPartProgress}
                  />
                  <div>
                    <label>{`${props.addPartProgress}%`}</label>
                  </div>
                </>
              )}
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
              <Button
                className="btn btn-secondary w-50"
                onClick={() =>
                  props.onAddPartCloseDialog()
                }
              >
                Cancel
              </Button>
              <Button
                className="btn btn-primary"
                onClick={() => props.onAddPart()}
                autoFocus
              >
                Done Importing
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      ) : null}
    </>
  );
}

const AddPart = withRouter(connect(Props, Actions)(ImportPartMethod));

export default AddPart;
