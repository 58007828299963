import API, { graphqlOperation } from "@aws-amplify/api";

import { deleteBuild, createBuild } from "../../../../graphql/mutations";

import ACTION_TYPES from "../../../actionTypes";
import { IBuild, IState } from "../../../Interfaces";
import { onNavigateToHomePage } from "../../Home";
import onResetSearch  from "../../Search/reset"
import { onDisplayLoader } from "../../Search";
import onEnableSendToPrinter from "../New/enableSendToPrinter";

const onDeletebuildDone = (deleteBuild) => {
  return {
    type: ACTION_TYPES.BUILD.DELETE.ACTION,
    payload: deleteBuild,
  };
};
const onNewBuildSuccess = (buildToBerebuild) => {
  return {
    type: ACTION_TYPES.BUILD.REBUILD.ACTION,
    payload: buildToBerebuild,
  };
};

const onRebuild = (buildId, history) => {
  return async (dispatch, getState) => {
    const state = getState() as IState;
    
    if (state.data.search.isSearchEnabled) {
      dispatch(onDisplayLoader(true))
    }
  
    const buildToBeRebuild  = !state.data.search.isSearchEnabled ?  (state.data.builds.loadedBuilds.find(
      (b) => b.id === buildId
    ) as IBuild) : state.data.search.highlightedBuildData as IBuild


    const deleteBuildVariables = {
      input: {
        id: buildToBeRebuild.id,
        _version: buildToBeRebuild._version,
      },
    };
   
    try {
      const result = await API.graphql(
        graphqlOperation(deleteBuild, deleteBuildVariables)
      ) as any;

      if (result) {
        const newBuild = { ...result.data.deleteBuild } as any;
        newBuild.current_status = "new";
        newBuild.build_result = "";

        newBuild.created_at = new Date().toISOString();

        delete newBuild._deleted;
        delete newBuild._lastChangedAt;
        delete newBuild.createdAt;
        delete newBuild.updatedAt;
        delete newBuild._version;
        newBuild.modified_by = state.creator as string;
        newBuild.modified_at = new Date().toISOString();
        const sendToPrinter ={
          selectedPrinter:state.data.info.sendToPrinter.selectedGrafanaBundle,
          sendToPrinter: state.data.info.sendToPrinter.isEnabled || false
        }
        newBuild['sendToPrinter'] = JSON.stringify(sendToPrinter)
        
        const newBuildvariables = {
          input: newBuild,
        };
        try {
          const newBuildresult = await API.graphql(
            graphqlOperation(createBuild, newBuildvariables)
          );
          const addedBuild = (newBuildresult as any)?.data?.createBuild as IBuild;
          // SW-2056 Removing duplicate entry ( Rebuild )
          // dispatch(onNewBuildSuccess(addedBuild));
          let sendToPrinter={
            isEnabled:false,
            selectedGrafanaBundle:''
          }
           dispatch(onEnableSendToPrinter(sendToPrinter))
          if (state.data.search.isSearchEnabled) {
            dispatch(onDisplayLoader(false))
            dispatch(onNavigateToHomePage(history));
            dispatch(onResetSearch())
            
          }
         
         
        } catch (ex: any) {
          if (state.data.search.isSearchEnabled) {
            dispatch(onDisplayLoader(false))
            dispatch(onResetSearch())

          }
          console.error('Exception ', ex, ' updating failed');
        }
      }
    } catch (ex: any) {
			console.error('Exception ', ex, ' updating failed');
      if (state.data.search.isSearchEnabled) {
        dispatch(onDisplayLoader(false))
        dispatch(onResetSearch())
      }
    }
   

  };
};

export default onRebuild;
