import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { onHighlightFolder, onHighlightFolderId, onUnarchiveFolder, onArchiveFolder, onDeleteFolder, onRenameFolderOpenDialog } from '../../../Actions/Folders';
import { IState } from '../../../Interfaces';
import { Menu, MenuItem } from '@material-ui/core';

const Props = (state: IState, ownProps: any) => {

    const props = {
        isLoadingComplete: state.data.folders.isLoadingComplete as boolean,
        displayFolder: ownProps.displayFolder,
    };

    return props;
};

const Actions = {
    onHighlightFolder: onHighlightFolder,
    onHighlightFolderId: onHighlightFolderId,
    onRenameFolderOpenDialog: onRenameFolderOpenDialog,
    onUnarchiveFolder: onUnarchiveFolder,
    onArchiveFolder: onArchiveFolder,
    onDeleteFolder: onDeleteFolder,
};

class Component extends React.Component<any, any> {
    constructor(props) {
      super(props);
      this.state = {
        anchorEl: null,
      };
    }
    handleClose = () => {
        this.setState({ anchorEl: null });
    };
    public render() {
        const { anchorEl } = this.state;
        return (
            <>
                <div>
        <div>
          <div
            className="display-list-more-action"
            aria-controls={`simple-menu`}
            aria-haspopup="true"
            id="dropdown-basic"
            onClick={(event) => { 
                event.stopPropagation();
                this.setState({ anchorEl: event.currentTarget });
            }}
            style={{ position: "relative" }}
          >
            <div className="options right-justify">
              <div className="more-check option">
                <img className="icon more-check" alt="" />
              </div>
            </div>
          </div>
          <Menu
            id={`simple-menu`}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            style={{
              position: "absolute",
              top: "38px",
            }}
          >
            <MenuItem onClick={() => { this.props.onDeleteFolder(this.props.displayFolder.id); this.handleClose() }}>
                Delete
            </MenuItem>
          </Menu>
        </div>
      </div>
            </>
        );
    }
}

const DisplayFolderFolderOptions = withRouter(connect(Props, Actions)(Component));

export default DisplayFolderFolderOptions;