import IPartInfo from "../../../../interfaces/IPartInfo";
import { IState, IPart, IConfiguration, IPlate } from "../../../Interfaces";

const onClearAddedPartsReducer = (state: IState, action) => {
    
    const newState = { ...state } as IState;
    if(newState.data.plates.highlightedPlateId) {
        const getHighlightedPlate : IPlate = newState.data.plates.loadedPlates.find((el) => el.id === newState.data.plates.highlightedPlateId) as IPlate;
        if(getHighlightedPlate?.s3PartsString.length) {
            getHighlightedPlate.parts = getHighlightedPlate?.s3PartsString ? getHighlightedPlate.s3PartsString : [];
            getHighlightedPlate.s3PartsString = [];
            newState.data.plates.loadedPlates = newState.data.plates.loadedPlates.map((plateItem) => {
                if(plateItem.id === newState.data.plates.highlightedPlateId) {
                    return getHighlightedPlate;
                }
                return plateItem;
            });
        }
    }
    newState.data.workspace.addedParts = [] as IPart[];
    newState.data.workspace.state.build.parts = [] as IPartInfo[];
    newState.data.workspace.addedPartsSelectedPartConfigurations = [] as IConfiguration[];
    newState.data.workspace.addedPartsSelectedPartSlicerConfigurations = [] as IConfiguration[];
    newState.data.workspace.isLoadingInProgress = !newState.data.workspace.isLoadingInProgress; 
    
    return newState;
};

export default onClearAddedPartsReducer;