import { API, graphqlOperation } from "aws-amplify";
import { IBuild, IState } from "../../../Interfaces";
import { updateBuild } from '../../../../graphql/mutations';

const onUpdateBuild = (buildId,printerId,history) => {
    return async (dispatch, getState) => {
        const state = getState() as IState;

        const buildToBeUpdated = !state.data.search.isSearchEnabled ? state.data.builds.loadedBuilds.find(b => b.id === buildId) as IBuild : state.data.search.highlightedBuildData as IBuild;
        const sendToPrinterObj ={
            selectedPrinter: printerId,
            sendToPrinter: true
        }
        buildToBeUpdated['sendToPrinter'] =JSON.stringify(sendToPrinterObj)
        buildToBeUpdated.created_at = new Date().toISOString();

        delete buildToBeUpdated._deleted;
        delete buildToBeUpdated._lastChangedAt;
        delete buildToBeUpdated.createdAt;
        delete buildToBeUpdated.updatedAt;
        buildToBeUpdated.modified_by = state.creator as string;
        buildToBeUpdated.modified_at = new Date().toISOString();


        const variables = {
            input: buildToBeUpdated,
        };

        const result = await API.graphql(graphqlOperation(updateBuild, variables));
        // const updatedBuild = ((result as any)?.data?.updateBuild) as IBuild;
    };
};

export default onUpdateBuild;