import API, { graphqlOperation } from '@aws-amplify/api';

import { createPlate } from '../../../../graphql/mutations';

import ACTION_TYPES from '../../../actionTypes';
import { ICopyPlateModalStatus, IPlate, IState } from '../../../Interfaces';
import { onDisplayLoader, onResetSearch } from '../../Search';
import { onNavigateToHomePage } from '../../Home';

import { NotificationManager } from "react-notifications";
import awsconfig from '../../../../aws-exports'; // Ensure this path is correct for your project
import { Amplify, Storage } from 'aws-amplify';
import { makeRequest } from '../../../../utils';

Amplify.configure(awsconfig);
const onCopyPlateInit = () => {
    return {
        type: ACTION_TYPES.PLATE.COPY.INIT,
        payload: null,
    };
};

const onCopyPlateSuccess = (copiedPlate) => {
    return {
        type: ACTION_TYPES.PLATE.COPY.SUCCESS,
        payload: copiedPlate,
    };
};

const onCopyPlateFail = (errorMessage: string) => {
    NotificationManager.error('Failure on Copy Plate');
    console.error('Failure on Copy Plate - ', errorMessage);
    return {
        type: ACTION_TYPES.PLATE.COPY.FAIL,
        payload: errorMessage,
    };
};

const onCopyPlateDone = () => {
    return {
        type: ACTION_TYPES.PLATE.COPY.DONE,
        payload: null,
    };
};

const onCopyPlate = (history) => {
    return async (dispatch, getState) => {

        dispatch(onCopyPlateInit());

        const state = getState() as IState;

        const copyPlateModalStatus = state.data.modals.copyPlateModalStatus as ICopyPlateModalStatus;

        const isPlateNameEmpty = (!copyPlateModalStatus.inputText) as boolean;
        const isPlateNameExists = !state.data.search.isSearchEnabled ? (state.data.plates.loadedPlates.some(p => p.name === copyPlateModalStatus.inputText)
        ) as boolean : (state.data.search.searchPlatesResults.some(p => p.name === copyPlateModalStatus.inputText)
        ) as boolean
      

        if (isPlateNameEmpty) {
            const errorMessage = 'Plate Name is mandatory.' as string;
            dispatch(onCopyPlateFail(errorMessage));
            dispatch(onCopyPlateDone());
            return;
        }
        if (isPlateNameExists) {
            const errorMessage = 'Plate Name already exists.' as string;
            dispatch(onCopyPlateFail(errorMessage));
            dispatch(onCopyPlateDone());
            return;
        }

        try {
            let pathForSavingToS3;
            const state = getState() as IState;
            const plateToBeCopied = !state.data.search.isSearchEnabled ? { ...state.data.plates.loadedPlates.find(p => p.id === state.data.plates.highlightedPlateId) } as IPlate
                : state.data.search.highlightedPlateData as any
            if (
                plateToBeCopied &&
                plateToBeCopied.parts[0] &&
                JSON.parse(plateToBeCopied.parts[0]).Key === `PlatePartsFiles/${plateToBeCopied.id}.json`
            ) {
                const path = `PlatePartsFiles/${plateToBeCopied.id}.json`;
                const AWSBucketParam = {
                    Bucket: Storage["_config"]["AWSS3"]["bucket"],
                    Key: path,
                    CacheControl: 'no-cache' // or 'max-age=0'
                };
                try {
                    const getResult = await Storage.get(AWSBucketParam.Key, { download: true,  cacheControl: 'no-cache' });
                    plateToBeCopied.parts = JSON.parse(await (getResult.Body as any).text());
                    const partsArrayToS3 = plateToBeCopied['parts'];

                    const fileName = copyPlateModalStatus.inputText + '.json';
                    pathForSavingToS3 = `PlatePartsFiles/${fileName}`;
                    const AWSBucketParamForSavingToS3 = {
                        Bucket: Storage["_config"]["AWSS3"]["bucket"],
                        Key: pathForSavingToS3,
                        CacheControl: 'no-cache' // or 'max-age=0'
                    };

                    try {
                        await Storage.put(pathForSavingToS3, partsArrayToS3, {
                            contentType: "application/json",
                            completeCallback: (event) => console.log(`Successfully uploaded ${event.key}`),
                            progressCallback: (progress) => console.log(`Uploaded: ${progress.loaded}/${progress.total}`),
                            errorCallback: (error: any) => {
                                dispatch(onCopyPlateFail("Failure on Copy Plate"));
                                console.error("Failure on Copy Plate:" + error.message);
                            }

                        });
                    } catch (error: any) {
                        dispatch(onCopyPlateFail("Failure on Copy Plate"));
                        console.error("Failure on Copy Plate:" + error.message);
                    }
                    plateToBeCopied['parts'] = JSON.stringify(AWSBucketParamForSavingToS3);

                } catch (error: any) {
                    dispatch(onCopyPlateFail("Failure on Copy Plate"));
                    console.error("Failure on Copy Plate:" + error.message);
                }
               
            }  
            plateToBeCopied.id = copyPlateModalStatus.inputText;
            plateToBeCopied.name = copyPlateModalStatus.inputText;
            plateToBeCopied.plate_search_string = copyPlateModalStatus.inputText?.toLowerCase();
            delete plateToBeCopied._deleted;
            delete plateToBeCopied.archive;
            delete plateToBeCopied._version;
            delete plateToBeCopied._lastChangedAt;
            delete plateToBeCopied.createdAt;
            delete plateToBeCopied.updatedAt;
            delete plateToBeCopied.s3PartsString;
            plateToBeCopied.hasBuilds = false;
            plateToBeCopied.builds = [];
            plateToBeCopied.created_by = state.creator as string;
            plateToBeCopied.created_at = new Date().toISOString();
            plateToBeCopied.modified_by = state.creator as string;
            plateToBeCopied.modified_at = new Date().toISOString();
            plateToBeCopied.dumb = 1;
            const variables = {
                input: plateToBeCopied,
            };
            const createGQLOperation = async () => {
                const result = await API.graphql(graphqlOperation(createPlate, variables));
                const copiedPlate = ((result as any)?.data?.createPlate) as IPlate;
                dispatch(onCopyPlateSuccess(copiedPlate));
            }
            
            const renderMeshResponse: any = await makeRequest(
                "POST",
                "https://beta.mantle3d.com/render_mesh/",
                {
                    bucket: "vizapp-s3125945-bv",
                    table: "Parts-5e4bhziwdval7nm3edjsmffm44-bv",
                    plate: plateToBeCopied.id,
                }
            );
            const partsObject = JSON.parse(plateToBeCopied.parts);
            partsObject.Thumbnail = JSON.parse(renderMeshResponse);
            const getThumbnailImage: any = await Storage.get(JSON.parse(renderMeshResponse)[0], { download: true,  cacheControl: 'no-cache' });
            const reader = new FileReader();
            reader.readAsDataURL(getThumbnailImage.Body);
            reader.onload = async () => {
                partsObject.ThumbnailImage = reader.result;
                plateToBeCopied['parts'] = JSON.stringify(partsObject);
                createGQLOperation();
            };
            reader.onerror = () => {
                plateToBeCopied['parts'] = JSON.stringify(partsObject);
                createGQLOperation();
            }
            dispatch(onCopyPlateDone());
            if (state.data.search.isSearchEnabled) {
                dispatch(onDisplayLoader(false))
                dispatch(onNavigateToHomePage(history))
                dispatch(onResetSearch())
            }
        } catch (graphqlError) {
            const errorMessage = `Copy plate failed: ${Object((graphqlError as any)?.errors?.[0]?.message).toString()}`;
            dispatch(onCopyPlateFail(errorMessage));
            dispatch(onCopyPlateDone());
            dispatch(onDisplayLoader(false))
        }
    };
};

export default onCopyPlate;