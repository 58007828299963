import { API, graphqlOperation } from 'aws-amplify';
import { createPlate } from '../../../../graphql/mutations';
import ACTION_TYPES from '../../../actionTypes';
import { getNewPlate } from '../../../Instances';
import { INewPlateModalStatus, IPlate, IState } from '../../../Interfaces';
import { onNavigateToHomePage, onNavigateToPlatesPage } from '../../Home';
import onUpdatePartAfterNewPlate from './updatePart';
import onUpdateConfiguraitonAfterNewPlate from './updateConfiguration';
import { NotificationManager } from "react-notifications";
import { getPlateSearch } from '../../../../graphql/queries';
import IPartInfo from '../../../../interfaces/IPartInfo';
import { onAddPartsToAddedParts } from '../../Parts';
import { onLoadPartIds } from '../../Home/Load/loadParts';
import onNewPlateCloseDialog from './newPlateCloseDialog';
import onHighlightPlate from '../Highlight/highlightPlate';
import awsmobile from '../../../../aws-exports';
import awsconfig from '../../../../aws-exports'; // Ensure this path is correct for your project
import { Amplify, Storage } from 'aws-amplify';
import onUpdatePlate from '../Update/updatePlate';
import { onHighlightFolder, onHighlightFolderId } from '../../Folders';

Amplify.configure(awsconfig);
const onNavigateToPlatesPageInit = (isNewPlate) => {
    return {
        type: ACTION_TYPES.HOME.NAVIGATE.PAGES.PLATES.INIT,
        payload: isNewPlate,
    };
};

const onNavigateToPlatesPageDone = () => {
    return {
        type: ACTION_TYPES.HOME.NAVIGATE.PAGES.PLATES.DONE,
        payload: null,
    };
};

const onNewPlateSuccess = (addedPlate) => {
    return {
        type: ACTION_TYPES.PLATE.ADD.SUCCESS,
        payload: addedPlate,
    };
};

const onNewPlateFail = (errorMessage: string) => {
    //NotificationManager.error('Failure on New Plate');
    console.error(`Failure on New Plate - ${errorMessage}`);
    return {
        type: ACTION_TYPES.PLATE.ADD.FAIL,
        payload: errorMessage,
    };
};

const onNewPlate = (history) => {
    return async (dispatch, getState) => {

        const state = getState() as IState;

        const newPlateModalStatus = state.data.modals.newPlateModalStatus as INewPlateModalStatus;

        const isPlateNameEmpty = (!newPlateModalStatus.inputText) as boolean;

        const hasParts = state.data.workspace.state.build.parts.length > 0 as boolean;

        if (isPlateNameEmpty) {
            const errorMessage = 'Plate Name is mandatory.' as string;
            dispatch(onNewPlateFail(errorMessage));
            return;
        }

        if (!hasParts) {
            const errorMessage = 'Parts not selected.' as string;
            dispatch(onNewPlateFail(errorMessage));
            return;
        }

        try {
            let path;
            let newPlate;
            const plateSearch = await API.graphql(
                graphqlOperation(getPlateSearch, {
                    id: newPlateModalStatus.inputText,
                })
            )
            const result = (plateSearch as any)?.data?.getPlateSearch
            if (result) {
                const errorMessage = 'Plate Name already exists.' as string;
                dispatch(onNewPlateFail(errorMessage));
                return;
            }
            else {
                try {
                    const state = getState() as IState;
                    newPlate = getNewPlate(state);
                    newPlate['noOfParts'] = newPlate['parts'].length;
                    const partsArrayToS3 = newPlate['parts'];
                    const fileName = newPlateModalStatus.inputText + '.json';
                    path = `PlatePartsFiles/${fileName}`;
                    const AWSBucketParam = {
                        Bucket: Storage["_config"]["AWSS3"]["bucket"],
                        Key: path,
                        CacheControl: 'no-cache' // or 'max-age=0'
                    };
                    try {
                        await Storage.put(path, partsArrayToS3, {
                            contentType: "application/json",
                            completeCallback: (event) => console.log(`Successfully uploaded ${event.key}`),
                            progressCallback: (progress) => console.log(`Uploaded: ${progress.loaded}/${progress.total}`),
                            errorCallback: (error: any) => {
                                dispatch(onNewPlateFail("Create plate failed"));
                                console.error("Create plate failed:" + error.message);
                            }
                        });
                    } catch (error: any) {
                        dispatch(onNewPlateFail("Create plate failed"));
                        console.error("Create plate failed:" + error.message);
                    }

                    newPlate['parts'] = JSON.stringify(AWSBucketParam);
                    delete newPlate.s3PartsString;
                    const variables = {
                        input: newPlate,
                    };
                    const result = await API.graphql(graphqlOperation(createPlate, variables));
                    const addedPlate = ((result as any)?.data?.createPlate) as IPlate;    
                    dispatch(onNavigateToPlatesPageInit(true));
                    dispatch(onHighlightFolder(""));
                    dispatch(onHighlightFolderId(""));
                    history.push({
                        pathname: '/dashboard/plates/edit',
                        state: { params: undefined },
                    })
                    onLoadPartIds()(dispatch, getState);
                    dispatch(onHighlightPlate(newPlateModalStatus.inputText))
                    dispatch(onNavigateToPlatesPageDone());
                    dispatch(onNewPlateCloseDialog())

                 
                  

                    addedPlate.parts = partsArrayToS3;
                    const partIdsToBeUpdated = addedPlate.parts.map(part => JSON.parse(part).properties.PartID);
                    const partsToBeUpdated = state.data.parts.loadedParts.filter(lp => partIdsToBeUpdated.some(id => id === lp.id));                 
                    const groupedPartsToBeUpdated = partIdsToBeUpdated.reduce((acc, filename) => {
                        if (!acc[filename]) {
                            acc[filename] = 0;
                        }
                        acc[filename]++;
                        return acc;
                    }, {});

                    partsToBeUpdated.forEach(part =>{
                        let noOfPartInstance = groupedPartsToBeUpdated[part.id];
                        dispatch(onUpdatePartAfterNewPlate(part, addedPlate,noOfPartInstance))
                    })
                    const configIdsToBeUpdated = ([] as string[]).concat(addedPlate.machineId, addedPlate.recipeId, addedPlate.millConfigId, addedPlate.materialConfigId) as string[];
                    const configsToBeUpdated = state.data.configurations.loadedConfigurations.filter(lc => configIdsToBeUpdated.some(id => id === lc.id));
                    configsToBeUpdated.forEach(config => dispatch(onUpdateConfiguraitonAfterNewPlate(config, addedPlate)));
                    dispatch(onUpdatePlate(history, "true"));
                } catch (graphqlError) {
                   
                    if (Object((graphqlError as any)?.errors?.[0]?.message).toString().includes("Item size has exceeded the maximum allowed size")){
                        const errorMessage = "Your request exceeds maximum number of parts that can be added"
                        dispatch(onNewPlateFail(errorMessage));
                    }
                    else{
                        const errorMessage = `Create plate failed: ${Object((graphqlError as any)?.errors?.[0]?.message).toString()}`;
                        dispatch(onNewPlateFail(errorMessage));
                    }
                    
                    
                   
                }

            }
        } catch (graphqlError) {
            const errorMessage = ` Unable to search if the entered plate name already exists or not due to ${Object(
                (graphqlError as any)?.errors?.[0]?.message
            ).toString()}`;
            dispatch(onNewPlateFail(errorMessage));
            console.log(errorMessage)
        }



    };
};

export default onNewPlate;