/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createBuild = /* GraphQL */ `mutation CreateBuild(
  $input: CreateBuildInput!
  $condition: ModelBuildConditionInput
) {
  createBuild(input: $input, condition: $condition) {
    id
    name
    hasPlates
    plates
    created_by
    created_at
    current_status
    dumb
    build_result
    machineId
    recipeId
    millConfigId
    materialConfigId
    path
    gcodeUUID
    bundle
    build_time
    canceled_by
    messages
    print
    moho_version
    marker_name
    Min_Layer
    Max_Layer
    Max_Slab
    Slab_Layers
    Contouring
    Process
    cloudWatchLogName
    overrides
    machineConfig
    millConfig
    materialConfig
    recipe
    parts
    build_json
    machine_info
    result_json
    gcode_uuid
    cloudWatch
    folders
    hasFolders
    tags
    hasTags
    hasParts
    build_messages
    build_search_string
    modified_by
    modified_at
    metrics
    build_complexity_status
    noOfParts
    total_time
    extruded
    purged
    sendToPrinter
    scaling
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.CreateBuildMutationVariables,
  APITypes.CreateBuildMutation
>;
export const updateBuild = /* GraphQL */ `mutation UpdateBuild(
  $input: UpdateBuildInput!
  $condition: ModelBuildConditionInput
) {
  updateBuild(input: $input, condition: $condition) {
    id
    name
    hasPlates
    plates
    created_by
    created_at
    current_status
    dumb
    build_result
    machineId
    recipeId
    millConfigId
    materialConfigId
    path
    gcodeUUID
    bundle
    build_time
    canceled_by
    messages
    print
    moho_version
    marker_name
    Min_Layer
    Max_Layer
    Max_Slab
    Slab_Layers
    Contouring
    Process
    cloudWatchLogName
    overrides
    machineConfig
    millConfig
    materialConfig
    recipe
    parts
    build_json
    machine_info
    result_json
    gcode_uuid
    cloudWatch
    folders
    hasFolders
    tags
    hasTags
    hasParts
    build_messages
    build_search_string
    modified_by
    modified_at
    metrics
    build_complexity_status
    noOfParts
    total_time
    extruded
    purged
    sendToPrinter
    scaling
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.UpdateBuildMutationVariables,
  APITypes.UpdateBuildMutation
>;
export const deleteBuild = /* GraphQL */ `mutation DeleteBuild(
  $input: DeleteBuildInput!
  $condition: ModelBuildConditionInput
) {
  deleteBuild(input: $input, condition: $condition) {
    id
    name
    hasPlates
    plates
    created_by
    created_at
    current_status
    dumb
    build_result
    machineId
    recipeId
    millConfigId
    materialConfigId
    path
    gcodeUUID
    bundle
    build_time
    canceled_by
    messages
    print
    moho_version
    marker_name
    Min_Layer
    Max_Layer
    Max_Slab
    Slab_Layers
    Contouring
    Process
    cloudWatchLogName
    overrides
    machineConfig
    millConfig
    materialConfig
    recipe
    parts
    build_json
    machine_info
    result_json
    gcode_uuid
    cloudWatch
    folders
    hasFolders
    tags
    hasTags
    hasParts
    build_messages
    build_search_string
    modified_by
    modified_at
    metrics
    build_complexity_status
    noOfParts
    total_time
    extruded
    purged
    sendToPrinter
    scaling
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.DeleteBuildMutationVariables,
  APITypes.DeleteBuildMutation
>;
export const createParts = /* GraphQL */ `mutation CreateParts(
  $input: CreatePartsInput!
  $condition: ModelPartsConditionInput
) {
  createParts(input: $input, condition: $condition) {
    id
    name
    creator
    created_at
    modifier
    modified_at
    files
    icon
    dumb
    archive
    folders
    hasFolders
    tags
    hasTags
    hasPlates
    plates
    hasBuilds
    builds
    metrics
    parts_search_string
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.CreatePartsMutationVariables,
  APITypes.CreatePartsMutation
>;
export const updateParts = /* GraphQL */ `mutation UpdateParts(
  $input: UpdatePartsInput!
  $condition: ModelPartsConditionInput
) {
  updateParts(input: $input, condition: $condition) {
    id
    name
    creator
    created_at
    modifier
    modified_at
    files
    icon
    dumb
    archive
    folders
    hasFolders
    tags
    hasTags
    hasPlates
    plates
    hasBuilds
    builds
    metrics
    parts_search_string
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.UpdatePartsMutationVariables,
  APITypes.UpdatePartsMutation
>;
export const deleteParts = /* GraphQL */ `mutation DeleteParts(
  $input: DeletePartsInput!
  $condition: ModelPartsConditionInput
) {
  deleteParts(input: $input, condition: $condition) {
    id
    name
    creator
    created_at
    modifier
    modified_at
    files
    icon
    dumb
    archive
    folders
    hasFolders
    tags
    hasTags
    hasPlates
    plates
    hasBuilds
    builds
    metrics
    parts_search_string
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.DeletePartsMutationVariables,
  APITypes.DeletePartsMutation
>;
export const createConfigurations = /* GraphQL */ `mutation CreateConfigurations(
  $input: CreateConfigurationsInput!
  $condition: ModelConfigurationsConditionInput
) {
  createConfigurations(input: $input, condition: $condition) {
    id
    name
    type
    created_at
    modified_at
    data
    dumb
    default
    hasPlates
    plates
    creator
    modifier
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.CreateConfigurationsMutationVariables,
  APITypes.CreateConfigurationsMutation
>;
export const updateConfigurations = /* GraphQL */ `mutation UpdateConfigurations(
  $input: UpdateConfigurationsInput!
  $condition: ModelConfigurationsConditionInput
) {
  updateConfigurations(input: $input, condition: $condition) {
    id
    name
    type
    created_at
    modified_at
    data
    dumb
    default
    hasPlates
    plates
    creator
    modifier
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.UpdateConfigurationsMutationVariables,
  APITypes.UpdateConfigurationsMutation
>;
export const deleteConfigurations = /* GraphQL */ `mutation DeleteConfigurations(
  $input: DeleteConfigurationsInput!
  $condition: ModelConfigurationsConditionInput
) {
  deleteConfigurations(input: $input, condition: $condition) {
    id
    name
    type
    created_at
    modified_at
    data
    dumb
    default
    hasPlates
    plates
    creator
    modifier
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.DeleteConfigurationsMutationVariables,
  APITypes.DeleteConfigurationsMutation
>;
export const createConfigurationsAllHistory = /* GraphQL */ `mutation CreateConfigurationsAllHistory(
  $input: CreateConfigurationsAllHistoryInput!
  $condition: ModelConfigurationsAllHistoryConditionInput
) {
  createConfigurationsAllHistory(input: $input, condition: $condition) {
    id
    ConfigurationGroupId
    name
    type
    created_at
    data
    dumb
    default
    hasPlates
    plates
    eventName
    modified_at
    creator
    modifier
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.CreateConfigurationsAllHistoryMutationVariables,
  APITypes.CreateConfigurationsAllHistoryMutation
>;
export const updateConfigurationsAllHistory = /* GraphQL */ `mutation UpdateConfigurationsAllHistory(
  $input: UpdateConfigurationsAllHistoryInput!
  $condition: ModelConfigurationsAllHistoryConditionInput
) {
  updateConfigurationsAllHistory(input: $input, condition: $condition) {
    id
    ConfigurationGroupId
    name
    type
    created_at
    data
    dumb
    default
    hasPlates
    plates
    eventName
    modified_at
    creator
    modifier
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.UpdateConfigurationsAllHistoryMutationVariables,
  APITypes.UpdateConfigurationsAllHistoryMutation
>;
export const deleteConfigurationsAllHistory = /* GraphQL */ `mutation DeleteConfigurationsAllHistory(
  $input: DeleteConfigurationsAllHistoryInput!
  $condition: ModelConfigurationsAllHistoryConditionInput
) {
  deleteConfigurationsAllHistory(input: $input, condition: $condition) {
    id
    ConfigurationGroupId
    name
    type
    created_at
    data
    dumb
    default
    hasPlates
    plates
    eventName
    modified_at
    creator
    modifier
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.DeleteConfigurationsAllHistoryMutationVariables,
  APITypes.DeleteConfigurationsAllHistoryMutation
>;
export const createPrint = /* GraphQL */ `mutation CreatePrint(
  $input: CreatePrintInput!
  $condition: ModelPrintConditionInput
) {
  createPrint(input: $input, condition: $condition) {
    id
    bundle_UUID
    start
    end
    visionImages
    thermalImages
    operationTimes
    printerId
    comment
    buildID
    estTotalTime
    totalPercentage
    totalPaste
    printerOwner
    ETA
    numberOfLayers
    layerStatus
    printFeedbackId
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.CreatePrintMutationVariables,
  APITypes.CreatePrintMutation
>;
export const updatePrint = /* GraphQL */ `mutation UpdatePrint(
  $input: UpdatePrintInput!
  $condition: ModelPrintConditionInput
) {
  updatePrint(input: $input, condition: $condition) {
    id
    bundle_UUID
    start
    end
    visionImages
    thermalImages
    operationTimes
    printerId
    comment
    buildID
    estTotalTime
    totalPercentage
    totalPaste
    printerOwner
    ETA
    numberOfLayers
    layerStatus
    printFeedbackId
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.UpdatePrintMutationVariables,
  APITypes.UpdatePrintMutation
>;
export const deletePrint = /* GraphQL */ `mutation DeletePrint(
  $input: DeletePrintInput!
  $condition: ModelPrintConditionInput
) {
  deletePrint(input: $input, condition: $condition) {
    id
    bundle_UUID
    start
    end
    visionImages
    thermalImages
    operationTimes
    printerId
    comment
    buildID
    estTotalTime
    totalPercentage
    totalPaste
    printerOwner
    ETA
    numberOfLayers
    layerStatus
    printFeedbackId
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.DeletePrintMutationVariables,
  APITypes.DeletePrintMutation
>;
export const createPrinter = /* GraphQL */ `mutation CreatePrinter(
  $input: CreatePrinterInput!
  $condition: ModelPrinterConditionInput
) {
  createPrinter(input: $input, condition: $condition) {
    id
    name
    GrafanaBundle
    currentBundle
    bundlePrintStart
    currentStatus
    lastStatusSync
    currentPrint
    currentLayer
    currentStage
    currentPasteAmount
    printEndTime
    pasteEndTime
    lastSyncTime
    lastVisionPath
    lastThermalPath
    currentPrintNameOnPrinter
    remainingPasteConsumption
    remainingPrintTime
    creator
    created_at
    modifier
    modified_at
    tools
    printerPauseCommandStatus
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.CreatePrinterMutationVariables,
  APITypes.CreatePrinterMutation
>;
export const updatePrinter = /* GraphQL */ `mutation UpdatePrinter(
  $input: UpdatePrinterInput!
  $condition: ModelPrinterConditionInput
) {
  updatePrinter(input: $input, condition: $condition) {
    id
    name
    GrafanaBundle
    currentBundle
    bundlePrintStart
    currentStatus
    lastStatusSync
    currentPrint
    currentLayer
    currentStage
    currentPasteAmount
    printEndTime
    pasteEndTime
    lastSyncTime
    lastVisionPath
    lastThermalPath
    currentPrintNameOnPrinter
    remainingPasteConsumption
    remainingPrintTime
    creator
    created_at
    modifier
    modified_at
    tools
    printerPauseCommandStatus
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.UpdatePrinterMutationVariables,
  APITypes.UpdatePrinterMutation
>;
export const deletePrinter = /* GraphQL */ `mutation DeletePrinter(
  $input: DeletePrinterInput!
  $condition: ModelPrinterConditionInput
) {
  deletePrinter(input: $input, condition: $condition) {
    id
    name
    GrafanaBundle
    currentBundle
    bundlePrintStart
    currentStatus
    lastStatusSync
    currentPrint
    currentLayer
    currentStage
    currentPasteAmount
    printEndTime
    pasteEndTime
    lastSyncTime
    lastVisionPath
    lastThermalPath
    currentPrintNameOnPrinter
    remainingPasteConsumption
    remainingPrintTime
    creator
    created_at
    modifier
    modified_at
    tools
    printerPauseCommandStatus
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.DeletePrinterMutationVariables,
  APITypes.DeletePrinterMutation
>;
export const createPrinterQueue = /* GraphQL */ `mutation CreatePrinterQueue(
  $input: CreatePrinterQueueInput!
  $condition: ModelPrinterQueueConditionInput
) {
  createPrinterQueue(input: $input, condition: $condition) {
    id
    printerId
    buildId
    printName
    estimatedCompletionTime
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.CreatePrinterQueueMutationVariables,
  APITypes.CreatePrinterQueueMutation
>;
export const updatePrinterQueue = /* GraphQL */ `mutation UpdatePrinterQueue(
  $input: UpdatePrinterQueueInput!
  $condition: ModelPrinterQueueConditionInput
) {
  updatePrinterQueue(input: $input, condition: $condition) {
    id
    printerId
    buildId
    printName
    estimatedCompletionTime
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.UpdatePrinterQueueMutationVariables,
  APITypes.UpdatePrinterQueueMutation
>;
export const deletePrinterQueue = /* GraphQL */ `mutation DeletePrinterQueue(
  $input: DeletePrinterQueueInput!
  $condition: ModelPrinterQueueConditionInput
) {
  deletePrinterQueue(input: $input, condition: $condition) {
    id
    printerId
    buildId
    printName
    estimatedCompletionTime
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.DeletePrinterQueueMutationVariables,
  APITypes.DeletePrinterQueueMutation
>;
export const createImage = /* GraphQL */ `mutation CreateImage(
  $input: CreateImageInput!
  $condition: ModelImageConditionInput
) {
  createImage(input: $input, condition: $condition) {
    printerId
    printId
    type
    step
    layer
    path
    time
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.CreateImageMutationVariables,
  APITypes.CreateImageMutation
>;
export const updateImage = /* GraphQL */ `mutation UpdateImage(
  $input: UpdateImageInput!
  $condition: ModelImageConditionInput
) {
  updateImage(input: $input, condition: $condition) {
    printerId
    printId
    type
    step
    layer
    path
    time
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.UpdateImageMutationVariables,
  APITypes.UpdateImageMutation
>;
export const deleteImage = /* GraphQL */ `mutation DeleteImage(
  $input: DeleteImageInput!
  $condition: ModelImageConditionInput
) {
  deleteImage(input: $input, condition: $condition) {
    printerId
    printId
    type
    step
    layer
    path
    time
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.DeleteImageMutationVariables,
  APITypes.DeleteImageMutation
>;
export const createPlate = /* GraphQL */ `mutation CreatePlate(
  $input: CreatePlateInput!
  $condition: ModelPlateConditionInput
) {
  createPlate(input: $input, condition: $condition) {
    id
    name
    created_by
    created_at
    modified_by
    modified_at
    dumb
    machineId
    recipeId
    millConfigId
    materialConfigId
    path
    messages
    print
    moho_version
    marker_name
    Min_Layer
    Max_Layer
    Max_Slab
    Slab_Layers
    Contouring
    Process
    overrides
    machineConfig
    millConfig
    materialConfig
    recipe
    parts
    machine_info
    archive
    hasFolders
    folders
    hasTags
    tags
    hasParts
    hasBuilds
    builds
    hasConfigs
    configs
    plate_search_string
    noOfParts
    scaling
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.CreatePlateMutationVariables,
  APITypes.CreatePlateMutation
>;
export const updatePlate = /* GraphQL */ `mutation UpdatePlate(
  $input: UpdatePlateInput!
  $condition: ModelPlateConditionInput
) {
  updatePlate(input: $input, condition: $condition) {
    id
    name
    created_by
    created_at
    modified_by
    modified_at
    dumb
    machineId
    recipeId
    millConfigId
    materialConfigId
    path
    messages
    print
    moho_version
    marker_name
    Min_Layer
    Max_Layer
    Max_Slab
    Slab_Layers
    Contouring
    Process
    overrides
    machineConfig
    millConfig
    materialConfig
    recipe
    parts
    machine_info
    archive
    hasFolders
    folders
    hasTags
    tags
    hasParts
    hasBuilds
    builds
    hasConfigs
    configs
    plate_search_string
    noOfParts
    scaling
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.UpdatePlateMutationVariables,
  APITypes.UpdatePlateMutation
>;
export const deletePlate = /* GraphQL */ `mutation DeletePlate(
  $input: DeletePlateInput!
  $condition: ModelPlateConditionInput
) {
  deletePlate(input: $input, condition: $condition) {
    id
    name
    created_by
    created_at
    modified_by
    modified_at
    dumb
    machineId
    recipeId
    millConfigId
    materialConfigId
    path
    messages
    print
    moho_version
    marker_name
    Min_Layer
    Max_Layer
    Max_Slab
    Slab_Layers
    Contouring
    Process
    overrides
    machineConfig
    millConfig
    materialConfig
    recipe
    parts
    machine_info
    archive
    hasFolders
    folders
    hasTags
    tags
    hasParts
    hasBuilds
    builds
    hasConfigs
    configs
    plate_search_string
    noOfParts
    scaling
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.DeletePlateMutationVariables,
  APITypes.DeletePlateMutation
>;
export const createPrinterUtilization = /* GraphQL */ `mutation CreatePrinterUtilization(
  $input: CreatePrinterUtilizationInput!
  $condition: ModelPrinterUtilizationConditionInput
) {
  createPrinterUtilization(input: $input, condition: $condition) {
    id
    alarmCount
    alarmTotalTime
    runningTotalTime
    stoppedTotalTime
    offlineTime
    startDate
    stopDate
    bucketName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.CreatePrinterUtilizationMutationVariables,
  APITypes.CreatePrinterUtilizationMutation
>;
export const updatePrinterUtilization = /* GraphQL */ `mutation UpdatePrinterUtilization(
  $input: UpdatePrinterUtilizationInput!
  $condition: ModelPrinterUtilizationConditionInput
) {
  updatePrinterUtilization(input: $input, condition: $condition) {
    id
    alarmCount
    alarmTotalTime
    runningTotalTime
    stoppedTotalTime
    offlineTime
    startDate
    stopDate
    bucketName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.UpdatePrinterUtilizationMutationVariables,
  APITypes.UpdatePrinterUtilizationMutation
>;
export const deletePrinterUtilization = /* GraphQL */ `mutation DeletePrinterUtilization(
  $input: DeletePrinterUtilizationInput!
  $condition: ModelPrinterUtilizationConditionInput
) {
  deletePrinterUtilization(input: $input, condition: $condition) {
    id
    alarmCount
    alarmTotalTime
    runningTotalTime
    stoppedTotalTime
    offlineTime
    startDate
    stopDate
    bucketName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.DeletePrinterUtilizationMutationVariables,
  APITypes.DeletePrinterUtilizationMutation
>;
export const createFolders = /* GraphQL */ `mutation CreateFolders(
  $input: CreateFoldersInput!
  $condition: ModelFoldersConditionInput
) {
  createFolders(input: $input, condition: $condition) {
    id
    name
    hasParts
    noOfParts
    hasPlates
    noOfPlates
    hasBuilds
    noOfBuilds
    creator
    created_at
    modifier
    modified_at
    dumb
    archive
    parts
    plates
    builds
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.CreateFoldersMutationVariables,
  APITypes.CreateFoldersMutation
>;
export const updateFolders = /* GraphQL */ `mutation UpdateFolders(
  $input: UpdateFoldersInput!
  $condition: ModelFoldersConditionInput
) {
  updateFolders(input: $input, condition: $condition) {
    id
    name
    hasParts
    noOfParts
    hasPlates
    noOfPlates
    hasBuilds
    noOfBuilds
    creator
    created_at
    modifier
    modified_at
    dumb
    archive
    parts
    plates
    builds
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.UpdateFoldersMutationVariables,
  APITypes.UpdateFoldersMutation
>;
export const deleteFolders = /* GraphQL */ `mutation DeleteFolders(
  $input: DeleteFoldersInput!
  $condition: ModelFoldersConditionInput
) {
  deleteFolders(input: $input, condition: $condition) {
    id
    name
    hasParts
    noOfParts
    hasPlates
    noOfPlates
    hasBuilds
    noOfBuilds
    creator
    created_at
    modifier
    modified_at
    dumb
    archive
    parts
    plates
    builds
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.DeleteFoldersMutationVariables,
  APITypes.DeleteFoldersMutation
>;
export const createTags = /* GraphQL */ `mutation CreateTags(
  $input: CreateTagsInput!
  $condition: ModelTagsConditionInput
) {
  createTags(input: $input, condition: $condition) {
    id
    name
    hasParts
    noOfParts
    hasPlates
    noOfPlates
    hasBuilds
    noOfBuilds
    creator
    created_at
    modifier
    modified_at
    dumb
    archive
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.CreateTagsMutationVariables,
  APITypes.CreateTagsMutation
>;
export const updateTags = /* GraphQL */ `mutation UpdateTags(
  $input: UpdateTagsInput!
  $condition: ModelTagsConditionInput
) {
  updateTags(input: $input, condition: $condition) {
    id
    name
    hasParts
    noOfParts
    hasPlates
    noOfPlates
    hasBuilds
    noOfBuilds
    creator
    created_at
    modifier
    modified_at
    dumb
    archive
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.UpdateTagsMutationVariables,
  APITypes.UpdateTagsMutation
>;
export const deleteTags = /* GraphQL */ `mutation DeleteTags(
  $input: DeleteTagsInput!
  $condition: ModelTagsConditionInput
) {
  deleteTags(input: $input, condition: $condition) {
    id
    name
    hasParts
    noOfParts
    hasPlates
    noOfPlates
    hasBuilds
    noOfBuilds
    creator
    created_at
    modifier
    modified_at
    dumb
    archive
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.DeleteTagsMutationVariables,
  APITypes.DeleteTagsMutation
>;
export const createPrintFeedback = /* GraphQL */ `mutation CreatePrintFeedback(
  $input: CreatePrintFeedbackInput!
  $condition: ModelPrintFeedbackConditionInput
) {
  createPrintFeedback(input: $input, condition: $condition) {
    id
    Feedback
    printerId
    printId
    bundle_UUID
    buildID
    creator
    created_at
    modifier
    modified_at
    imageUrls
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.CreatePrintFeedbackMutationVariables,
  APITypes.CreatePrintFeedbackMutation
>;
export const updatePrintFeedback = /* GraphQL */ `mutation UpdatePrintFeedback(
  $input: UpdatePrintFeedbackInput!
  $condition: ModelPrintFeedbackConditionInput
) {
  updatePrintFeedback(input: $input, condition: $condition) {
    id
    Feedback
    printerId
    printId
    bundle_UUID
    buildID
    creator
    created_at
    modifier
    modified_at
    imageUrls
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.UpdatePrintFeedbackMutationVariables,
  APITypes.UpdatePrintFeedbackMutation
>;
export const deletePrintFeedback = /* GraphQL */ `mutation DeletePrintFeedback(
  $input: DeletePrintFeedbackInput!
  $condition: ModelPrintFeedbackConditionInput
) {
  deletePrintFeedback(input: $input, condition: $condition) {
    id
    Feedback
    printerId
    printId
    bundle_UUID
    buildID
    creator
    created_at
    modifier
    modified_at
    imageUrls
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.DeletePrintFeedbackMutationVariables,
  APITypes.DeletePrintFeedbackMutation
>;
export const createDistribution = /* GraphQL */ `mutation CreateDistribution(
  $input: CreateDistributionInput!
  $condition: ModelDistributionConditionInput
) {
  createDistribution(input: $input, condition: $condition) {
    product_id
    build_id
    commit_url
    deployed_at
    InstallerBucket
    InstallerBucketPath
    mw_expiration_date
    release_note
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.CreateDistributionMutationVariables,
  APITypes.CreateDistributionMutation
>;
export const updateDistribution = /* GraphQL */ `mutation UpdateDistribution(
  $input: UpdateDistributionInput!
  $condition: ModelDistributionConditionInput
) {
  updateDistribution(input: $input, condition: $condition) {
    product_id
    build_id
    commit_url
    deployed_at
    InstallerBucket
    InstallerBucketPath
    mw_expiration_date
    release_note
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.UpdateDistributionMutationVariables,
  APITypes.UpdateDistributionMutation
>;
export const deleteDistribution = /* GraphQL */ `mutation DeleteDistribution(
  $input: DeleteDistributionInput!
  $condition: ModelDistributionConditionInput
) {
  deleteDistribution(input: $input, condition: $condition) {
    product_id
    build_id
    commit_url
    deployed_at
    InstallerBucket
    InstallerBucketPath
    mw_expiration_date
    release_note
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.DeleteDistributionMutationVariables,
  APITypes.DeleteDistributionMutation
>;
export const createEnvironments = /* GraphQL */ `mutation CreateEnvironments(
  $input: CreateEnvironmentsInput!
  $condition: ModelEnvironmentsConditionInput
) {
  createEnvironments(input: $input, condition: $condition) {
    id
    build_id
    commit_url
    product_id
    send_domain
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.CreateEnvironmentsMutationVariables,
  APITypes.CreateEnvironmentsMutation
>;
export const updateEnvironments = /* GraphQL */ `mutation UpdateEnvironments(
  $input: UpdateEnvironmentsInput!
  $condition: ModelEnvironmentsConditionInput
) {
  updateEnvironments(input: $input, condition: $condition) {
    id
    build_id
    commit_url
    product_id
    send_domain
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.UpdateEnvironmentsMutationVariables,
  APITypes.UpdateEnvironmentsMutation
>;
export const deleteEnvironments = /* GraphQL */ `mutation DeleteEnvironments(
  $input: DeleteEnvironmentsInput!
  $condition: ModelEnvironmentsConditionInput
) {
  deleteEnvironments(input: $input, condition: $condition) {
    id
    build_id
    commit_url
    product_id
    send_domain
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.DeleteEnvironmentsMutationVariables,
  APITypes.DeleteEnvironmentsMutation
>;
export const createPartsSearch = /* GraphQL */ `mutation CreatePartsSearch(
  $input: CreatePartsSearchInput!
  $condition: ModelPartsSearchConditionInput
) {
  createPartsSearch(input: $input, condition: $condition) {
    id
    parts_search_string
    dumb
    created_by
    created_at
    modified_by
    modified_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.CreatePartsSearchMutationVariables,
  APITypes.CreatePartsSearchMutation
>;
export const updatePartsSearch = /* GraphQL */ `mutation UpdatePartsSearch(
  $input: UpdatePartsSearchInput!
  $condition: ModelPartsSearchConditionInput
) {
  updatePartsSearch(input: $input, condition: $condition) {
    id
    parts_search_string
    dumb
    created_by
    created_at
    modified_by
    modified_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.UpdatePartsSearchMutationVariables,
  APITypes.UpdatePartsSearchMutation
>;
export const deletePartsSearch = /* GraphQL */ `mutation DeletePartsSearch(
  $input: DeletePartsSearchInput!
  $condition: ModelPartsSearchConditionInput
) {
  deletePartsSearch(input: $input, condition: $condition) {
    id
    parts_search_string
    dumb
    created_by
    created_at
    modified_by
    modified_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.DeletePartsSearchMutationVariables,
  APITypes.DeletePartsSearchMutation
>;
export const createPlateSearch = /* GraphQL */ `mutation CreatePlateSearch(
  $input: CreatePlateSearchInput!
  $condition: ModelPlateSearchConditionInput
) {
  createPlateSearch(input: $input, condition: $condition) {
    id
    plate_search_string
    dumb
    created_by
    created_at
    modified_by
    modified_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.CreatePlateSearchMutationVariables,
  APITypes.CreatePlateSearchMutation
>;
export const updatePlateSearch = /* GraphQL */ `mutation UpdatePlateSearch(
  $input: UpdatePlateSearchInput!
  $condition: ModelPlateSearchConditionInput
) {
  updatePlateSearch(input: $input, condition: $condition) {
    id
    plate_search_string
    dumb
    created_by
    created_at
    modified_by
    modified_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.UpdatePlateSearchMutationVariables,
  APITypes.UpdatePlateSearchMutation
>;
export const deletePlateSearch = /* GraphQL */ `mutation DeletePlateSearch(
  $input: DeletePlateSearchInput!
  $condition: ModelPlateSearchConditionInput
) {
  deletePlateSearch(input: $input, condition: $condition) {
    id
    plate_search_string
    dumb
    created_by
    created_at
    modified_by
    modified_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.DeletePlateSearchMutationVariables,
  APITypes.DeletePlateSearchMutation
>;
export const createBuildSearch = /* GraphQL */ `mutation CreateBuildSearch(
  $input: CreateBuildSearchInput!
  $condition: ModelBuildSearchConditionInput
) {
  createBuildSearch(input: $input, condition: $condition) {
    id
    build_search_string
    dumb
    created_by
    created_at
    modified_by
    modified_at
    moho_version
    current_status
    build_result
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.CreateBuildSearchMutationVariables,
  APITypes.CreateBuildSearchMutation
>;
export const updateBuildSearch = /* GraphQL */ `mutation UpdateBuildSearch(
  $input: UpdateBuildSearchInput!
  $condition: ModelBuildSearchConditionInput
) {
  updateBuildSearch(input: $input, condition: $condition) {
    id
    build_search_string
    dumb
    created_by
    created_at
    modified_by
    modified_at
    moho_version
    current_status
    build_result
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.UpdateBuildSearchMutationVariables,
  APITypes.UpdateBuildSearchMutation
>;
export const deleteBuildSearch = /* GraphQL */ `mutation DeleteBuildSearch(
  $input: DeleteBuildSearchInput!
  $condition: ModelBuildSearchConditionInput
) {
  deleteBuildSearch(input: $input, condition: $condition) {
    id
    build_search_string
    dumb
    created_by
    created_at
    modified_by
    modified_at
    moho_version
    current_status
    build_result
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.DeleteBuildSearchMutationVariables,
  APITypes.DeleteBuildSearchMutation
>;
export const createPrinterCommand = /* GraphQL */ `mutation CreatePrinterCommand(
  $input: CreatePrinterCommandInput!
  $condition: ModelPrinterCommandConditionInput
) {
  createPrinterCommand(input: $input, condition: $condition) {
    TransactionID
    Acknowledgement
    Command
    DeviceID
    MessageReceivedAt
    RecordCreatedAt
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.CreatePrinterCommandMutationVariables,
  APITypes.CreatePrinterCommandMutation
>;
export const updatePrinterCommand = /* GraphQL */ `mutation UpdatePrinterCommand(
  $input: UpdatePrinterCommandInput!
  $condition: ModelPrinterCommandConditionInput
) {
  updatePrinterCommand(input: $input, condition: $condition) {
    TransactionID
    Acknowledgement
    Command
    DeviceID
    MessageReceivedAt
    RecordCreatedAt
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.UpdatePrinterCommandMutationVariables,
  APITypes.UpdatePrinterCommandMutation
>;
export const deletePrinterCommand = /* GraphQL */ `mutation DeletePrinterCommand(
  $input: DeletePrinterCommandInput!
  $condition: ModelPrinterCommandConditionInput
) {
  deletePrinterCommand(input: $input, condition: $condition) {
    TransactionID
    Acknowledgement
    Command
    DeviceID
    MessageReceivedAt
    RecordCreatedAt
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.DeletePrinterCommandMutationVariables,
  APITypes.DeletePrinterCommandMutation
>;
export const createHelp = /* GraphQL */ `mutation CreateHelp(
  $input: CreateHelpInput!
  $condition: ModelHelpConditionInput
) {
  createHelp(input: $input, condition: $condition) {
    id
    helpDocumentName
    helpDocumentComment
    helpDocumentTypeLink
    created_by
    created_at
    modified_by
    modified_at
    helpDocumentType
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.CreateHelpMutationVariables,
  APITypes.CreateHelpMutation
>;
export const updateHelp = /* GraphQL */ `mutation UpdateHelp(
  $input: UpdateHelpInput!
  $condition: ModelHelpConditionInput
) {
  updateHelp(input: $input, condition: $condition) {
    id
    helpDocumentName
    helpDocumentComment
    helpDocumentTypeLink
    created_by
    created_at
    modified_by
    modified_at
    helpDocumentType
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.UpdateHelpMutationVariables,
  APITypes.UpdateHelpMutation
>;
export const deleteHelp = /* GraphQL */ `mutation DeleteHelp(
  $input: DeleteHelpInput!
  $condition: ModelHelpConditionInput
) {
  deleteHelp(input: $input, condition: $condition) {
    id
    helpDocumentName
    helpDocumentComment
    helpDocumentTypeLink
    created_by
    created_at
    modified_by
    modified_at
    helpDocumentType
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.DeleteHelpMutationVariables,
  APITypes.DeleteHelpMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    firstName
    lastName
    email
    phoneNo
    roleId
    status
    notifications
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    firstName
    lastName
    email
    phoneNo
    roleId
    status
    notifications
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    firstName
    lastName
    email
    phoneNo
    roleId
    status
    notifications
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createNotification = /* GraphQL */ `mutation CreateNotification(
  $input: CreateNotificationInput!
  $condition: ModelNotificationConditionInput
) {
  createNotification(input: $input, condition: $condition) {
    id
    notificationType
    notificationMsg
    sendText
    sendEmail
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.CreateNotificationMutationVariables,
  APITypes.CreateNotificationMutation
>;
export const updateNotification = /* GraphQL */ `mutation UpdateNotification(
  $input: UpdateNotificationInput!
  $condition: ModelNotificationConditionInput
) {
  updateNotification(input: $input, condition: $condition) {
    id
    notificationType
    notificationMsg
    sendText
    sendEmail
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.UpdateNotificationMutationVariables,
  APITypes.UpdateNotificationMutation
>;
export const deleteNotification = /* GraphQL */ `mutation DeleteNotification(
  $input: DeleteNotificationInput!
  $condition: ModelNotificationConditionInput
) {
  deleteNotification(input: $input, condition: $condition) {
    id
    notificationType
    notificationMsg
    sendText
    sendEmail
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.DeleteNotificationMutationVariables,
  APITypes.DeleteNotificationMutation
>;
export const createRole = /* GraphQL */ `mutation CreateRole(
  $input: CreateRoleInput!
  $condition: ModelRoleConditionInput
) {
  createRole(input: $input, condition: $condition) {
    id
    roleName
    permissions {
      roleChange
      userManagement
      advancedFeatures
      
    }
    advancedFeatures {
      id
      name
      enabled
      
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.CreateRoleMutationVariables,
  APITypes.CreateRoleMutation
>;
export const updateRole = /* GraphQL */ `mutation UpdateRole(
  $input: UpdateRoleInput!
  $condition: ModelRoleConditionInput
) {
  updateRole(input: $input, condition: $condition) {
    id
    roleName
    permissions {
      roleChange
      userManagement
      advancedFeatures
      
    }
    advancedFeatures {
      id
      name
      enabled
      
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.UpdateRoleMutationVariables,
  APITypes.UpdateRoleMutation
>;
export const deleteRole = /* GraphQL */ `mutation DeleteRole(
  $input: DeleteRoleInput!
  $condition: ModelRoleConditionInput
) {
  deleteRole(input: $input, condition: $condition) {
    id
    roleName
    permissions {
      roleChange
      userManagement
      advancedFeatures
      
    }
    advancedFeatures {
      id
      name
      enabled
      
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedMutation<
  APITypes.DeleteRoleMutationVariables,
  APITypes.DeleteRoleMutation
>;
