import React, { useEffect, useState, FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { version } from "../../package.json";
import * as build from "../build.json";
import "./MVPPrintDashboard.scss";
import { MohoVersion } from "../Distributions/MohoVersion";
import API, { graphqlOperation } from "@aws-amplify/api";
import {
  listBuilds,
  buildByCreatedAt,
  buildByInitiated,
  getPrint,
  getPrinter,
} from "../graphql/queries";
import Amplify, { Auth, Storage } from "aws-amplify";
import { createBuild, updatePrint } from "../graphql/mutations";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import { useHistory } from "react-router-dom";
import saveFile from "../utils";
import JSZip from "jszip";
import { createPrinter, updatePrinter } from "../graphql/mutations";
import { listPrinters } from "../graphql/queries";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import LoadingComponent from "../LoadingComponent/LoadingComponent";
import { subscribe } from "graphql";
import MVPToolsDetailsModal from "./MVPToolsDetailsModal";
import { NotificationManager } from "react-notifications";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import { onHomePageTabChange, onNavigateToBuildReviewPage } from "../Beta/Actions/Home";
import { useDispatch } from "react-redux";
import printingReviewIcon from "./printingReviewIcon.svg"
import  printerIcon  from "./printer.svg";
import  downloadIcon  from "./download.svg";
import  timeStampIcon  from "./timestamp.svg";
interface FetchToolsInfo {
  id: string;
  name: string;
  GrafanaBundle: string
}

const MVPPrintDashboard: React.FunctionComponent = (props) => {
  const [recentElements, setRecentElements] = useState<any[]>([]);
  const [pauseStatus, setPauseStatus] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentName, setCurrentName] = useState<string>("");
  const [grafanaName, setGrafanaName] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [printDataList, setPrintDataList] = useState<any>({});
  const [openP, setOpenP] = useState<boolean>(false);
  const [selectedPrinter, setSelectedPrinter] = useState<any[]>([]);
  const [addPrinterError, setAddPrinterError] = useState<boolean>(false);
  const [addGrafanaError, setAddGrafanaError] = useState<boolean>(false);
  const [updateSubscription, setUpdateSubscription] = useState<any>(null);
  const [selectedPrinterName, setSelectedPrinterName] = useState<string>("");
  const [displayToolsDetails, setDisplayToolsDetails] =
    useState<boolean>(false);
  const [selectedPrinterTools, setSelectedPrinterTools] = useState<any[]>([]);
  const [primaryInfoToFetchToolsDetails, setPrimaryInfoToFetchToolsDetails] = useState<FetchToolsInfo[]>([]);;
  const [selectedPrinterId, setSelectedPrinterId] = useState<string>("");
  const [pausePrintModal, setPausePrintModal] = useState<boolean>(false);
  const [diagnosticsModal, setDiagnosticsModal] = useState<boolean>(false);
  const [printerList, setPrinterList] = useState<any[]>([]);
  const [selectedGrafana, setSelectedGrafana] = useState<string>("");
  const [selectedGrafanaId, setSelectedGrafanaId] = useState<string>("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  
  const [fetchingItems, setFetchingItems] = useState({});
  const [showDetails, setShowDetails] = useState({});
  const [fetchedData, setFetchedData] = useState<any[]>([]);

  const dispatch = useDispatch();
    
      const handleDownload = async () => {
        try {
          setError(null);
          setIsLoading(true);     
          const apiUrl = `https://8n9tl9pu1a.execute-api.us-east-1.amazonaws.com/dev/devicedatadev/b4-machine`;
          const response = await fetch(apiUrl);
      
          if (!response.ok) {
            throw new Error(`Failed to fetch presigned URL: ${response.statusText}`);
          }
          const data = await response.json();
          if (!data.presigned_url) {
            throw new Error("No presigned URL found in the response.");
          }
          const link = document.createElement("a");
          link.href = data.presigned_url;
          link.download = ""; 
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); 
        } catch (err:any) {
          console.error("Error occurred while downloading:", err);
          setError(err.message || "An unexpected error occurred.");
        } finally {
          setIsLoading(false);
        }
      };

  const fetchDataAndRemoveBlur = async (itemId) => {
    setFetchingItems((prev) => ({ ...prev, [itemId]: true }));
    try {
      let requestData = {
        body: recentElements.filter((elm) => elm.id === itemId)
      };
      const response = await API.post("lavaRestApi", "/getPrinterState", requestData);
      let indexToUpdate = recentElements.findIndex(obj => obj.id === itemId);
      if (indexToUpdate !== -1) {
        recentElements[indexToUpdate] = response[0];
      } else {
        console.log('Object not found');
      }
      setShowDetails((prev) => ({ ...prev, [itemId]: true }));
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      // Ensure to remove the blur and button after fetching data
      setFetchingItems((prev) => ({ ...prev, [itemId]: false }));
    }
  }

  const handleModalClose = () => setDisplayToolsDetails(false);
  const handleModalShow = () => setDisplayToolsDetails(true);

  const displayToolsModal = (printer) => {
    setDisplayToolsDetails(true);
    setSelectedPrinterTools(printer.GrafanaBundle)
   
    const primaryInfo: FetchToolsInfo = {
      id: printer.id,
      name: printer.name,
      GrafanaBundle: printer.GrafanaBundle
    };
    setPrimaryInfoToFetchToolsDetails([primaryInfo]);
    setSelectedPrinterId(printer.name);
  };

  const printerAction = async (GrafanaBundleId, status, printerId) => {
    const response = await API.get(
      "lavaPrinterControllerApi",
      `/${GrafanaBundleId}/${status}`,
      {
        headers: {},
        response: true,
      }
    );
    setPausePrintModal(true);
  };
  
  const diagnosticsAction= async (GrafanaBundleId, status, printerId) => {
    setSelectedGrafanaId(GrafanaBundleId)
    const response = await API.get(
      "lavaPrinterControllerApi",
      `/${GrafanaBundleId}/${status}`,
      {
        headers: {},
        response: true,
      }
    );
    if(!diagnosticsModal){
      setDiagnosticsModal(true);
    }
   
  };

  const history = useHistory();
  let zip = new JSZip();

  const routeChange = (newPath: string) => {
    history.push(newPath);
  };
  const goTolist = async (buildItem) => {
    console.log(JSON.stringify(buildItem,null,4), buildItem)
    let selectedPrinter = await getFiles(buildItem.id);
    setSelectedPrinter(selectedPrinter);
    setSelectedPrinterName(buildItem.id)
    setOpenP(true);
  };
  const handleDialogCloseP = () => {
    setOpenP(false);
    setPausePrintModal(false);
    setDiagnosticsModal(false);
    setSelectedGrafanaId("")
  };

  const goToBulidReview = (buildItem) => {
    history.push({
      pathname: "/dashboard/printReview",
      state: {
        selectedBuild: buildItem,
        selectedPart: printDataList[buildItem.id],
      },
    });
  };

  const get_current_list = async () => {
    let selfPointer = this;
    setLoading(true);
    // const response = await API.get("lavaRestApi", "/getPrinterState", {});
    // console.log('getPrinterState'+response)
    let requestResult = await API.graphql(
      graphqlOperation(listPrinters, { limit: 100 })
    );
    console.log("MVPBuildReview ~~~requestResult ~~~~", requestResult);
    let array = (requestResult as any).data.listPrinters.items;
    setRecentElements(array);
   // getPrinterStats(array[1]);
    // setUpdateSubscription(
    //   (
    //     API.graphql(
    //       graphqlOperation(`
    //           subscription OnUpdatePrinter {
    //               onUpdatePrinter {
    //                     id
    //                     currentPrint
    //                     currentLayer
    //                     currentStage
    //                     lastSyncTime
    //                     currentBundle
    //                     lastVisionPath
    //                     lastThermalPath
    //                     }
    //           }`)
    //     ) as any
    //   ).subscribe({
    //     next: ({ provider, value }) => {
    //       console.log({ provider, value });
    //       value = value.data.onUpdatePrinter;
    //       let newPrinters = Object.assign({});
    //       array.forEach((printer) => {
    //         if (
    //           printer.id == value.id &&
    //           (value.currentLayer || value.currentStage || value.lastStatusSync)
    //         ) {
    //           printer.currentLayer = value.currentLayer || printer.currentLayer;
    //           printer.currentStage = value.currentStage || printer.currentStage;
    //           printer.lastStatusSync =
    //             value.lastStatusSync || printer.lastStatusSync;
    //         }
    //       });
    //     },
    //     error: (error) => console.warn(error),
    //   })
    // );
    setLoading(false);
  };
  const getFiles = async (printerId) => {
    setLoading(true);
    // let files = await API.get(
    //   "lavaRestApi",
    //   `/queue/printers/${printerId}`,
    //   {}
    // );
    // setLoading(false);

    let files: any = await API.graphql(
      graphqlOperation(
      `query listPrinterQueuesByPrinter($printerId: ID!) {
        listPrinterQueuesByPrinter(printerId: $printerId) {
        nextToken
        startedAt
        items {
          id
          printName
          printerId
          estimatedCompletionTime
          createdAt
          buildId
        }
        }
      }`,
      { printerId: printerId }
      )
    );
    setLoading(false);
    return files?.data?.listPrinterQueuesByPrinter?.items.length ? files?.data?.listPrinterQueuesByPrinter?.items  : [];
  };


  const optionValueChange = (e, caseType) => {
    get_current_list();
    //  console.log('MVPPrintDashboard~optionValueChange~~', e.target.value);
  };

  const handleDialogClose = () => {
    setCurrentName("");
    setSelectedGrafana("");
    setGrafanaName("");
    setAddPrinterError(false);
    setAddGrafanaError(false);
    setOpen(false);
  };
  const getPrinters = async () => {
    setLoading(true);
    let files = await API.get("lavaRestApi", `/all/printers`, {});
    if (files) {
      setPrinterList(files);
    }
    console.log("getPrinters", files);
    setLoading(false);
    return files;
  };

  const addPrinter = () => {
    setOpen(true);
    getPrinters();
  };
  const aaddPrint = async (ev) => {
    setSelectedGrafana("")
    ev.stopPropagation();
    let dt = new Date() + "";
    let iid = Date.parse(dt);
    const data = {
      id: currentName,
      name: currentName,
      GrafanaBundle: grafanaName,
    };

    const printerExists = recentElements.find(
      (printer) => printer.id === currentName
    );

    if (!printerList.includes(grafanaName)) {
      setAddGrafanaError(true);
    } else if (!printerExists && printerList.includes(grafanaName)) {
      try {
       
        let result = await API.graphql(
          graphqlOperation(createPrinter, { input: data })
        );

        console.log(result);
        LoadMachine();
        get_current_list();
        setOpen(false);
      } catch (e) {
        console.log(e);
      }
    } else {
      setAddPrinterError(true);
    }
  };
  const LoadMachine = async () => {
    let requestResult = await API.graphql(
      graphqlOperation(listPrinters, { limit: 100 })
    );
    console.log("LoadMachine ~~~requestResult ~~~~", requestResult);
  };
  function secondsToDhms(seconds) {
    seconds = Number(seconds);
    let d = Math.floor(seconds / (3600 * 24));
    let h = Math.floor((seconds % (3600 * 24)) / 3600);
    let m = Math.floor((seconds % 3600) / 60);
    let s = Math.floor(seconds % 60);
    // console.log(d, h, m, s)
    let dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
    let hDisplay = h > 0 ? h + (h == 1 ? " hr, " : " hr, ") : "";
    let mDisplay = m > 0 ? m + (m == 1 ? " min, " : " min ") : "";
    //  let sDisplay = s > 0 ? s + (s == 1 ? " sec" : " sec") : ""
    return dDisplay + hDisplay + mDisplay;
  }
  const printIdVal = (id) => {
    let requestResult = printDataList
      ? printDataList[id]
        ? printDataList[id][0]
        : id
      : id;
    console.log("LoadMachine ~~~requestResult ~~~~", requestResult);
    return requestResult;
  };
  const [isConfigDisabled, setIsConfigDiabled] = useState<Boolean>(false);
  useEffect(() => {
    get_current_list();
   // testAPi();
    Auth.currentAuthenticatedUser().then((user) => {
      //console.log(user);

      const groups =
        user.signInUserSession.accessToken.payload["cognito:groups"] || [];

      //console.log(groups);

      if (groups[0] == "Operator") {
        setIsConfigDiabled(true);
      } else {
        setIsConfigDiabled(false);
      }
    });
    return function clean() {
      if (updateSubscription) {
        updateSubscription.unsubscribe();
      }
    };
  }, []);
  const secondsTohms = (time) => {
    var secondsNumber = Number(time);
    if(isNaN(secondsNumber)) { return "-"}
    var hours = Math.floor(secondsNumber / 3600);
    var minutes = Math.floor(secondsNumber % 3600 / 60);

    var hDisplay = hours > 0 ? hours + (hours === 1 ? " hr, " : " hrs, ") : "";
    var mDisplay =  minutes + " min";
    return hDisplay + mDisplay;
  }
  // useEffect(() => {
  //   let array = recentElements;
  //   setRecentElements(array);
  // }, [printDataList]);
  
  return (
    <div className="MVPPrintDashboard">
      <div className="dashboard-header">
        <div className="text-h-l">Printers:</div>
        <div className="actionSpace">
          {!isConfigDisabled && (
            <Button className="btn-primary" onClick={() => addPrinter()}>
              Add Printer
            </Button>
          )}
        </div>
      </div>
      <LoadingComponent visible={loading} />

      <div>
        <div></div>
        <div></div>
        <div className="custom-card">
          {recentElements.map((item: any) => {
            const labelId = `checkbox-build-label-${item.name}`;
            const printId = printDataList
              ? printDataList[item.id]
                ? printDataList[item.id][0]
                : ""
              : "";
            return (
              <div key={item.id} className="e-c-card">
                {/* <div  className="e-c-card-h">
                  <div></div>
                </div> */}
                <div className="e-c-card-b"></div>
                <div className="e-c-card-f">
                  <div className="e-c-card-f-h">
                    {/* {printId !== ''? (<div title={item.name}onClick = {()=>{goToBulidReview(item)}}>{printId}</div>):
            (<div title={item.name}onClick = {()=>{goToBulidReview(item)}}>{printIdVal(item.id)}</div>)} */}
                    <div
                      title={item.name}
                      onClick={() => {
                        goToBulidReview(item);
                      }}
                    >
                      {item.id}
                    </div>
                    {/* <div>
                      {item.currentPrintNameOnPrinter
                        ? item.currentPrintNameOnPrinter
                        : "No Data"}
                    </div> */}
                  </div>
                  <div className="position-relative">
                    {!fetchingItems[item.id] && !showDetails[item.id] && (
                      <div className="e-c-card-overlay d-flex justify-content-center align-items-center">
                        <div className="text-center">
                          <button
                            className="btn-primary btn btn-primary mt-3"
                            onClick={() => fetchDataAndRemoveBlur(item.id)}
                          >
                            Display Data
                          </button>
                        </div>
                      </div>
                    )}
                    {fetchingItems[item.id] && (
                      <div className="e-c-card-overlay d-flex justify-content-center align-items-center">
                        <div className="text-center">
                          <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                          </Spinner>
                        </div>
                      </div>
                    )}
                    <div className="e-c-card-f-e">
                      <div>
                        <div>
                          <div>Printer Status</div>
                          {/* {console.log('Printer' + JSON.stringify(item))} */}
                          <div>
                            {item.currentStatus
                              ? item.currentStatus
                              : "No Data"}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>
                          <div>Print Name</div>
                          <div>
                            {item.currentPrintNameOnPrinter
                              ? item.currentPrintNameOnPrinter
                              : "No Data"}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="e-c-card-f-e">
                      <div className="w-100">
                        <div style={{ display: "flex" }}>
                          <div>
                            Pause Printer Command Status
                            <div>
                              {item.printerPauseCommandStatus
                                ? item.printerPauseCommandStatus
                                : "Idle"}

                              {(item.currentStatus === "Running" &&
                                item.printerPauseCommandStatus === "Stopped") ||
                              item.printerPauseCommandStatus === "" ||
                              item.printerPauseCommandStatus === "Failed" ? (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="button-create-new-plate">
                                      Pause Printer
                                    </Tooltip>
                                  }
                                >
                                  <img
                                    alt=""
                                    src="/pause.svg"
                                    className="print-actions ml-2"
                                    onClick={() =>
                                      printerAction(
                                        item.GrafanaBundle,
                                        "pause",
                                        item.id
                                      )
                                    }
                                  />
                                </OverlayTrigger>
                              ) : null}

                              {item.currentStatus === "Running" &&
                              item.printerPauseCommandStatus ===
                                "Processing" ? (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="button-create-new-plate">
                                      Pause Printer
                                    </Tooltip>
                                  }
                                >
                                  <img
                                    alt=""
                                    src="/pause.svg"
                                    className="print-actions ml-2"
                                    style={{ opacity: "0.4" }}
                                  />
                                </OverlayTrigger>
                              ) : null}
                            </div>
                          </div>

                          <div style={{ marginLeft: "8px" }}>
                            <div className="printer-label"> Diagnostics</div>
                            <div>
                              {(item.currentStatus === "Running" &&
                                item.printerPauseCommandStatus === "Stopped") ||
                              item.printerPauseCommandStatus === "" ||
                              item.printerPauseCommandStatus === "Failed" ? (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="button-create-new-plate">
                                      Diagnostics
                                    </Tooltip>
                                  }
                                >
                                  <img
                                    alt=""
                                    src={printingReviewIcon}
                                    className="print-diagnostics ml-2"
                                    onClick={() =>
                                      diagnosticsAction(
                                        item.GrafanaBundle,
                                        "Diagnostics",
                                        item.id
                                      )
                                    }
                                  />
                                </OverlayTrigger>
                              ) : null}

                              {item.currentStatus === "Running" &&
                              item.printerPauseCommandStatus ===
                                "Processing" ? (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="button-create-new-plate">
                                      Pause Printer
                                    </Tooltip>
                                  }
                                >
                                  <img
                                    alt=""
                                    src="/pause.svg"
                                    className="print-actions ml-2"
                                    style={{ opacity: "0.4" }}
                                  />
                                </OverlayTrigger>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="e-c-card-f-e mb-5">
                      <div>
                        <div>
                          <div>Remaining Print Time</div>
                          <div>
                            {item.remainingPrintTime
                              ? item.remainingPrintTime === "0"
                                ? "00:00:00 hrs"
                                : secondsToDhms(item.remainingPrintTime)
                              : "No Data"}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>
                          <div>Remaining Paste needed for Print</div>
                          <div>
                            {item.remainingPasteConsumption
                              ? `${item.remainingPasteConsumption} mL`
                              : "No Data"}{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="e-c-card-f-f">
                      <div className="d-none"></div>
                      <div className="">
                        <Button
                          variant="primary"
                          onClick={() => displayToolsModal(item)}
                        >
                          Overall Tool Status
                        </Button>
                      </div>
                      <div className="">
                        <Button
                          variant="secondary"

                          onClick={() => { goTolist(item) }}
                        >

                          Print Queue
                        </Button>
                      </div>

                      {/* <div
                        onClick={() => {
                          goTolist(item);
                        }}
                      ></div> */}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Modal
        show={open}
        onHide={handleDialogClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="partsDialog"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>New Printer</Modal.Title>
          <span className="close-btn" onClick={handleDialogClose}></span>
        </Modal.Header>
        <Modal.Body>
          <div className="each-dialog-content">
            <div className="each-d-section">
              Enter the name for the new printer
            </div>
            {addGrafanaError && (
              <div style={{ color: "red" }}>
                Invalid Grafana. Please enter a valid Grafana name.
              </div>
            )}
            {addPrinterError && (
              <div style={{ color: "red" }}>
                Printer name already taken. Please use another name
              </div>
            )}
            <div className="each-d-section">
              <input
                className="f-w"
                type={"text"}
                value={currentName}
                onChange={(ev) => {
                  let v = ev.target.value;
                  setCurrentName(v);
                }}
              />
            </div>
            <div className="each-d-section">Select Grafana name</div>

            <div className="bp-l-b-e btstrp dropdown">
              <div className="bp-l-b-e-h"></div>
              <div className="btn-group bp-l-b-e-dd">
                <button
                  className="btn btn-secondary"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {selectedGrafana !== ""
                    ? selectedGrafana
                    : "Select the Grafana name"}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary dropdown-toggle dropdown-toggle-split"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                />
                <div className="dropdown-menu">
                  {printerList.length !== 0 ? (
                    printerList.map((printer, idx) => (
                      <div
                        key={idx}
                        id={printer}
                        className="dropdown-item"
                        onClick={() => {
                          setSelectedGrafana(printer);
                          setGrafanaName(printer);
                        }}
                      >
                        <label>{printer}</label>
                      </div>
                    ))
                  ) : (
                    <div
                      style={{
                        height: "150px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                      className="dropdown-item"
                    >
                      <img width="50px" src="/img/loader.svg" alt="loading" />
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* <div className="each-d-section">
              <input
                className="f-w"
                type={"text"}
                value={grafanaName}
                onChange={(ev) => {
                  let v = ev.target.value;
                  setGrafanaName(v);
                }}
              />
            </div> */}
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button className="btn-primary" onClick={aaddPrint} autoFocus>
            Add Printer
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={diagnosticsModal}
        onHide={handleDialogCloseP}
        backdrop="static"
        keyboard={false}
        dialogClassName="partsDialog"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title
            className="d-flex justify-content-center"
            style={{ width: "100%" }}
          >
            Diagnostics Review-{selectedGrafanaId}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="each-dialog-content">
            <div className="container">
              <div className="row">
                <div className="row w-100 py-2 diagnostics-popup">
                  <div className="diagnostics-text-block">
                    <h6 className="sub-head">Download Logs</h6>
                    <button
                      style={{ width: "170px" }}
                      className="btn-primary btn btn-primary mt-3"
                      onClick={handleDownload}
                      disabled={isLoading}
                    >
                      {"Download"}
                    </button>
                  </div>
                  <div className="diagnostics-text-block">
                  <h6 className="sub-head">Regenerate Logs</h6>
                    <button
                      style={{ width: "170px" }}
                      className="btn-primary btn btn-primary mt-3"
                      onClick={() =>
                        diagnosticsAction(
                          selectedGrafanaId,
                          "Diagnostics",
                          selectedGrafanaId
                        )
                      }
                      disabled={isLoading}
                    >
                      {"Regenerate"}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* <div>
              <div className="text-center">
                <h4 style={{font :"normal normal bold 20px/19px Graphik-Regular"}}>
                 Printer Name:  <span style={{marginLeft:'20px'}}>  </span>
                </h4>
               
              </div>
            </div> */}
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            className="btn btn-secondary"
            onClick={handleDialogCloseP}
            autoFocus
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Pause Printer Modal */}

      <Modal
        show={pausePrintModal}
        onHide={handleDialogCloseP}
        backdrop="static"
        keyboard={false}
        dialogClassName="partsDialog"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title></Modal.Title>
          {/* <span className="close-btn"
            onClick={handleDialogCloseP}>
          </span> */}
        </Modal.Header>
        <Modal.Body>
          <div className="each-dialog-content">
            <div>
              <div className="text-center">
                <h4>
                  <span>Printer Name: </span>
                  {selectedPrinterId}
                </h4>
                <div className="primary-color pt-3">
                  Pause Command issued, <br />
                  there will be a delay before the printing is paused.
                </div>
                <h5 style={{ margin: "30px" }}>Please refresh the page.</h5>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            className="btn btn-secondary"
            onClick={handleDialogCloseP}
            autoFocus
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={openP}
        onHide={handleDialogCloseP}
        backdrop="static"
        keyboard={false}
        dialogClassName="printQueueDialog"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title style={{ margin: '0 auto' }}>Print Queue:  {selectedPrinterName}</Modal.Title>
          <span className="close-btn" onClick={handleDialogCloseP}></span>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: '600px'}}>
          <div className="each-dialog-content">
            <div className="queue-header">
              <div className="">Order</div>
              <div className="">Name</div>
              <div className="">Date Added to Queue</div>
              <div className="">Est. Completion time</div>
            </div>
            <div style={{maxHeight: '500px', overflowY: 'auto', paddingRight: '15px'}}>
              {selectedPrinter.length ? selectedPrinter.map((item: any, idx) => {
                return (
                  <div className="queue-content" key={idx}>
                  <div className="each-d-section">{idx + 1 }</div>
                  <div className="each-d-section">
                    {/* {item?.printName.replace("_Lava.gcode", "")} */}
                    <span className="build_link" onClick={() => {
                      dispatch(onNavigateToBuildReviewPage(item.buildId, history))
                      dispatch(onHomePageTabChange("Print"))
                      }}>{item?.printName.replace("_Lava.gcode", "")}</span>
                  </div>
                  <div className="each-d-section">
                    {moment(item.createdAt).format(
                                "MMMM Do YYYY, HH:mm:ss A"
                              )}
                  </div>
                  <div className="each-d-section">
                    {item.estimatedCompletionTime
                      ? secondsTohms(item.estimatedCompletionTime)
                      : "-"}
                  </div>
                  </div>
                );
              }) : <div className="each-d-section" style={{ textAlign: 'center'}}>No Data</div>}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          {/* <Button variant="outline-secondary" className='outline-secondarytextactive-btn' onClick= {aaddPrint} autoFocus>
                Add Print
                        </Button> */}
        </Modal.Footer>
      </Modal>

      {selectedPrinterTools !== undefined &&
      selectedPrinterTools !== null &&
      selectedPrinterTools.length !== 0 ? (
        <MVPToolsDetailsModal
          showModal={displayToolsDetails}
          hideModal={handleModalClose}
          selectedPrinterTools={selectedPrinterTools}
          primaryInfoToFetchToolsDetails={primaryInfoToFetchToolsDetails}
          printerId={selectedPrinterId}
        ></MVPToolsDetailsModal>
      ) : null}
    </div>
  );
};

export default MVPPrintDashboard;
