import { IPart, IState } from '../../../Interfaces';

const onCopyPartSuccessReducer = (state: IState, action) => {
    const copiedPart = action.payload as IPart;
    
    const newState = { ...state } as IState;
    
    newState.data.modals.copyPartModalStatus = {
        ...newState.data.modals.copyPartModalStatus,
        inputText: '',
        isOpen: false,
        isValid: false,
        hasError: false,
        errorMessage: '',
    };

    newState.data.parts.highlightedPart = {} as IPart;
    newState.data.parts.highlightedPartId = '';
    
    return newState;
};

export default onCopyPartSuccessReducer;
