import React from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, DropdownButton, Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { onCopyPartCloseDialog, onCopyPartNameChange, onCopyPart } from '../../../Actions/Parts';
import { IState, ICopyPartModalStatus, IDisplayFolder, IPart } from '../../../Interfaces';
import { getDisplayFolders } from '../../../Mappers';
import { onHighlightFolder } from '../../../Actions/Folders';

const Props = (state: IState, ownProps: any) => {

    const copyPartModalStatus = state.data.modals.copyPartModalStatus as ICopyPartModalStatus;
    const displayFolders = getDisplayFolders(state) as IDisplayFolder[];

    const props = {
        isModalOpen: copyPartModalStatus.isOpen,
        inputText: copyPartModalStatus.inputText,
        hasError: copyPartModalStatus.hasError,
        errorMessage: copyPartModalStatus.errorMessage,
        isValid: copyPartModalStatus.isValid,
		displayFolders: displayFolders,
		highlightedFolderId: state.data.folders.highlightedFolderId,
        partToBeCopied: !state.data.search.isSearchEnabled
        ? (state.data.parts.loadedParts.find(
            (p) => p.id === state.data.parts.highlightedPartId
          ) as IPart) : (state.data.search.highlightedPartData as IPart),
    };

    return props;
};

const Actions = {
    onCopyPartCloseDialog: onCopyPartCloseDialog,
    onCopyPartNameChange: onCopyPartNameChange,
    onCopyPart: onCopyPart,
    onHighlightFolder,
};

class Component extends React.Component<any, any> {

    private onCopyPartNameChange(event) {
        if(event.target.value === '/' || (!event.target.value.includes('/') && this.props.highlightedFolderId)) return;
        const folderId = this.props.highlightedFolderId;
        if(event.target.value === '') {
            const inputText = folderId ? `${folderId}/${event.target.value}` : event.target.value;
            this.props.onCopyPartNameChange(inputText);
            return;
        }
        const value = folderId && event.target.value.includes('/') ? event.target.value.split('/')[1].split(/[^a-zA-Z0-9._-]/).join('') 
        :  event.target.value.split(/[^a-zA-Z0-9._-]/).join('');
        this.props.onCopyPartNameChange(folderId ? `${folderId}/${value}` : value);
    }

    private folderSelect(folderId: string) {
        let inputText = this.props.inputText.includes("/") ? this.props.inputText.split('/')[1] : this.props.inputText;
        inputText = folderId ? `${folderId}/${inputText}` : inputText;
        if(inputText.includes('_duplicate')){
            inputText = inputText.replace('_duplicate', '');
        }
        this.props.onCopyPartNameChange(inputText);
    }

    public render() {
        return (
            <>
                {
                    (this.props.isModalOpen) ?
                        (
                            <>
                                <Modal
                                    show={this.props.isModalOpen}
                                    onHide={() => this.props.onCopyPartCloseDialog()}
                                    backdrop='static'
                                    keyboard={false}
                                    dialogClassName='partsDialog'
                                    aria-labelledby='contained-modal-title-vcenter'
                                    centered>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Copy</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className='each-dialog-content'>
                                            <div className='each-d-section'>
                                                Enter the name for the new part
                                            </div>
                                            <div className='each-d-section'>
                                                {/* <label className='f-w' >folder name</label> */}
                                                <input className='f-w'
                                                    type='text'
                                                    value={this.props.inputText}
                                                    onChange={(event) => this.onCopyPartNameChange(event)}
                                                />
                                            </div>
                                            <div className='hint-msg'>
                                                Name can contain only a-z, A-Z, 0-9, ._-
                                            </div>
                                            <div className='invalid-feedback d-block'
                                                hidden={!(this.props.hasError)}>
                                                {this.props.errorMessage}
                                            </div>

                                            <div className='each-d-section'>
                                                Selected Folder : {this.props.highlightedFolderId ? this.props.highlightedFolderId : 'All'}
                                            </div>
                                            <div className='each-d-section copyFolderPart'>
                                            <DropdownButton id='dropdown-basic-button' title='Select Folder'>
														<Dropdown.Item
															onClick={() => {
																this.props.onHighlightFolder('');
                                                                this.props.onCopyPartNameChange(this.props.inputText.split('/')[1]);
															}}
														>
															None
														</Dropdown.Item>
														{
															this.props.displayFolders.map(df => {
																return (
																	<React.Fragment key={df.id}>
																		<Dropdown.Item
																			onClick={() => {
																				this.props.onHighlightFolder(df.id);
                                                                                this.folderSelect(df.id);
																			}}
																		>
																			{df.name}
																		</Dropdown.Item>
																	</React.Fragment>
																);
															})
														}
                                            </DropdownButton>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer className="justify-content-center">
                                        <Button
                                            className='btn-primary'
                                            onClick={() => this.props.onCopyPart()}
                                            autoFocus>
                                            Copy Part
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </>
                        ) :
                        (null)
                }

            </>
        );
    }
}

const CopyPart = withRouter(connect(Props, Actions)(Component));

export default CopyPart;