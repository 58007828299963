import React from "react";
import * as THREE from "three";
import { TransformControls } from "three/examples/jsm/controls/TransformControls";

import Scene from "../Scene";
import { CoordinateDisplay, ICoordinateEntry } from "./CoordinateDisplay";
import { IWorkspaceMeshManager } from "./WorkspaceMeshManager";

import "./WorkspaceSceneController.css";
import { Box3, Vector3 } from "three";

const emissiveColors = {
	standard: 0x000000,
	highlighted: 0x111111,
	selected: 0x222222,
};

const LicensedIconsPath = "/licensedIcons/";

interface IProps {
	scene: Scene;
	meshManager: IWorkspaceMeshManager;
	dispatch: any;
}

interface IState {
	currentMode: Mode;
	clickedMesh: THREE.Object3D | null;
	refresh: boolean;
}

interface IPickResult {
	point: THREE.Vector3,
	face: THREE.Face3,
	object: THREE.Object3D,
}

export enum Mode {
	None,
	Translate,
	Rotate,
	Scale,
	Snap,
	FaceUpOrient,
	MoveToCentre,
    DualZ,
}

export default class WorkspaceSceneController extends React.Component<
	IProps,
	IState,
	IPickResult
> {
	tooltip: React.RefObject<HTMLDivElement>;
	hoveredMesh: THREE.Object3D | null;
	raycaster: THREE.Raycaster;
	transformControls: TransformControls;
	arrowHelper: THREE.ArrowHelper;
	constructor(props: IProps) {
		super(props);

		this.onDelete = this.onDelete.bind(this);

		this.tooltip = React.createRef<HTMLDivElement>();
		this.hoveredMesh = null;
		this.raycaster = new THREE.Raycaster();
		this.arrowHelper = new THREE.ArrowHelper(
			new THREE.Vector3(0, 0, 1),
			new THREE.Vector3(0, 0, 0),
			30,
			0xffff00,
		);
		this.props.scene.scene.add(this.arrowHelper);
		this.arrowHelper.visible = false;
		this.transformControls = new TransformControls(
			props.scene.camera,
			props.scene.renderer.domElement
		);
		props.scene.scene.add(this.transformControls);

		this.state = {
			currentMode: Mode.None,
			clickedMesh: null,
			refresh: false,
		};

		this.transformControls.addEventListener(
			"dragging-changed",
			(event: any) => {
				if (this.props.scene.cameraControls) {
					this.props.scene.cameraControls.enabled = !event.value;
				}
			}
		);

		this.transformControls.addEventListener("objectChange", () => {
			this.setState(
				Object.assign(this.state, { refresh: !this.state.refresh })
			);
		});

		this.transformControls.addEventListener("mouseUp", (event: any) => {
			const mesh = event.target.object;
			const mode = event.target.getMode();
			this.updatePartProps(mesh);
		});


		props.scene.renderer.domElement.addEventListener("mousemove", (ev) =>
			this.onMouseMove(ev)
		);
		props.scene.renderer.domElement.addEventListener("mousedown", (ev) =>
			this.onMouseClick(ev)
		);
		props.meshManager.addListener(this);
	}
	
    partForMesh = (mesh: THREE.Object3D) => {
        return this.props.meshManager.findPartForMesh(mesh);
    }

	render() {
		let chooseSrc = (mode: Mode) => {
			switch (mode) {
				case Mode.Translate:
					return LicensedIconsPath + "011-surface.svg";
				case Mode.Rotate:
					return LicensedIconsPath + "003-rotate.svg";
				case Mode.Snap:
					return LicensedIconsPath + "043-download.svg";
				case Mode.FaceUpOrient:
					return LicensedIconsPath + "087-export.svg";
				case Mode.MoveToCentre:
					return LicensedIconsPath + "contract.svg";
			}
		};
		return (
			<>
				<div
					ref={this.tooltip}
					style={{
						position: "fixed",
						display: "none",
						border: "1px solid black",
						backgroundColor: "#ffffff",
						padding: "0 8px 0 8px",
						borderRadius: "2px",
						// backgroundImage:`url(${process.env.PUBLIC_URL+ "/img/workspace-bg.png"})`

					}}
				></div>
				<div className="modePickerContainer">
					<div className="modePickerContainerItem">
						<div className={`icon translate ${Mode.Translate === this.state.currentMode ? 'active' : ''}`} onClick={() => {
							let newMode = Mode.Translate === this.state.currentMode ? Mode.None : Mode.Translate;
							this.setState({
								currentMode: newMode,
							});
						}}>
						</div>
						<span className="tooltiptext">Translate</span>
					</div>
					<div className="modePickerContainerItem">
						<div className={`icon rotate ${Mode.Rotate === this.state.currentMode ? 'active' : ''}`} onClick={() => {
							let newMode = Mode.Rotate === this.state.currentMode ? Mode.None : Mode.Rotate;
							this.setState({
								currentMode: newMode,
							});
						}}>
						</div>
						<span className="tooltiptext">Rotate</span>
					</div>
					<div className="modePickerContainerItem">
						<div className={`icon move-to-centre ${Mode.MoveToCentre === this.state.currentMode ? 'active' : ''}`} onClick={() => {
							let newMode = Mode.MoveToCentre === this.state.currentMode ? Mode.None : Mode.MoveToCentre;
							this.setState({
								currentMode: newMode,
							});
						}}>
						</div>
						<span className="tooltiptext">Move To Center</span>
					</div>
					<div className="modePickerContainerItem">
						<div className={`icon face-up-orient ${Mode.FaceUpOrient === this.state.currentMode ? 'active' : ''}`} onClick={() => {
							let newMode = Mode.FaceUpOrient === this.state.currentMode ? Mode.None : Mode.FaceUpOrient;
							this.setState({
								currentMode: newMode,
							});
						}}>
						</div>
						<span className="tooltiptext">Face-down Orient</span>
					</div>
					<div className="modePickerContainerItem">
						<div className={`icon snap ${Mode.Snap === this.state.currentMode ? 'active' : ''}`} onClick={() => {
							let newMode = Mode.Snap === this.state.currentMode ? Mode.None : Mode.Snap;
							this.setState({
								currentMode: newMode,
							});
						}}>
						</div>
						<span className="tooltiptext">Snap</span>
					</div>
				</div>
				{this.state.clickedMesh ? (
					<CoordinateDisplay
						mesh={this.state.clickedMesh}
						planeZ={0}
						refreshToken={this.state.refresh}
						mode={this.state.currentMode}
						scaleFactor={1}
						setScaleFactor={(scale) => {}}
						partForMesh={this.partForMesh}
						updater={() => this.updatePartProps(this.state.clickedMesh!)}
					/>
				) : null}
			</>
		);
	}

	componentDidUpdate(prevProps, prevState) {
		switch (this.state.currentMode) {
			case Mode.None:
			case Mode.Snap:
			case Mode.FaceUpOrient:
			case Mode.MoveToCentre:
				this.transformControls.detach();
				if (this.state.clickedMesh) {
					this.setState(Object.assign(this.state, { clickedMesh: null }));
				}
				break;
			case Mode.Translate:
				this.transformControls.showX = this.transformControls.showY = true;
				this.transformControls.showZ = false;
				this.transformControls.setMode("translate");
				break;
			case Mode.Rotate:
				this.transformControls.showX = this.transformControls.showY = true;
				this.transformControls.showZ = true;
				this.transformControls.setMode("rotate");
				break;
		}

		this.props.scene.render();
	}

	onDelete(object: THREE.Object3D) {
		if (object === this.transformControls.object) {
			this.transformControls.detach();
		}
	}

	private onMouseMove(event: MouseEvent) {
		const object = this.pick(event);

		if (this.tooltip.current) {

			this.tooltip.current.innerHTML = '';
			if (object) {
				if (object.object.userData.intersectsOtherMesh) {
					this.tooltip.current.innerHTML = 'The part placement violates design rules. Please reposition it.';
				}
				else if (object.object.userData.outsideBuildEnvelope) {
					this.tooltip.current.innerHTML = 'This part is not completely inside the build envelope';
				}
				else if ('material' in object.object) {
					const mesh = object.object as THREE.Mesh;
					const part = this.props.meshManager.findPartForMesh(mesh);
					this.tooltip.current.innerHTML = `${part.properties.PartID} as ${part.customLabel}`;
				}
			}

			this.tooltip.current.style.display = 'none';
			if (this.tooltip.current.innerHTML) {
				this.tooltip.current.style.display = 'block';
				this.tooltip.current.style.top = event.pageY + 20 + 'px';
				this.tooltip.current.style.left = event.pageX + 20 + 'px';
			}
		}

		if (object && this.state.currentMode === Mode.FaceUpOrient) {
			this.arrowHelper.position.set(object.point.x, object.point.y, object.point.z)
			let quat = new THREE.Quaternion();
			object.object.getWorldQuaternion(quat)
			this.arrowHelper.setDirection(object.face.normal.applyQuaternion(quat));
			this.arrowHelper.visible = true;
		} else {
			this.arrowHelper.visible = false;
		}

		if (this.hoveredMesh && this.hoveredMesh != this.state.clickedMesh) {
			const mesh = this.hoveredMesh as THREE.Mesh;
			if ("material" in mesh) {
				(mesh.material as THREE.MeshLambertMaterial).emissive.setHex(
					emissiveColors.standard
				);
			}
		}

		if (object && object.object != this.state.clickedMesh) {
			const mesh = object.object as THREE.Mesh;
			if ("material" in mesh) {
				(mesh.material as THREE.MeshLambertMaterial).emissive.setHex(
					emissiveColors.highlighted
				);
			}
		}

		this.hoveredMesh = object ? object.object : null;
		this.props.scene.render();
	}

	private onMouseClick(ev) {
		if (
			this.state.currentMode !== Mode.None &&
			!this.transformControls.dragging
		) {
			const clickedMesh = this.pick(ev)?.object;

			if (this.state.clickedMesh) {
				const mesh = this.state.clickedMesh as THREE.Mesh;
				if ("material" in mesh) {
					(mesh.material as THREE.MeshLambertMaterial).emissive.setHex(
						emissiveColors.standard
					);
				}
			}
			let object = this.pick(ev)
			if (object && this.state.currentMode === Mode.FaceUpOrient && clickedMesh) {

				let center = new THREE.Vector3();
				new THREE.Box3().setFromObject(clickedMesh).getCenter(center);

				let quaternion = new THREE.Quaternion()
				let objectQuat = new THREE.Quaternion();
				object.object.getWorldQuaternion(objectQuat)
				quaternion.setFromUnitVectors(object.face.normal.applyQuaternion(objectQuat), new THREE.Vector3(0, 0, -1))

				let moveMat = new THREE.Matrix4().makeTranslation(-center.x, -center.y, -center.z);
				let reverseMat = new THREE.Matrix4().makeTranslation(center.x, center.y, center.z);
				let rotateMat = new THREE.Matrix4().makeRotationFromQuaternion(quaternion);
				clickedMesh.applyMatrix4(reverseMat.multiply(rotateMat.multiply(moveMat)));
				this.props.meshManager.snapObjectToFloor(clickedMesh);
				this.updatePartProps(clickedMesh);
			}
			if (clickedMesh) {
				if (this.state.currentMode === Mode.MoveToCentre) {
					const objectBoundingBox = new THREE.Box3().setFromObject(clickedMesh);
					let centre = new THREE.Vector3(0, 0);
					objectBoundingBox.getCenter(centre);

					let bvCenter = new Vector3()
					this.props.meshManager.getBuildAreaVolume()?.getCenter(bvCenter)


					let position = clickedMesh.position;
					let vectorForMove = new THREE.Vector3(0, 0, 0).addVectors(position, centre.negate());
					clickedMesh.position.set(vectorForMove.x + bvCenter.x, vectorForMove.y + bvCenter.y, 0);

					this.props.meshManager.snapObjectToFloor(clickedMesh);
					this.updatePartProps(clickedMesh);
					return;
				}
				if (this.state.currentMode === Mode.Snap) {
					this.props.meshManager.snapObjectToFloor(clickedMesh);
					this.updatePartProps(clickedMesh);
					return;
				} else {
					const mesh = clickedMesh as THREE.Mesh;
					if ("material" in mesh) {
						(mesh.material as THREE.MeshLambertMaterial).emissive.setHex(
							emissiveColors.selected
						);
					}

					this.transformControls.attach(clickedMesh);
				}
			} else {
				this.transformControls?.detach();
			}

			this.setState(Object.assign(this.state, { clickedMesh: clickedMesh }));
		}
		this.props.scene.render();
	}


	private pick(event: MouseEvent): IPickResult | null {
		// calculate mouse position in normalized device coordinates
		// (-1 to +1) for both components
		if (event !== undefined) {
			let canvasRect = this.props.scene.renderer.domElement.getBoundingClientRect();

			let topOffset = canvasRect.top;
			let leftOffset = canvasRect.left;

			let canvasWidth = canvasRect.width;
			let canvasHeight = canvasRect.height;

			let mouse = new THREE.Vector2();
			mouse.x = ((event.clientX - leftOffset) / canvasWidth) * 2 - 1;
			mouse.y = -((event.clientY - topOffset) / canvasHeight) * 2 + 1;

			// update the picking ray with the camera and mouse position
			this.raycaster.setFromCamera(mouse, this.props.scene.camera!);
			// calculate objects intersecting the picking ray

			if (this.props.meshManager.selectedMatrix) {
				let intersected = this.raycaster.intersectObjects(
					this.props.meshManager.selectedMatrix.children
				)[0];

				if (intersected) {
					return {
						object: intersected.object.parent!,
						point: intersected.point,
						face: intersected.face!,
					}
				}
			}
			else {
				let intersected = this.raycaster.intersectObjects(
					this.props.meshManager.getListOfMeshes()
				)[0]
				if (intersected) {
					return {
						object: intersected.object,
						point: intersected.point,
						face: intersected.face!,
					}
				}
			}
		}
		return null;
	}

	private updatePartProps(object: THREE.Object3D) {
		const mesh = object as THREE.Mesh;
		if (!("material" in mesh)) {
			return;
		}
		let objectRotation = object["rotation"];
		let objectPosition = object["position"];

		this.props.dispatch({
			type: "transform",
			cargo: {
				entityUUID: object.userData.UUID,
				properties: {
					rotate: objectRotation,
					translate: objectPosition,
					userData: object.userData,
				},
			},
		});
	}
}
