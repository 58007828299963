import React from 'react';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


function MVPDisplayPasteConsumptionModal(props) {
    const { showModal, hideModal } = props;
    const consumptionDetails = props.pasteConsumption;

    const sumTimeTaken = (firstValue, secondValue) => {
        let firstTimeValue = firstValue ? firstValue : '00:00:00';
        let secondTimeValue = secondValue ? secondValue : '00:00:00';

        const [firstHours, firstMinutes, firstSeconds] = firstTimeValue.split(':').map(Number);
        const [secondHours, secondMinutes, secondSeconds] = secondTimeValue.split(':').map(Number);

        let totalSeconds = firstSeconds + secondSeconds;
        let totalMinutes = firstMinutes + secondMinutes + Math.floor(totalSeconds / 60);
        let totalHours = firstHours + secondHours + Math.floor(totalMinutes / 60);

        totalSeconds = totalSeconds % 60;
        totalMinutes = totalMinutes % 60;

        return `${String(totalHours)}:${String(totalMinutes).padStart(2, '0')}:${String(totalSeconds).padStart(2, '0')}`;
    }
    
    //AggregatePasteConsumption details added in object
    let aggregatePasteConsumption: any[] = []
    let sum = 0;
    for (let i = 0; i < consumptionDetails.length; i++) {
        sum += parseFloat(consumptionDetails[i].pasteConsumption);
        const timeTaken = i !== 0 ? sumTimeTaken(aggregatePasteConsumption[i-1].timeTaken, consumptionDetails[i].timeTaken) : consumptionDetails[i].timeTaken;
        const [ hours, minutes ] = timeTaken.split(':').map(String);
        aggregatePasteConsumption.push({
            ...consumptionDetails[i], aggregatePasteConsumption: sum.toFixed(2), timeTaken: timeTaken, roundOffhrs: `${hours}.${String(minutes)[0]} hrs`
        })
    }
    aggregatePasteConsumption.unshift({ ...consumptionDetails[0], aggregatePasteConsumption: 0, timeTaken: '00:00:00', pasteConsumption: 0, roundOffhrs: '0 hrs' });
    console.log('aggregatePasteConsumption' + JSON.stringify(aggregatePasteConsumption, null, 4), aggregatePasteConsumption.length)

    //Getting the max paste consumption value
    const pasteConsumptionValues = aggregatePasteConsumption.map(object => {
        return object.pasteConsumption;
    });

    const maxPasteConsumptionValue = Math.max(...pasteConsumptionValues);

    //Getting the max aggregate paste consumption value
    const aggregatePasteConsumptionValues = aggregatePasteConsumption.map(object => {
        return parseFloat(object.aggregatePasteConsumption)
    });
    const maxAggregatePasteConsumptionValue = Math.max(...aggregatePasteConsumptionValues);

    //Populating X cordinate values
    const getXValue = aggregatePasteConsumption => {
        // let label = JSON.stringify(aggregatePasteConsumption.slab)
        let label = aggregatePasteConsumption.roundOffhrs;
        // return label.slice(6, label.length - 1)
        return label;
    };

 // Tooltip with Required Values
    const CustomTooltip = ({ payload, label }) => {
        if (payload && payload.length) {
            return (
                <div className="custom-tooltip" style={{ border: 'red'}}>
                    <p className="label">{`Time Taken (hrs) : ${payload[0]["payload"].timeTaken}`}</p>
                    <p className="label">{`Paste consumption : ${payload[0]["payload"].pasteConsumption} mL`}</p>
                    <p className="label">{`Aggregate paste consumption : ${payload[0]["payload"].aggregatePasteConsumption} mL`}</p>
                </div>
            );
        }

        return null;
    };

    const getInternal = () => {
        if (aggregatePasteConsumption.length < 10) {
            return 1;
        } else if (aggregatePasteConsumption.length > 10 && aggregatePasteConsumption.length < 25) {
            return 5;
        } else if (aggregatePasteConsumption.length > 25 && aggregatePasteConsumption.length < 50) {
            return 10;
        } else if (aggregatePasteConsumption.length > 50 && aggregatePasteConsumption.length < 100) { 
            return 15;
        } else if (aggregatePasteConsumption.length > 100 && aggregatePasteConsumption.length < 200) {
            return 20;
        } else if (aggregatePasteConsumption.length > 200 && aggregatePasteConsumption.length < 300) { 
            return 25;
        }
        return 30;
    }

    return (
        <Modal size="xl" centered show={showModal} onHide={hideModal}>
            <Modal.Header
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderBottom: "1px solid #DBDBDB",
                }}
            >
                <Modal.Title>Paste consumption</Modal.Title>
            </Modal.Header>
            <Modal.Body
                style={{
                    display: "flex",

                    flexDirection: "column",
                    //width:'100%',
                    height: '500px'
                }}
                className="modal-body"
            >
               

                <ResponsiveContainer width="100%" height="100%">
                    {/* <LineChart
                        width={500}
                        height={300}
                        data={aggregatePasteConsumption}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="4 4" />
                        <XAxis dataKey={getXValue} dy={10} angle={-90} 
                            axisLine={false} >
                            <Label value="Slab" offset={10} position="bottom" />
                            </XAxis>
                        <YAxis dataKey="aggregatePasteConsumption" domain={[0, Math.round(maxVal+3)]} dy={10}>
                            <Label value="Paste consumption in mL" offset={0} position="insideBottomLeft" angle={-90}></Label>
                        </YAxis>
                        <Tooltip content={<CustomTooltip active=""  payload={consumptionDetails.pasteConsumption} label={consumptionDetails.pasteConsumption  } />} />
                        <Legend verticalAlign="bottom"  />
                        <Line type="monotone" name="Paste consumption" dataKey="aggregatePasteConsumption" stroke="#ff6900" activeDot={{ r: 10 }} />
                      
                    </LineChart> */}

                    <LineChart
                        width={500}
                        height={300}
                        data={aggregatePasteConsumption}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis interval={getInternal()} dataKey={getXValue} label={{ value: 'Time Taken (hrs)', position: 'bottom' }} />
                        <YAxis padding={{ top: 20, bottom: 20 }}  yAxisId="left" domain={[0, Math.round(maxPasteConsumptionValue + 1)]} label={{ value: 'Paste consumption in mL', angle: -90, position: 'center'  }} />
                        <YAxis  domain={[0, Math.round(maxAggregatePasteConsumptionValue + 2)]} yAxisId="right" orientation="right" label={{ value: 'Aggregate paste consumption in mL', angle: -90, position: 'center' }}  />
                        <Tooltip wrapperStyle={{ outline: "none" }} content={<CustomTooltip payload={aggregatePasteConsumption} label={aggregatePasteConsumption} />} />
                        <Legend verticalAlign="bottom" />
                        <Legend />
                        <Line
                            name="Paste consumption"
                            yAxisId="left"
                            type="monotone"
                            dataKey="pasteConsumption"
                            stroke="#ff6900"
                            activeDot={{ r: 5 }}
                        />
                        <Line name="Aggregate paste consumption" activeDot={{ r: 5 }} yAxisId="right" type="monotone" dataKey="aggregatePasteConsumption" stroke="#0000FF" />
                    </LineChart>
                </ResponsiveContainer>


            </Modal.Body>
            <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
                <Button variant="secondary" onClick={hideModal}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default MVPDisplayPasteConsumptionModal;
