import { IState, IConfiguration } from "../../../Interfaces";

const onLoadConfigurationsInProgressReducer = (state: IState, action) => {
  const newlyLoadedConfigurations = action.payload as IConfiguration[];
  const newState = { ...state } as IState;
  newState.data.configurations.loadedConfigurations.splice(
    newState.data.configurations.loadedConfigurations.length,
    0,
    ...newlyLoadedConfigurations
  );
  if (newState.data.configurations.loadedConfigurations.length > 0) {
    const newConfigurationsList =
      newState.data.configurations.loadedConfigurations;
    let millConfigurations = newConfigurationsList.filter(
      (c) => c.type === "millConfig"
    );

    let newMillConfigurations = [] as IConfiguration[];
    let getThouConfigurations = [] as IConfiguration[];
    let getRoughConfigurations = millConfigurations.filter((item) =>
      /rough?/i.test(item?.id)
    ) as IConfiguration[];
    let getBlockConfigurations = millConfigurations.filter((item) =>
      /blocks?/i.test(item?.id)
    ) as IConfiguration[];

    getThouConfigurations = millConfigurations
      .filter((item) => {
        return item?.id.includes("thou") || item?.id.includes("Thou");
      })
      .sort((item1, item2) => {
        let getNumberA = item1.id.match(/\d+/);
        let numberA = getNumberA ? parseInt(getNumberA[0]) : 0;
        let getNumberB = item2.id.match(/\d+/);
        let numberB = getNumberB ? parseInt(getNumberB[0]) : 0;
        return numberA - numberB;
      });

    newMillConfigurations = [
      ...getThouConfigurations,
      ...getRoughConfigurations,
      ...getBlockConfigurations,
    ];
    newMillConfigurations = [
      ...new Set([...newMillConfigurations, ...millConfigurations]),
    ];

    millConfigurations = newMillConfigurations;

    // { Part configurations - Addictive strategies } 

    let partConfigurations = newState.data.configurations.loadedConfigurations.filter(
      (c) => c.type === "partConfig"
    );

    let getDetailedConfigurations = partConfigurations.filter((item) => {
      return item.id.includes("Detailed") || item.id.includes("detailed");
    });

    let getFastConfigurations = partConfigurations.filter((item) => {
      return item.id.includes("Fast") || item.id.includes("fast");
    });

    let newPartConfigurations = [] as IConfiguration[];

    newPartConfigurations = [...new Set([...getDetailedConfigurations, ...getFastConfigurations, ...partConfigurations])];


    // { Recipe configurations - Drying strategies }

    let recipeConfigurations = newState.data.configurations.loadedConfigurations.filter(
      (c) => c.type === "recipe"
    );

    let getDefaultConfigurations = recipeConfigurations.filter((item) => {
      return item.id.includes("Default") || item.id.includes("default");
    });

    let getDryConfigurations = recipeConfigurations.filter((item) => {
      return item.id.includes("Dry") || item.id.includes("dry");
    });

    let newRecipeConfigurations = [] as IConfiguration[];

    newRecipeConfigurations = [...new Set([...getDefaultConfigurations, ...getDryConfigurations, ...recipeConfigurations])];

    newState.data.configurations.loadedConfigurations = [
      ...new Set([...newMillConfigurations, ...newPartConfigurations, ...newRecipeConfigurations, ...newState.data.configurations.loadedConfigurations]),
    ];
  }

  newState.data.configurations.isLoadingInProgress = true;
  return newState;
};

export default onLoadConfigurationsInProgressReducer;
