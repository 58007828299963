import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { TabIndex } from "../Enums";
import { onHomePageTabChange } from "../Actions/Home";
import {
  IConfiguration,
  IDisplayPlate,
  IPart,
  IPlate,
  IState,
  IBuild,
} from "../Interfaces";
import { Button, Modal } from "react-bootstrap";
import {
  onNavigateToPlatesPage,
  onNavigateToReviewPage,
} from "../Actions/Home";
import { onAddPartOpenDialog, onUnselectParts } from "../Actions/Parts";
import { onUnselectBuilds } from "../Actions/Builds";
import { onUnselectPlates } from "../Actions/Plates";
import {
  getDefaultPrintConfiguration,
  getDefaultMillConfiguration,
  getDefaultMaterialConfiguration,
  getDefaultRecipeConfiguration,
  getDefaultPartConfiguration,
  getDefaultPartSlicerConfiguration,
} from "../DefaultConfigurations";
import { onNewTagOpenDialog, onNewFolderOpenDialog } from "../Actions/Home";
import { setPlateConfigurations } from "../Instances";
import { onHighlightPlate } from "../Actions/Plates";
import ImportPart from "./ImportParts";
import IPartInfo from "../../interfaces/IPartInfo";
import { onNewPlateOpenDialog } from "../Actions/Plates";
import onUpdatedNewPlate from "../Actions/Plates/New/updatedNewPlate";
import { API } from "aws-amplify";
import { NotificationManager, NotificationContainer } from "react-notifications";
import { onHighlightFolder, onHighlightFolderId } from "../Actions/Folders";
// import DeleteMultipleItemsModal from "./DeleteMultipleItemsModal"

const Props = (state: IState, ownProps: any) => {
  const workspace = state.data.workspace as any;
  const isSearchEnabled = state.data.search.isSearchEnabled as boolean;
  const defaultPrintConfiguration = getDefaultPrintConfiguration(
    state
  ) as IConfiguration;
  const defaultMillConfiguration = getDefaultMillConfiguration(
    state
  ) as IConfiguration;
  const defaultMaterialConfiguration = getDefaultMaterialConfiguration(
    state
  ) as IConfiguration;
  const defaultRecipeConfiguration = getDefaultRecipeConfiguration(
    state
  ) as IConfiguration;

  if (ownProps?.addedPart) {
    const addedPartPartConfiguration = state.data.workspace
      .addedPartsSelectedPartConfigurations[
      ownProps.addedPart
    ] as IConfiguration;
    const addedPartPartSlicerConfiguration = state.data.workspace
      .addedPartsSelectedPartSlicerConfigurations[
      ownProps.addedPart
    ] as IConfiguration;

    const props = {
      isLoadingComplete: state.data.folders.isLoadingComplete as boolean,
      selectedTabIndex: state.data.info.homePageTabIndex as number,
      selectedPartIds: state.data.parts.selectedPartIds as string[],
      selectedConfigurations:
        state.data.workspace.selectedConfigurations || ([] as IConfiguration[]),
      ids: {
        partIds: [ownProps.addedPart]
          .filter(Boolean)
          .map((part) => part.id)
          .filter(Boolean) as string[],
        partConfigurationIds: [addedPartPartConfiguration?.["id"]] as string[],
        partSlicerConfigurationIds: [
          addedPartPartSlicerConfiguration?.["id"],
        ] as string[],
        plateConfigurationIds: [
          defaultPrintConfiguration?.["id"],
          defaultMillConfiguration?.["id"],
          defaultMaterialConfiguration?.["id"],
          defaultRecipeConfiguration?.["id"],
        ].filter(Boolean),
      },
      loadedPlates: state.data.plates.loadedPlates as IPlate[],
      displayPlate: ownProps.displayPlate as IDisplayPlate,
      state: state as any,
      workspace: state.data.workspace,
      selectedIds: state.data.workspace?.selectedConfigurations
        ?.filter((item) => item?.id)
        .map((id) => id?.id || id?.name),
      selectedPlate: state.data.plates.loadedPlates?.filter(
        (el) => el.id === state.data.plates.highlightedPlateId
      ),
      enableBatchDelete : state.enableBatchDelete,
    };
    return props;
  } else {
    const props = {
      creator: state.creator,
      selectedParts: state.data.parts.selectedParts as IPart[],
      selectedPlates: state.data.plates.selectedPlates as IPlate[],
      selectedBuilds: state.data.builds.selectedBuilds as IBuild[],

      isLoadingComplete: state.data.folders.isLoadingComplete as boolean,
      selectedTabIndex: state.data.info.homePageTabIndex as number,
      selectedPartIds: state.data.parts.selectedPartIds as string[],
      ids: {
        partIds: [...state.data.parts.selectedPartIds].filter(
          Boolean
        ) as string[],
        partConfigurationIds: [...state.data.parts.selectedPartIds]
          .filter(Boolean)
          .map((id) => getDefaultPartConfiguration(state))
          .filter(Boolean)
          .map((configuration) => configuration.id)
          .filter(Boolean) as string[],
        partSlicerConfigurationIds: [...state.data.parts.selectedPartIds]
          .filter(Boolean)
          .map((id) => getDefaultPartSlicerConfiguration(state))
          .filter(Boolean)
          .map((configuration) => configuration.id)
          .filter(Boolean) as string[],
        plateConfigurationIds: [
          defaultPrintConfiguration?.["id"],
          defaultMillConfiguration?.["id"],
          defaultMaterialConfiguration?.["id"],
          defaultRecipeConfiguration?.["id"],
        ].filter(Boolean),
      },
      loadedPlates: state.data.plates.loadedPlates as IPlate[],
      displayPlate: ownProps.displayPlate as IDisplayPlate,
      state: state as any,
      workspace: state.data.workspace,
      selectedIds: state.data.workspace?.selectedConfigurations
        ?.filter((item) => item?.id)
        .map((id) => id?.id || id?.name),
      selectedPlate: state.data.plates.loadedPlates?.filter(
        (el) => el.id === state.data.plates.highlightedPlateId
      ),
      selectedSearchPlate: state.data.search.highlightedPlateData,
      selectedBuildIds: state.data.builds.selectedBuildIds as string[],
      selectedPlateIds: state.data.plates.selectedPlateIds as string[],
      showListView: state.data.info.showListView,
      showLoader : ownProps.showLoader,
      enableBatchDelete : state.enableBatchDelete,
      isPlateSelected: state.data.plates.highlightedPlateId ? true : false,
    };
    return props;
  }
};

const Actions = {
  onHomePageTabChange: onHomePageTabChange,
  onAddPartOpenDialog: onAddPartOpenDialog,
  onNavigateToPlatesPage: onNavigateToPlatesPage,
  onNewTagOpenDialog: onNewTagOpenDialog,
  onNewFolderOpenDialog: onNewFolderOpenDialog,
  onNavigateToReviewPage: onNavigateToReviewPage,
  onHighlightPlate: onHighlightPlate,
  onNewPlateOpenDialog: onNewPlateOpenDialog,
  onUpdatedNewPlate: onUpdatedNewPlate,
  onUnSelectParts: onUnselectParts,
  onUnselectBuilds: onUnselectBuilds,
  onUnselectPlates: onUnselectPlates,
  onHighlightFolderId,
  onHighlightFolder,
};

class Component extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      showImportPartswModal: false,
      isModalOpen: false,
      showDeletedList: false,
      deletedListType: "", 
      deletedItemIds: [],
      notDeletedItemIds: []
      // showMultipleItemsDeleteModal:false,
    };
  }

  updateAndRedirect = async () => {
    if (!this.props.isSearchEnabled) {
      await setPlateConfigurations(
        this.props.state,
        this.props.selectedIds,
        false
      );
      this.props.onNavigateToPlatesPage(
        this.props.ids,
        this.props.history,
        false
      );
    }
    if (this.props.isSearchEnabled) {
      this.props.onHighlightPlate(this.props.selectedSearchPlate.id);

      const plateToBeEdited = this.props.selectedSearchPlate;

      const partIds = plateToBeEdited.parts
        .filter(Boolean)
        .map((stringifiedPart) => JSON.parse(stringifiedPart))
        .filter(Boolean)
        .map((part) => part.properties.PartID)
        .filter(Boolean) as string[];

      const parts = plateToBeEdited.parts
        .filter(Boolean)
        .map((stringifiedPart) => JSON.parse(stringifiedPart))
        .filter(Boolean) as IPartInfo[];

      const partConfigurationIds = parts
        .map((part) => part.properties.PartConfig?.originalJson?.id)
        .filter((c) => c);

      const partSlicerConfigurationIds = parts
        .map((part) => part.properties.SlicerConfig?.originalJson?.id)
        .filter((c) => c);

      const plateConfigurationIds = [
        plateToBeEdited.machineId,
        plateToBeEdited.millConfigId,
        plateToBeEdited.materialConfigId,
        plateToBeEdited.recipeId,
      ];

      const ids = {
        partIds,
        parts,
        partConfigurationIds,
        partSlicerConfigurationIds,
        plateConfigurationIds,
      };

      this.props.onNavigateToPlatesPage(ids, this.props.history, false);
    }
  };

  componentDidMount() {
    this.props.onHighlightPlate("");
    console.log(this.props.selectedSearchPlate, "selectedSearchPlate");
  }
  handleModalClose = () => this.setState({ showImportPartswModal: false });
  handleModalShow = () => this.setState({ showImportPartswModal: true });
  createNewPlate = () => {
    this.props.onUpdatedNewPlate(this.props.ids, this.props.history, true);
    this.props.onNewPlateOpenDialog();
  };

  handleMultipleDelete = async () => {
    this.props.showLoader(true);
    this.setState({ isModalOpen: false });
    const generateObjects = (ids: string[]) => {
      return ids.map((id: string) => {
        const newObj: { [key: string]: string } = {};
        newObj[id] = "";
        return newObj;
      });
    };

    const builds = generateObjects([...new Set([...this.props.selectedBuildIds])]);
    const plates = generateObjects([...new Set([...this.props.selectedPlateIds])]);
    const parts = generateObjects([...new Set([...this.props.selectedPartIds])]);
    const totalSelectedCount = this.props.selectedBuildIds.length + this.props.selectedPlateIds.length + this.props.selectedPartIds.length;
    let successCount = 0;
    let notDeletedCount = 0;
    let deletedIds: string[] = [];
    let notDeletedIds: any = [];
    this.props.onUnSelectParts();
    this.props.onUnselectBuilds();
    this.props.onUnselectPlates();
    let requestData = {
      body: {
        BUILDID: builds,
        PLATESID: plates,
        PARTSID: parts,
      },
    };
    const response = await API.post("lavaRestApi", "/batchDelete", requestData);
    if (response) {
      this.props.showLoader(false);
      if(response["BUILDID"].length && Array.isArray(response["BUILDID"])) {
        response["BUILDID"].forEach((buildItem: any) => {
          const value = Object.values(buildItem)[0];
          if (typeof value === "string" && (value.includes("Skipped") || value.includes("Failed") )){
            notDeletedCount = notDeletedCount + 1;
            notDeletedIds.push({ key: Object.keys(buildItem)[0], msg: Object.values(buildItem)[0]});
          }
          else {
            successCount = successCount + 1;
            deletedIds.push(Object.keys(buildItem)[0]);
          }
        })
      }

      if(response["PLATESID"].length && Array.isArray(response["PLATESID"])) {
        response["PLATESID"].forEach((plateItem: any) => {
          const value = Object.values(plateItem)[0];
          if (typeof value === "string" && (value.includes("Skipped") || value.includes("Failed"))){
            notDeletedCount = notDeletedCount + 1;
            notDeletedIds.push({ key: Object.keys(plateItem)[0], msg: Object.values(plateItem)[0]});
          }
          else {
            successCount = successCount + 1;
            deletedIds.push(Object.keys(plateItem)[0]);
          }
        })
      }

      if(response["PARTSID"].length && Array.isArray(response["PARTSID"])) {
        response["PARTSID"].forEach((partItem: any) => {
          const value = Object.values(partItem)[0];
          if (typeof value === "string" && (value.includes("Skipped") || value.includes("Failed"))){
            notDeletedCount = notDeletedCount + 1;
            notDeletedIds.push({ key: Object.keys(partItem)[0], msg: Object.values(partItem)[0]});
          }
          else {
            successCount = successCount + 1;
            deletedIds.push(Object.keys(partItem)[0]);
          }
        })
    }

    const customContent = (
      <div className="custom-notification-class" onClick={() => {
        this.setState({ deletedItemIds: deletedIds, notDeletedItemIds: notDeletedIds, showDeletedList: true  });
      }}>
        <h5 className="total-count">{successCount} of {totalSelectedCount} items Deleted</h5>
        <div className="d-flex">
          {/* Deleted list */}
          <p className="deleted-count">{deletedIds.length} files deleted</p>
          {/* Not Deleted list */}
          <p className="notDeleted-count">{notDeletedIds.length} files not deleted</p>
        </div>
      </div>
    );
    if (notDeletedCount) {
      NotificationManager.error(customContent, '', 0, null);
      setTimeout(() => {
        if(document.getElementsByClassName("notification-error").length) {
        document.getElementsByClassName("notification-error")[0].classList.add("batch-delete-error-popup");
        }
      }, 100);
    }
    else {
      NotificationManager.success("Deleted Successfully!")
    }
    }
    console.log("getPrinterState" + response);
  };

  public render() {
    return (
      <>
        {/* Batch delete popup modal */}
        <Modal
          show={this.state.isModalOpen}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header>
            <Modal.Title>Delete</Modal.Title>
            <span
              className="close-btn"
              onClick={() => {
                this.setState({ isModalOpen: false });
              }}
            ></span>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-4">
              <h4>Are you sure you want to delete this selected items?</h4>
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-end">
            <Button
              className="btn btn-secondary"
              onClick={() => {
                this.setState({ isModalOpen: false });
              }}
            >
              Cancel
            </Button>
            <Button
              className="btn btn-primary"
              onClick={() => {
                this.handleMultipleDelete();
              }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
         {/* After deletion modal */}
         <Modal
          dialogClassName="delete-result-modal"
          show={this.state.showDeletedList}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header>
            <Modal.Title>Deleted Files</Modal.Title>
            <span
              className="close-btn"
              onClick={() => {
                this.setState({ showDeletedList: false });
              }}
            ></span>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-4 batch-delete-result-modal">
              {this.state.deletedListType && <div className="list-heading d-flex" onClick={() => { this.setState({ deletedListType: "" }) }}>
                <span className='back-icon'></span>
                {this.state.deletedListType === "deleted" ? "Deleted files" : "Not deleted files"}</div>}
              {!this.state.deletedListType && 
                <div className="main-list d-flex">
                  <Button onClick={() => { this.setState({ deletedListType: "deleted" }) }}
                   className="primary">Deleted {`( `} {this.state.deletedItemIds.length} {` )`}</Button>
                  <Button onClick={() => { this.setState({ deletedListType: "notDeleted" }) }}
                   className="primary">Not Deleted {`( `} {this.state.notDeletedItemIds.length} {` )`} </Button>
                  {/* <p className="deleted-link" onClick={() => { this.setState({ deletedListType: "deleted" }) }}><span></span>
                      Deleted {`( `} {this.state.deletedItemIds.length} {` )`}
                  </p>
                  <p className="not-deleted-link" onClick={() => { this.setState({ deletedListType: "notDeleted" }) }}><span></span>
                    Not Deleted {`( `} {this.state.notDeletedItemIds.length} {` )`}
                  </p> */}
                </div>}
                {this.state.deletedListType === "deleted" && this.state.deletedItemIds.length ? this.state.deletedItemIds.map((item, index) => {
                  return (
                    <div className="deleted-list d-flex" key={`${index} + ${item}`}><span className="completed"></span>{item}</div>
                  )
                }) : this.state.deletedListType === "deleted" && <div className="ml-3">No Data</div>}
                {this.state.deletedListType === "notDeleted" && this.state.notDeletedItemIds.length ? this.state.notDeletedItemIds.map((item, index) => {
                  return (
                    <div className="not-deleted-list" key={`${index} + ${item}`}>
                      <div className="d-flex">
                        <span className="warning"></span>
                        <span className="warning-filename">{item.key}</span>
                      </div>
                      <p className="warning-message">{item.msg}</p></div>
                  )
                }) : this.state.deletedListType === "notDeleted" && <div className="ml-3">No Data</div>}
            </div>
          </Modal.Body>
        </Modal>
        <div className="tab-bar">
          <div className="options left-justify">
            <div
              className={`tab ${
                this.props.selectedTabIndex === TabIndex.ALL ? "active" : ""
              }`}
              onClick={() => {
                this.props.onHomePageTabChange(TabIndex.ALL);
                this.props.onUnSelectParts();
                this.props.onUnselectPlates();
                this.props.onUnselectBuilds();
                this.props.onHighlightFolderId("");
              }}
            >
              <label>All</label>
            </div>
            <div
              className={`tab ${
                this.props.selectedTabIndex === TabIndex.PARTS ? "active" : ""
              }`}
              onClick={() => {
                this.props.onHomePageTabChange(TabIndex.PARTS);
                this.props.onNavigateToReviewPage(
                  this.props.ids,
                  this.props.history,
                  this.props.selectedTabIndex,
                  true
                );
                this.props.onUnSelectParts();
                this.props.onUnselectPlates();
                this.props.onUnselectBuilds();
                this.props.onHighlightFolderId("");
              }}
            >
              <label>Parts</label>
            </div>
            <div
              className={`tab ${
                this.props.selectedTabIndex === TabIndex.PLATES ? "active" : ""
              }`}
              onClick={() => {
                this.props.onHomePageTabChange(TabIndex.PLATES);
                this.props.onNavigateToReviewPage(
                  this.props.ids,
                  this.props.history,
                  true
                );
                this.props.onUnSelectParts();
                this.props.onUnselectPlates();
                this.props.onUnselectBuilds();
                this.props.onHighlightFolderId("");
              }}
            >
              <label>Plates</label>
            </div>
            <div
              className={`tab ${
                this.props.selectedTabIndex === TabIndex.BUILDS ? "active" : ""
              }`}
              onClick={() => {
                this.props.onHomePageTabChange(TabIndex.BUILDS);
                this.props.onUnSelectParts();
                this.props.onUnselectPlates();
                this.props.onUnselectBuilds();
                this.props.onHighlightFolderId("");
              }}
            >
              <label>Builds</label>
            </div>
          </div>
          <div className="flex-space"></div>
          <div className="options right-justify">
            <>
              {this.props.selectedTabIndex === TabIndex.ALL ? (
                <>
                  <div className="create-folder">
                    <Button
                      variant="secondary"
                      disabled={this.props.isLoadingInProgress}
                      onClick={() => this.props.onNewFolderOpenDialog()}
                    >
                      <img
                        src="/folder-icon.png"
                        className="pr-1"
                        alt="Create Folder Icon"
                      />{" "}
                      Create Folder
                    </Button>
                  </div>
                  <div className="import">
                    <Button
                      variant="secondary"
                      className="btn-space"
                      disabled={this.props.isLoadingInProgress}
                      onClick={this.handleModalShow}
                    >
                      <img
                        src="/import-icon.svg"
                        className="pr-3"
                        alt="Import Parts Icon"
                      />
                      Import Parts
                    </Button>
                  </div>

                  <div className="create-new-plate">
                    <Button
                      className={
                        this.props.selectedPartIds &&
                        this.props.selectedPartIds.length > 0 && !this.props.enableBatchDelete
                          ? "active"
                          : "btn-inactive"
                      }
                      onClick={() => this.createNewPlate()}
                    >
                      <span className="pr-2"> + </span> Create New Plate
                    </Button>
                  </div>
                  <div className="delete">
                    <Button
                      className={(
                        (this.props.selectedPartIds.length > 0 ||
                        this.props.selectedBuildIds.length > 0 ||
                        this.props.selectedPlateIds.length > 0) && this.props.enableBatchDelete)
                          ? "active btn-space"
                          : "btn-inactive btn-space"
                      }
                      variant="secondary"
                      onClick={() => {
                        this.setState({ isModalOpen: true });
                      }}
                    >
                      <svg
                        width="25px"
                        height="25px"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7 9.5L12 14.5M12 9.5L7 14.5M19.4922 13.9546L16.5608 17.7546C16.2082 18.2115 16.032 18.44 15.8107 18.6047C15.6146 18.7505 15.3935 18.8592 15.1583 18.9253C14.8928 19 14.6042 19 14.0271 19H6.2C5.07989 19 4.51984 19 4.09202 18.782C3.71569 18.5903 3.40973 18.2843 3.21799 17.908C3 17.4802 3 16.9201 3 15.8V8.2C3 7.0799 3 6.51984 3.21799 6.09202C3.40973 5.71569 3.71569 5.40973 4.09202 5.21799C4.51984 5 5.07989 5 6.2 5H14.0271C14.6042 5 14.8928 5 15.1583 5.07467C15.3935 5.14081 15.6146 5.2495 15.8107 5.39534C16.032 5.55998 16.2082 5.78846 16.5608 6.24543L19.4922 10.0454C20.0318 10.7449 20.3016 11.0947 20.4054 11.4804C20.4969 11.8207 20.4969 12.1793 20.4054 12.5196C20.3016 12.9053 20.0318 13.2551 19.4922 13.9546Z"
                          stroke="#fff"
                          strokeWidth="1.6"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span className="pl-2">Delete</span>
                    </Button>
                    {/* { this.props.showMultipleItemsDeleteModal === true ? console.log('true'): console.log('false')}
                        {
                            this.props.showMultipleItemsDeleteModal && (<DeleteMultipleItemsModal showModal ={this.props.showMultipleItemsDeleteModal} hideModal ={!this.props.showMultipleItemsDeleteModal} ownedByOthers={this.props.selectedParts?.filter((part)=> part.creator !== this.props.creator)}></DeleteMultipleItemsModal>)
                        } */}
                  </div>
                </>
              ) : null}
            </>
            <>
              {this.props.selectedTabIndex === TabIndex.PARTS ? (
                <>
                  <div className="import">
                    <Button
                      variant="secondary"
                      className="btn-space"
                      disabled={this.props.isLoadingInProgress}
                      onClick={this.handleModalShow}
                    >
                      <img
                        src="/import-icon.svg"
                        className="pr-3"
                        alt="Import Parts Icon"
                      />
                      Import Parts
                    </Button>
                  </div>

                  <div className="create-new-plate pl-4">
                    <Button
                      className={
                        this.props.selectedPartIds &&
                        this.props.selectedPartIds.length > 0
                          ? "active"
                          : "btn-inactive"
                      }
                      // className="btn-space ml-0"
                      onClick={() => this.createNewPlate()}
                    >
                      Start New Plate <span className="pr-2"> + </span>
                    </Button>
                  </div>
                </>
              ) : null}
            </>
            <>
              {this.props.selectedTabIndex === TabIndex.PLATES ? (
                <>
                  <div className="import">
                    <Button
                      // className={(this.props.selectedPlate && this.props.selectedPlate.length !== 0) || (this.props.selectedSearchPlate && this.props.selectedSearchPlate.length !== 0) ? "active" : "btn-inactive"}
                      variant="secondary"
                      disabled={this.props.isLoadingInProgress || !this.props.isPlateSelected}
                      onClick={() => {
                        this.updateAndRedirect();
                        this.props.onHomePageTabChange(TabIndex.PARTS);
                      }}
                    >
                      Edit Build Plate
                      <img
                        src="/edit-build-icon.svg"
                        className="pl-2"
                        alt="Edit Build Plate Icon"
                      />
                    </Button>
                  </div>

                  <div className="create-new-plate d-none">
                    <Button className="ml-4" onClick={() => {}}>
                      Configure Printer Settings{" "}
                      <span className="btn-right-arrow pl-2"></span>
                    </Button>
                  </div>
                </>
              ) : null}
            </>
            <>
              {this.props.selectedTabIndex === TabIndex.BUILDS ? (
                <>
                  <div className="create-folder">
                    <Button
                      variant="secondary"
                      disabled={this.props.isLoadingInProgress}
                      onClick={() => this.props.onNewFolderOpenDialog()}
                    >
                      <img
                        src="/folder-icon.png"
                        className="pr-1"
                        alt="Create Folder Icon"
                      />{" "}
                      Create Folder
                    </Button>
                  </div>
                  <div className="delete">
                    <Button
                      className={(
                        (this.props.selectedPartIds.length > 0 ||
                        this.props.selectedBuildIds.length > 0 ||
                        this.props.selectedPlateIds.length > 0) && this.props.enableBatchDelete)
                          ? "active btn-space"
                          : "btn-inactive btn-space"
                      }
                      variant="secondary"
                      onClick={() => {
                        this.setState({ isModalOpen: true });
                      }}
                    >
                      <svg
                        width="25px"
                        height="25px"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7 9.5L12 14.5M12 9.5L7 14.5M19.4922 13.9546L16.5608 17.7546C16.2082 18.2115 16.032 18.44 15.8107 18.6047C15.6146 18.7505 15.3935 18.8592 15.1583 18.9253C14.8928 19 14.6042 19 14.0271 19H6.2C5.07989 19 4.51984 19 4.09202 18.782C3.71569 18.5903 3.40973 18.2843 3.21799 17.908C3 17.4802 3 16.9201 3 15.8V8.2C3 7.0799 3 6.51984 3.21799 6.09202C3.40973 5.71569 3.71569 5.40973 4.09202 5.21799C4.51984 5 5.07989 5 6.2 5H14.0271C14.6042 5 14.8928 5 15.1583 5.07467C15.3935 5.14081 15.6146 5.2495 15.8107 5.39534C16.032 5.55998 16.2082 5.78846 16.5608 6.24543L19.4922 10.0454C20.0318 10.7449 20.3016 11.0947 20.4054 11.4804C20.4969 11.8207 20.4969 12.1793 20.4054 12.5196C20.3016 12.9053 20.0318 13.2551 19.4922 13.9546Z"
                          stroke="#fff"
                          strokeWidth="1.6"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span className="pl-2">Delete</span>
                    </Button>
                    {/* { this.props.showMultipleItemsDeleteModal === true ? console.log('true'): console.log('false')}
                        {
                            this.props.showMultipleItemsDeleteModal && (<DeleteMultipleItemsModal showModal ={this.props.showMultipleItemsDeleteModal} hideModal ={!this.props.showMultipleItemsDeleteModal} ownedByOthers={this.props.selectedParts?.filter((part)=> part.creator !== this.props.creator)}></DeleteMultipleItemsModal>)
                        } */}
                  </div>
                </>
              ) : null}
            </>
          </div>
        </div>
        <ImportPart
          showModal={this.state.showImportPartswModal}
          hideModal={this.handleModalClose}
        ></ImportPart>
      </>
    );
  }
}

const TabBar = withRouter(connect(Props, Actions)(Component));

export default TabBar;
