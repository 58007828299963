/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getBuild = /* GraphQL */ `query GetBuild($id: ID!) {
  getBuild(id: $id) {
    id
    name
    hasPlates
    plates
    created_by
    created_at
    current_status
    dumb
    build_result
    machineId
    recipeId
    millConfigId
    materialConfigId
    path
    gcodeUUID
    bundle
    build_time
    canceled_by
    messages
    print
    moho_version
    marker_name
    Min_Layer
    Max_Layer
    Max_Slab
    Slab_Layers
    Contouring
    Process
    cloudWatchLogName
    overrides
    machineConfig
    millConfig
    materialConfig
    recipe
    parts
    build_json
    machine_info
    result_json
    gcode_uuid
    cloudWatch
    folders
    hasFolders
    tags
    hasTags
    hasParts
    build_messages
    build_search_string
    modified_by
    modified_at
    metrics
    build_complexity_status
    noOfParts
    total_time
    extruded
    purged
    sendToPrinter
    scaling
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedQuery<APITypes.GetBuildQueryVariables, APITypes.GetBuildQuery>;
export const listBuilds = /* GraphQL */ `query ListBuilds(
  $id: ID
  $filter: ModelBuildFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listBuilds(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      name
      hasPlates
      plates
      created_by
      created_at
      current_status
      dumb
      build_result
      machineId
      recipeId
      millConfigId
      materialConfigId
      path
      gcodeUUID
      bundle
      build_time
      canceled_by
      messages
      print
      moho_version
      marker_name
      Min_Layer
      Max_Layer
      Max_Slab
      Slab_Layers
      Contouring
      Process
      cloudWatchLogName
      overrides
      machineConfig
      millConfig
      materialConfig
      recipe
      parts
      build_json
      machine_info
      result_json
      gcode_uuid
      cloudWatch
      folders
      hasFolders
      tags
      hasTags
      hasParts
      build_messages
      build_search_string
      modified_by
      modified_at
      metrics
      build_complexity_status
      noOfParts
      total_time
      extruded
      purged
      sendToPrinter
      scaling
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.ListBuildsQueryVariables,
  APITypes.ListBuildsQuery
>;
export const syncBuilds = /* GraphQL */ `query SyncBuilds(
  $filter: ModelBuildFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncBuilds(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      hasPlates
      plates
      created_by
      created_at
      current_status
      dumb
      build_result
      machineId
      recipeId
      millConfigId
      materialConfigId
      path
      gcodeUUID
      bundle
      build_time
      canceled_by
      messages
      print
      moho_version
      marker_name
      Min_Layer
      Max_Layer
      Max_Slab
      Slab_Layers
      Contouring
      Process
      cloudWatchLogName
      overrides
      machineConfig
      millConfig
      materialConfig
      recipe
      parts
      build_json
      machine_info
      result_json
      gcode_uuid
      cloudWatch
      folders
      hasFolders
      tags
      hasTags
      hasParts
      build_messages
      build_search_string
      modified_by
      modified_at
      metrics
      build_complexity_status
      noOfParts
      total_time
      extruded
      purged
      sendToPrinter
      scaling
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.SyncBuildsQueryVariables,
  APITypes.SyncBuildsQuery
>;
export const buildByInitiated = /* GraphQL */ `query BuildByInitiated(
  $created_by: String!
  $created_at: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBuildFilterInput
  $limit: Int
  $nextToken: String
) {
  buildByInitiated(
    created_by: $created_by
    created_at: $created_at
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      hasPlates
      plates
      created_by
      created_at
      current_status
      dumb
      build_result
      machineId
      recipeId
      millConfigId
      materialConfigId
      path
      gcodeUUID
      bundle
      build_time
      canceled_by
      messages
      print
      moho_version
      marker_name
      Min_Layer
      Max_Layer
      Max_Slab
      Slab_Layers
      Contouring
      Process
      cloudWatchLogName
      overrides
      machineConfig
      millConfig
      materialConfig
      recipe
      parts
      build_json
      machine_info
      result_json
      gcode_uuid
      cloudWatch
      folders
      hasFolders
      tags
      hasTags
      hasParts
      build_messages
      build_search_string
      modified_by
      modified_at
      metrics
      build_complexity_status
      noOfParts
      total_time
      extruded
      purged
      sendToPrinter
      scaling
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.BuildByInitiatedQueryVariables,
  APITypes.BuildByInitiatedQuery
>;
export const buildByCurrentStatus = /* GraphQL */ `query BuildByCurrentStatus(
  $current_status: String!
  $created_at: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBuildFilterInput
  $limit: Int
  $nextToken: String
) {
  buildByCurrentStatus(
    current_status: $current_status
    created_at: $created_at
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      hasPlates
      plates
      created_by
      created_at
      current_status
      dumb
      build_result
      machineId
      recipeId
      millConfigId
      materialConfigId
      path
      gcodeUUID
      bundle
      build_time
      canceled_by
      messages
      print
      moho_version
      marker_name
      Min_Layer
      Max_Layer
      Max_Slab
      Slab_Layers
      Contouring
      Process
      cloudWatchLogName
      overrides
      machineConfig
      millConfig
      materialConfig
      recipe
      parts
      build_json
      machine_info
      result_json
      gcode_uuid
      cloudWatch
      folders
      hasFolders
      tags
      hasTags
      hasParts
      build_messages
      build_search_string
      modified_by
      modified_at
      metrics
      build_complexity_status
      noOfParts
      total_time
      extruded
      purged
      sendToPrinter
      scaling
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.BuildByCurrentStatusQueryVariables,
  APITypes.BuildByCurrentStatusQuery
>;
export const buildByCreatedAt = /* GraphQL */ `query BuildByCreatedAt(
  $dumb: Int!
  $created_at: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBuildFilterInput
  $limit: Int
  $nextToken: String
) {
  buildByCreatedAt(
    dumb: $dumb
    created_at: $created_at
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      hasPlates
      plates
      created_by
      created_at
      current_status
      dumb
      build_result
      machineId
      recipeId
      millConfigId
      materialConfigId
      path
      gcodeUUID
      bundle
      build_time
      canceled_by
      messages
      print
      moho_version
      marker_name
      Min_Layer
      Max_Layer
      Max_Slab
      Slab_Layers
      Contouring
      Process
      cloudWatchLogName
      overrides
      machineConfig
      millConfig
      materialConfig
      recipe
      parts
      build_json
      machine_info
      result_json
      gcode_uuid
      cloudWatch
      folders
      hasFolders
      tags
      hasTags
      hasParts
      build_messages
      build_search_string
      modified_by
      modified_at
      metrics
      build_complexity_status
      noOfParts
      total_time
      extruded
      purged
      sendToPrinter
      scaling
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.BuildByCreatedAtQueryVariables,
  APITypes.BuildByCreatedAtQuery
>;
export const buildByGcodeUUID = /* GraphQL */ `query BuildByGcodeUUID(
  $gcode_uuid: String!
  $sortDirection: ModelSortDirection
  $filter: ModelBuildFilterInput
  $limit: Int
  $nextToken: String
) {
  buildByGcodeUUID(
    gcode_uuid: $gcode_uuid
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      hasPlates
      plates
      created_by
      created_at
      current_status
      dumb
      build_result
      machineId
      recipeId
      millConfigId
      materialConfigId
      path
      gcodeUUID
      bundle
      build_time
      canceled_by
      messages
      print
      moho_version
      marker_name
      Min_Layer
      Max_Layer
      Max_Slab
      Slab_Layers
      Contouring
      Process
      cloudWatchLogName
      overrides
      machineConfig
      millConfig
      materialConfig
      recipe
      parts
      build_json
      machine_info
      result_json
      gcode_uuid
      cloudWatch
      folders
      hasFolders
      tags
      hasTags
      hasParts
      build_messages
      build_search_string
      modified_by
      modified_at
      metrics
      build_complexity_status
      noOfParts
      total_time
      extruded
      purged
      sendToPrinter
      scaling
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.BuildByGcodeUUIDQueryVariables,
  APITypes.BuildByGcodeUUIDQuery
>;
export const getParts = /* GraphQL */ `query GetParts($id: ID!) {
  getParts(id: $id) {
    id
    name
    creator
    created_at
    modifier
    modified_at
    files
    icon
    dumb
    archive
    folders
    hasFolders
    tags
    hasTags
    hasPlates
    plates
    hasBuilds
    builds
    metrics
    parts_search_string
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedQuery<APITypes.GetPartsQueryVariables, APITypes.GetPartsQuery>;
export const listParts = /* GraphQL */ `query ListParts(
  $id: ID
  $filter: ModelPartsFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listParts(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      name
      creator
      created_at
      modifier
      modified_at
      files
      icon
      dumb
      archive
      folders
      hasFolders
      tags
      hasTags
      hasPlates
      plates
      hasBuilds
      builds
      metrics
      parts_search_string
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<APITypes.ListPartsQueryVariables, APITypes.ListPartsQuery>;
export const syncParts = /* GraphQL */ `query SyncParts(
  $filter: ModelPartsFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncParts(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      creator
      created_at
      modifier
      modified_at
      files
      icon
      dumb
      archive
      folders
      hasFolders
      tags
      hasTags
      hasPlates
      plates
      hasBuilds
      builds
      metrics
      parts_search_string
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<APITypes.SyncPartsQueryVariables, APITypes.SyncPartsQuery>;
export const partByCreatedAt = /* GraphQL */ `query PartByCreatedAt(
  $dumb: Int!
  $created_at: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPartsFilterInput
  $limit: Int
  $nextToken: String
) {
  partByCreatedAt(
    dumb: $dumb
    created_at: $created_at
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      creator
      created_at
      modifier
      modified_at
      files
      icon
      dumb
      archive
      folders
      hasFolders
      tags
      hasTags
      hasPlates
      plates
      hasBuilds
      builds
      metrics
      parts_search_string
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.PartByCreatedAtQueryVariables,
  APITypes.PartByCreatedAtQuery
>;
export const partArchivedByCreatedAt = /* GraphQL */ `query PartArchivedByCreatedAt(
  $archive: String!
  $created_at: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPartsFilterInput
  $limit: Int
  $nextToken: String
) {
  partArchivedByCreatedAt(
    archive: $archive
    created_at: $created_at
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      creator
      created_at
      modifier
      modified_at
      files
      icon
      dumb
      archive
      folders
      hasFolders
      tags
      hasTags
      hasPlates
      plates
      hasBuilds
      builds
      metrics
      parts_search_string
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.PartArchivedByCreatedAtQueryVariables,
  APITypes.PartArchivedByCreatedAtQuery
>;
export const getConfigurations = /* GraphQL */ `query GetConfigurations($id: ID!) {
  getConfigurations(id: $id) {
    id
    name
    type
    created_at
    modified_at
    data
    dumb
    default
    hasPlates
    plates
    creator
    modifier
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedQuery<
  APITypes.GetConfigurationsQueryVariables,
  APITypes.GetConfigurationsQuery
>;
export const listConfigurations = /* GraphQL */ `query ListConfigurations(
  $id: ID
  $filter: ModelConfigurationsFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listConfigurations(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      name
      type
      created_at
      modified_at
      data
      dumb
      default
      hasPlates
      plates
      creator
      modifier
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.ListConfigurationsQueryVariables,
  APITypes.ListConfigurationsQuery
>;
export const syncConfigurations = /* GraphQL */ `query SyncConfigurations(
  $filter: ModelConfigurationsFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncConfigurations(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      type
      created_at
      modified_at
      data
      dumb
      default
      hasPlates
      plates
      creator
      modifier
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.SyncConfigurationsQueryVariables,
  APITypes.SyncConfigurationsQuery
>;
export const configurationTypeByCreatedAt = /* GraphQL */ `query ConfigurationTypeByCreatedAt(
  $type: String!
  $created_at: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelConfigurationsFilterInput
  $limit: Int
  $nextToken: String
) {
  configurationTypeByCreatedAt(
    type: $type
    created_at: $created_at
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      type
      created_at
      modified_at
      data
      dumb
      default
      hasPlates
      plates
      creator
      modifier
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.ConfigurationTypeByCreatedAtQueryVariables,
  APITypes.ConfigurationTypeByCreatedAtQuery
>;
export const configurationByCreatedAt = /* GraphQL */ `query ConfigurationByCreatedAt(
  $dumb: Int!
  $created_at: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelConfigurationsFilterInput
  $limit: Int
  $nextToken: String
) {
  configurationByCreatedAt(
    dumb: $dumb
    created_at: $created_at
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      type
      created_at
      modified_at
      data
      dumb
      default
      hasPlates
      plates
      creator
      modifier
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.ConfigurationByCreatedAtQueryVariables,
  APITypes.ConfigurationByCreatedAtQuery
>;
export const getConfigurationsAllHistory = /* GraphQL */ `query GetConfigurationsAllHistory($id: ID!) {
  getConfigurationsAllHistory(id: $id) {
    id
    ConfigurationGroupId
    name
    type
    created_at
    data
    dumb
    default
    hasPlates
    plates
    eventName
    modified_at
    creator
    modifier
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedQuery<
  APITypes.GetConfigurationsAllHistoryQueryVariables,
  APITypes.GetConfigurationsAllHistoryQuery
>;
export const listConfigurationsAllHistories = /* GraphQL */ `query ListConfigurationsAllHistories(
  $id: ID
  $filter: ModelConfigurationsAllHistoryFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listConfigurationsAllHistories(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      ConfigurationGroupId
      name
      type
      created_at
      data
      dumb
      default
      hasPlates
      plates
      eventName
      modified_at
      creator
      modifier
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.ListConfigurationsAllHistoriesQueryVariables,
  APITypes.ListConfigurationsAllHistoriesQuery
>;
export const syncConfigurationsAllHistories = /* GraphQL */ `query SyncConfigurationsAllHistories(
  $filter: ModelConfigurationsAllHistoryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncConfigurationsAllHistories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      ConfigurationGroupId
      name
      type
      created_at
      data
      dumb
      default
      hasPlates
      plates
      eventName
      modified_at
      creator
      modifier
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.SyncConfigurationsAllHistoriesQueryVariables,
  APITypes.SyncConfigurationsAllHistoriesQuery
>;
export const ConfigurationByGroupIdType = /* GraphQL */ `query ConfigurationByGroupIdType(
  $ConfigurationGroupId: String!
  $modified_at: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelConfigurationsAllHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  ConfigurationByGroupIdType(
    ConfigurationGroupId: $ConfigurationGroupId
    modified_at: $modified_at
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      ConfigurationGroupId
      name
      type
      created_at
      data
      dumb
      default
      hasPlates
      plates
      eventName
      modified_at
      creator
      modifier
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.ConfigurationByGroupIdTypeQueryVariables,
  APITypes.ConfigurationByGroupIdTypeQuery
>;
export const ConfigurationByEventNameType = /* GraphQL */ `query ConfigurationByEventNameType(
  $eventName: String!
  $modified_at: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelConfigurationsAllHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  ConfigurationByEventNameType(
    eventName: $eventName
    modified_at: $modified_at
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      ConfigurationGroupId
      name
      type
      created_at
      data
      dumb
      default
      hasPlates
      plates
      eventName
      modified_at
      creator
      modifier
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.ConfigurationByEventNameTypeQueryVariables,
  APITypes.ConfigurationByEventNameTypeQuery
>;
export const getPrint = /* GraphQL */ `query GetPrint($id: ID!) {
  getPrint(id: $id) {
    id
    bundle_UUID
    start
    end
    visionImages
    thermalImages
    operationTimes
    printerId
    comment
    buildID
    estTotalTime
    totalPercentage
    totalPaste
    printerOwner
    ETA
    numberOfLayers
    layerStatus
    printFeedbackId
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedQuery<APITypes.GetPrintQueryVariables, APITypes.GetPrintQuery>;
export const listPrints = /* GraphQL */ `query ListPrints(
  $id: ID
  $filter: ModelPrintFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listPrints(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      bundle_UUID
      start
      end
      visionImages
      thermalImages
      operationTimes
      printerId
      comment
      buildID
      estTotalTime
      totalPercentage
      totalPaste
      printerOwner
      ETA
      numberOfLayers
      layerStatus
      printFeedbackId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.ListPrintsQueryVariables,
  APITypes.ListPrintsQuery
>;
export const syncPrints = /* GraphQL */ `query SyncPrints(
  $filter: ModelPrintFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPrints(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      bundle_UUID
      start
      end
      visionImages
      thermalImages
      operationTimes
      printerId
      comment
      buildID
      estTotalTime
      totalPercentage
      totalPaste
      printerOwner
      ETA
      numberOfLayers
      layerStatus
      printFeedbackId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.SyncPrintsQueryVariables,
  APITypes.SyncPrintsQuery
>;
export const printByGcodeStart = /* GraphQL */ `query PrintByGcodeStart(
  $bundle_UUID: ID!
  $start: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPrintFilterInput
  $limit: Int
  $nextToken: String
) {
  printByGcodeStart(
    bundle_UUID: $bundle_UUID
    start: $start
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      bundle_UUID
      start
      end
      visionImages
      thermalImages
      operationTimes
      printerId
      comment
      buildID
      estTotalTime
      totalPercentage
      totalPaste
      printerOwner
      ETA
      numberOfLayers
      layerStatus
      printFeedbackId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.PrintByGcodeStartQueryVariables,
  APITypes.PrintByGcodeStartQuery
>;
export const printByPrinterStart = /* GraphQL */ `query PrintByPrinterStart(
  $printerId: String!
  $start: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPrintFilterInput
  $limit: Int
  $nextToken: String
) {
  printByPrinterStart(
    printerId: $printerId
    start: $start
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      bundle_UUID
      start
      end
      visionImages
      thermalImages
      operationTimes
      printerId
      comment
      buildID
      estTotalTime
      totalPercentage
      totalPaste
      printerOwner
      ETA
      numberOfLayers
      layerStatus
      printFeedbackId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.PrintByPrinterStartQueryVariables,
  APITypes.PrintByPrinterStartQuery
>;
export const getPrinter = /* GraphQL */ `query GetPrinter($id: ID!) {
  getPrinter(id: $id) {
    id
    name
    GrafanaBundle
    currentBundle
    bundlePrintStart
    currentStatus
    lastStatusSync
    currentPrint
    currentLayer
    currentStage
    currentPasteAmount
    printEndTime
    pasteEndTime
    lastSyncTime
    lastVisionPath
    lastThermalPath
    currentPrintNameOnPrinter
    remainingPasteConsumption
    remainingPrintTime
    creator
    created_at
    modifier
    modified_at
    tools
    printerPauseCommandStatus
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedQuery<
  APITypes.GetPrinterQueryVariables,
  APITypes.GetPrinterQuery
>;
export const listPrinters = /* GraphQL */ `query ListPrinters(
  $id: ID
  $filter: ModelPrinterFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listPrinters(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      name
      GrafanaBundle
      currentBundle
      bundlePrintStart
      currentStatus
      lastStatusSync
      currentPrint
      currentLayer
      currentStage
      currentPasteAmount
      printEndTime
      pasteEndTime
      lastSyncTime
      lastVisionPath
      lastThermalPath
      currentPrintNameOnPrinter
      remainingPasteConsumption
      remainingPrintTime
      creator
      created_at
      modifier
      modified_at
      tools
      printerPauseCommandStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.ListPrintersQueryVariables,
  APITypes.ListPrintersQuery
>;
export const syncPrinters = /* GraphQL */ `query SyncPrinters(
  $filter: ModelPrinterFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPrinters(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      GrafanaBundle
      currentBundle
      bundlePrintStart
      currentStatus
      lastStatusSync
      currentPrint
      currentLayer
      currentStage
      currentPasteAmount
      printEndTime
      pasteEndTime
      lastSyncTime
      lastVisionPath
      lastThermalPath
      currentPrintNameOnPrinter
      remainingPasteConsumption
      remainingPrintTime
      creator
      created_at
      modifier
      modified_at
      tools
      printerPauseCommandStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.SyncPrintersQueryVariables,
  APITypes.SyncPrintersQuery
>;
export const getPrinterQueue = /* GraphQL */ `query GetPrinterQueue($id: ID!) {
  getPrinterQueue(id: $id) {
    id
    printerId
    buildId
    printName
    estimatedCompletionTime
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedQuery<
  APITypes.GetPrinterQueueQueryVariables,
  APITypes.GetPrinterQueueQuery
>;
export const listPrinterQueues = /* GraphQL */ `query ListPrinterQueues(
  $id: ID
  $filter: ModelPrinterQueueFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listPrinterQueues(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      printerId
      buildId
      printName
      estimatedCompletionTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.ListPrinterQueuesQueryVariables,
  APITypes.ListPrinterQueuesQuery
>;
export const syncPrinterQueues = /* GraphQL */ `query SyncPrinterQueues(
  $filter: ModelPrinterQueueFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPrinterQueues(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      printerId
      buildId
      printName
      estimatedCompletionTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.SyncPrinterQueuesQueryVariables,
  APITypes.SyncPrinterQueuesQuery
>;
export const listPrinterQueuesByPrinter = /* GraphQL */ `query ListPrinterQueuesByPrinter(
  $printerId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPrinterQueueFilterInput
  $limit: Int
  $nextToken: String
) {
  listPrinterQueuesByPrinter(
    printerId: $printerId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      printerId
      buildId
      printName
      estimatedCompletionTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.ListPrinterQueuesByPrinterQueryVariables,
  APITypes.ListPrinterQueuesByPrinterQuery
>;
export const getImage = /* GraphQL */ `query GetImage($printerId: ID!, $time: AWSDateTime!) {
  getImage(printerId: $printerId, time: $time) {
    printerId
    printId
    type
    step
    layer
    path
    time
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedQuery<APITypes.GetImageQueryVariables, APITypes.GetImageQuery>;
export const listImages = /* GraphQL */ `query ListImages(
  $printerId: ID
  $time: ModelStringKeyConditionInput
  $filter: ModelImageFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listImages(
    printerId: $printerId
    time: $time
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      printerId
      printId
      type
      step
      layer
      path
      time
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.ListImagesQueryVariables,
  APITypes.ListImagesQuery
>;
export const syncImages = /* GraphQL */ `query SyncImages(
  $filter: ModelImageFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncImages(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      printerId
      printId
      type
      step
      layer
      path
      time
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.SyncImagesQueryVariables,
  APITypes.SyncImagesQuery
>;
export const imageByPrint = /* GraphQL */ `query ImageByPrint(
  $printId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelImageFilterInput
  $limit: Int
  $nextToken: String
) {
  imageByPrint(
    printId: $printId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      printerId
      printId
      type
      step
      layer
      path
      time
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.ImageByPrintQueryVariables,
  APITypes.ImageByPrintQuery
>;
export const getPlate = /* GraphQL */ `query GetPlate($id: ID!) {
  getPlate(id: $id) {
    id
    name
    created_by
    created_at
    modified_by
    modified_at
    dumb
    machineId
    recipeId
    millConfigId
    materialConfigId
    path
    messages
    print
    moho_version
    marker_name
    Min_Layer
    Max_Layer
    Max_Slab
    Slab_Layers
    Contouring
    Process
    overrides
    machineConfig
    millConfig
    materialConfig
    recipe
    parts
    machine_info
    archive
    hasFolders
    folders
    hasTags
    tags
    hasParts
    hasBuilds
    builds
    hasConfigs
    configs
    plate_search_string
    noOfParts
    scaling
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedQuery<APITypes.GetPlateQueryVariables, APITypes.GetPlateQuery>;
export const listPlates = /* GraphQL */ `query ListPlates(
  $id: ID
  $filter: ModelPlateFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listPlates(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      name
      created_by
      created_at
      modified_by
      modified_at
      dumb
      machineId
      recipeId
      millConfigId
      materialConfigId
      path
      messages
      print
      moho_version
      marker_name
      Min_Layer
      Max_Layer
      Max_Slab
      Slab_Layers
      Contouring
      Process
      overrides
      machineConfig
      millConfig
      materialConfig
      recipe
      parts
      machine_info
      archive
      hasFolders
      folders
      hasTags
      tags
      hasParts
      hasBuilds
      builds
      hasConfigs
      configs
      plate_search_string
      noOfParts
      scaling
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.ListPlatesQueryVariables,
  APITypes.ListPlatesQuery
>;
export const syncPlates = /* GraphQL */ `query SyncPlates(
  $filter: ModelPlateFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPlates(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      created_by
      created_at
      modified_by
      modified_at
      dumb
      machineId
      recipeId
      millConfigId
      materialConfigId
      path
      messages
      print
      moho_version
      marker_name
      Min_Layer
      Max_Layer
      Max_Slab
      Slab_Layers
      Contouring
      Process
      overrides
      machineConfig
      millConfig
      materialConfig
      recipe
      parts
      machine_info
      archive
      hasFolders
      folders
      hasTags
      tags
      hasParts
      hasBuilds
      builds
      hasConfigs
      configs
      plate_search_string
      noOfParts
      scaling
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.SyncPlatesQueryVariables,
  APITypes.SyncPlatesQuery
>;
export const plateByInitiated = /* GraphQL */ `query PlateByInitiated(
  $created_by: String!
  $created_at: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPlateFilterInput
  $limit: Int
  $nextToken: String
) {
  plateByInitiated(
    created_by: $created_by
    created_at: $created_at
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      created_by
      created_at
      modified_by
      modified_at
      dumb
      machineId
      recipeId
      millConfigId
      materialConfigId
      path
      messages
      print
      moho_version
      marker_name
      Min_Layer
      Max_Layer
      Max_Slab
      Slab_Layers
      Contouring
      Process
      overrides
      machineConfig
      millConfig
      materialConfig
      recipe
      parts
      machine_info
      archive
      hasFolders
      folders
      hasTags
      tags
      hasParts
      hasBuilds
      builds
      hasConfigs
      configs
      plate_search_string
      noOfParts
      scaling
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.PlateByInitiatedQueryVariables,
  APITypes.PlateByInitiatedQuery
>;
export const plateByCreatedAt = /* GraphQL */ `query PlateByCreatedAt(
  $dumb: Int!
  $created_at: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPlateFilterInput
  $limit: Int
  $nextToken: String
) {
  plateByCreatedAt(
    dumb: $dumb
    created_at: $created_at
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      created_by
      created_at
      modified_by
      modified_at
      dumb
      machineId
      recipeId
      millConfigId
      materialConfigId
      path
      messages
      print
      moho_version
      marker_name
      Min_Layer
      Max_Layer
      Max_Slab
      Slab_Layers
      Contouring
      Process
      overrides
      machineConfig
      millConfig
      materialConfig
      recipe
      parts
      machine_info
      archive
      hasFolders
      folders
      hasTags
      tags
      hasParts
      hasBuilds
      builds
      hasConfigs
      configs
      plate_search_string
      noOfParts
      scaling
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.PlateByCreatedAtQueryVariables,
  APITypes.PlateByCreatedAtQuery
>;
export const plateArchivedByCreatedAt = /* GraphQL */ `query PlateArchivedByCreatedAt(
  $archive: String!
  $created_at: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPlateFilterInput
  $limit: Int
  $nextToken: String
) {
  plateArchivedByCreatedAt(
    archive: $archive
    created_at: $created_at
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      created_by
      created_at
      modified_by
      modified_at
      dumb
      machineId
      recipeId
      millConfigId
      materialConfigId
      path
      messages
      print
      moho_version
      marker_name
      Min_Layer
      Max_Layer
      Max_Slab
      Slab_Layers
      Contouring
      Process
      overrides
      machineConfig
      millConfig
      materialConfig
      recipe
      parts
      machine_info
      archive
      hasFolders
      folders
      hasTags
      tags
      hasParts
      hasBuilds
      builds
      hasConfigs
      configs
      plate_search_string
      noOfParts
      scaling
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.PlateArchivedByCreatedAtQueryVariables,
  APITypes.PlateArchivedByCreatedAtQuery
>;
export const getPrinterUtilization = /* GraphQL */ `query GetPrinterUtilization($id: ID!) {
  getPrinterUtilization(id: $id) {
    id
    alarmCount
    alarmTotalTime
    runningTotalTime
    stoppedTotalTime
    offlineTime
    startDate
    stopDate
    bucketName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedQuery<
  APITypes.GetPrinterUtilizationQueryVariables,
  APITypes.GetPrinterUtilizationQuery
>;
export const listPrinterUtilizations = /* GraphQL */ `query ListPrinterUtilizations(
  $id: ID
  $filter: ModelPrinterUtilizationFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listPrinterUtilizations(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      alarmCount
      alarmTotalTime
      runningTotalTime
      stoppedTotalTime
      offlineTime
      startDate
      stopDate
      bucketName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.ListPrinterUtilizationsQueryVariables,
  APITypes.ListPrinterUtilizationsQuery
>;
export const syncPrinterUtilizations = /* GraphQL */ `query SyncPrinterUtilizations(
  $filter: ModelPrinterUtilizationFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPrinterUtilizations(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      alarmCount
      alarmTotalTime
      runningTotalTime
      stoppedTotalTime
      offlineTime
      startDate
      stopDate
      bucketName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.SyncPrinterUtilizationsQueryVariables,
  APITypes.SyncPrinterUtilizationsQuery
>;
export const printerUtilizationBystartDate = /* GraphQL */ `query PrinterUtilizationBystartDate(
  $bucketName: String!
  $startDate: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPrinterUtilizationFilterInput
  $limit: Int
  $nextToken: String
) {
  printerUtilizationBystartDate(
    bucketName: $bucketName
    startDate: $startDate
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      alarmCount
      alarmTotalTime
      runningTotalTime
      stoppedTotalTime
      offlineTime
      startDate
      stopDate
      bucketName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.PrinterUtilizationBystartDateQueryVariables,
  APITypes.PrinterUtilizationBystartDateQuery
>;
export const getFolders = /* GraphQL */ `query GetFolders($id: ID!) {
  getFolders(id: $id) {
    id
    name
    hasParts
    noOfParts
    hasPlates
    noOfPlates
    hasBuilds
    noOfBuilds
    creator
    created_at
    modifier
    modified_at
    dumb
    archive
    parts
    plates
    builds
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedQuery<
  APITypes.GetFoldersQueryVariables,
  APITypes.GetFoldersQuery
>;
export const listFolders = /* GraphQL */ `query ListFolders(
  $id: ID
  $filter: ModelFoldersFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listFolders(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      name
      hasParts
      noOfParts
      hasPlates
      noOfPlates
      hasBuilds
      noOfBuilds
      creator
      created_at
      modifier
      modified_at
      dumb
      archive
      parts
      plates
      builds
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.ListFoldersQueryVariables,
  APITypes.ListFoldersQuery
>;
export const syncFolders = /* GraphQL */ `query SyncFolders(
  $filter: ModelFoldersFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncFolders(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      hasParts
      noOfParts
      hasPlates
      noOfPlates
      hasBuilds
      noOfBuilds
      creator
      created_at
      modifier
      modified_at
      dumb
      archive
      parts
      plates
      builds
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.SyncFoldersQueryVariables,
  APITypes.SyncFoldersQuery
>;
export const foldersByCreatedAt = /* GraphQL */ `query FoldersByCreatedAt(
  $dumb: Int!
  $created_at: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFoldersFilterInput
  $limit: Int
  $nextToken: String
) {
  foldersByCreatedAt(
    dumb: $dumb
    created_at: $created_at
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      hasParts
      noOfParts
      hasPlates
      noOfPlates
      hasBuilds
      noOfBuilds
      creator
      created_at
      modifier
      modified_at
      dumb
      archive
      parts
      plates
      builds
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.FoldersByCreatedAtQueryVariables,
  APITypes.FoldersByCreatedAtQuery
>;
export const foldersArchivedByCreatedAt = /* GraphQL */ `query FoldersArchivedByCreatedAt(
  $archive: String!
  $created_at: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFoldersFilterInput
  $limit: Int
  $nextToken: String
) {
  foldersArchivedByCreatedAt(
    archive: $archive
    created_at: $created_at
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      hasParts
      noOfParts
      hasPlates
      noOfPlates
      hasBuilds
      noOfBuilds
      creator
      created_at
      modifier
      modified_at
      dumb
      archive
      parts
      plates
      builds
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.FoldersArchivedByCreatedAtQueryVariables,
  APITypes.FoldersArchivedByCreatedAtQuery
>;
export const getTags = /* GraphQL */ `query GetTags($id: ID!) {
  getTags(id: $id) {
    id
    name
    hasParts
    noOfParts
    hasPlates
    noOfPlates
    hasBuilds
    noOfBuilds
    creator
    created_at
    modifier
    modified_at
    dumb
    archive
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedQuery<APITypes.GetTagsQueryVariables, APITypes.GetTagsQuery>;
export const listTags = /* GraphQL */ `query ListTags(
  $id: ID
  $filter: ModelTagsFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listTags(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      name
      hasParts
      noOfParts
      hasPlates
      noOfPlates
      hasBuilds
      noOfBuilds
      creator
      created_at
      modifier
      modified_at
      dumb
      archive
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<APITypes.ListTagsQueryVariables, APITypes.ListTagsQuery>;
export const syncTags = /* GraphQL */ `query SyncTags(
  $filter: ModelTagsFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTags(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      hasParts
      noOfParts
      hasPlates
      noOfPlates
      hasBuilds
      noOfBuilds
      creator
      created_at
      modifier
      modified_at
      dumb
      archive
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<APITypes.SyncTagsQueryVariables, APITypes.SyncTagsQuery>;
export const tagsByCreatedAt = /* GraphQL */ `query TagsByCreatedAt(
  $dumb: Int!
  $created_at: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTagsFilterInput
  $limit: Int
  $nextToken: String
) {
  tagsByCreatedAt(
    dumb: $dumb
    created_at: $created_at
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      hasParts
      noOfParts
      hasPlates
      noOfPlates
      hasBuilds
      noOfBuilds
      creator
      created_at
      modifier
      modified_at
      dumb
      archive
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.TagsByCreatedAtQueryVariables,
  APITypes.TagsByCreatedAtQuery
>;
export const tagsArchivedByCreatedAt = /* GraphQL */ `query TagsArchivedByCreatedAt(
  $archive: String!
  $created_at: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTagsFilterInput
  $limit: Int
  $nextToken: String
) {
  tagsArchivedByCreatedAt(
    archive: $archive
    created_at: $created_at
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      hasParts
      noOfParts
      hasPlates
      noOfPlates
      hasBuilds
      noOfBuilds
      creator
      created_at
      modifier
      modified_at
      dumb
      archive
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.TagsArchivedByCreatedAtQueryVariables,
  APITypes.TagsArchivedByCreatedAtQuery
>;
export const getPrintFeedback = /* GraphQL */ `query GetPrintFeedback($id: ID!) {
  getPrintFeedback(id: $id) {
    id
    Feedback
    printerId
    printId
    bundle_UUID
    buildID
    creator
    created_at
    modifier
    modified_at
    imageUrls
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedQuery<
  APITypes.GetPrintFeedbackQueryVariables,
  APITypes.GetPrintFeedbackQuery
>;
export const listPrintFeedbacks = /* GraphQL */ `query ListPrintFeedbacks(
  $id: ID
  $filter: ModelPrintFeedbackFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listPrintFeedbacks(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      Feedback
      printerId
      printId
      bundle_UUID
      buildID
      creator
      created_at
      modifier
      modified_at
      imageUrls
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.ListPrintFeedbacksQueryVariables,
  APITypes.ListPrintFeedbacksQuery
>;
export const syncPrintFeedbacks = /* GraphQL */ `query SyncPrintFeedbacks(
  $filter: ModelPrintFeedbackFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPrintFeedbacks(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      Feedback
      printerId
      printId
      bundle_UUID
      buildID
      creator
      created_at
      modifier
      modified_at
      imageUrls
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.SyncPrintFeedbacksQueryVariables,
  APITypes.SyncPrintFeedbacksQuery
>;
export const getDistribution = /* GraphQL */ `query GetDistribution($product_id: ID!, $build_id: String!) {
  getDistribution(product_id: $product_id, build_id: $build_id) {
    product_id
    build_id
    commit_url
    deployed_at
    InstallerBucket
    InstallerBucketPath
    mw_expiration_date
    release_note
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedQuery<
  APITypes.GetDistributionQueryVariables,
  APITypes.GetDistributionQuery
>;
export const listDistributions = /* GraphQL */ `query ListDistributions(
  $product_id: ID
  $build_id: ModelStringKeyConditionInput
  $filter: ModelDistributionFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listDistributions(
    product_id: $product_id
    build_id: $build_id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      product_id
      build_id
      commit_url
      deployed_at
      InstallerBucket
      InstallerBucketPath
      mw_expiration_date
      release_note
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.ListDistributionsQueryVariables,
  APITypes.ListDistributionsQuery
>;
export const syncDistributions = /* GraphQL */ `query SyncDistributions(
  $filter: ModelDistributionFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncDistributions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      product_id
      build_id
      commit_url
      deployed_at
      InstallerBucket
      InstallerBucketPath
      mw_expiration_date
      release_note
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.SyncDistributionsQueryVariables,
  APITypes.SyncDistributionsQuery
>;
export const getEnvironments = /* GraphQL */ `query GetEnvironments($id: ID!) {
  getEnvironments(id: $id) {
    id
    build_id
    commit_url
    product_id
    send_domain
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedQuery<
  APITypes.GetEnvironmentsQueryVariables,
  APITypes.GetEnvironmentsQuery
>;
export const listEnvironments = /* GraphQL */ `query ListEnvironments(
  $filter: ModelEnvironmentsFilterInput
  $limit: Int
  $nextToken: String
) {
  listEnvironments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      build_id
      commit_url
      product_id
      send_domain
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.ListEnvironmentsQueryVariables,
  APITypes.ListEnvironmentsQuery
>;
export const syncEnvironments = /* GraphQL */ `query SyncEnvironments(
  $filter: ModelEnvironmentsFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncEnvironments(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      build_id
      commit_url
      product_id
      send_domain
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.SyncEnvironmentsQueryVariables,
  APITypes.SyncEnvironmentsQuery
>;
export const getPartsSearch = /* GraphQL */ `query GetPartsSearch($id: ID!) {
  getPartsSearch(id: $id) {
    id
    parts_search_string
    dumb
    created_by
    created_at
    modified_by
    modified_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedQuery<
  APITypes.GetPartsSearchQueryVariables,
  APITypes.GetPartsSearchQuery
>;
export const listPartsSearches = /* GraphQL */ `query ListPartsSearches(
  $id: ID
  $filter: ModelPartsSearchFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listPartsSearches(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      parts_search_string
      dumb
      created_by
      created_at
      modified_by
      modified_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.ListPartsSearchesQueryVariables,
  APITypes.ListPartsSearchesQuery
>;
export const syncPartsSearches = /* GraphQL */ `query SyncPartsSearches(
  $filter: ModelPartsSearchFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPartsSearches(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      parts_search_string
      dumb
      created_by
      created_at
      modified_by
      modified_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.SyncPartsSearchesQueryVariables,
  APITypes.SyncPartsSearchesQuery
>;
export const partsSearchByCreatedAt = /* GraphQL */ `query PartsSearchByCreatedAt(
  $dumb: Int!
  $created_at: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPartsSearchFilterInput
  $limit: Int
  $nextToken: String
) {
  partsSearchByCreatedAt(
    dumb: $dumb
    created_at: $created_at
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      parts_search_string
      dumb
      created_by
      created_at
      modified_by
      modified_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.PartsSearchByCreatedAtQueryVariables,
  APITypes.PartsSearchByCreatedAtQuery
>;
export const getPlateSearch = /* GraphQL */ `query GetPlateSearch($id: ID!) {
  getPlateSearch(id: $id) {
    id
    plate_search_string
    dumb
    created_by
    created_at
    modified_by
    modified_at
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedQuery<
  APITypes.GetPlateSearchQueryVariables,
  APITypes.GetPlateSearchQuery
>;
export const listPlateSearches = /* GraphQL */ `query ListPlateSearches(
  $id: ID
  $filter: ModelPlateSearchFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listPlateSearches(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      plate_search_string
      dumb
      created_by
      created_at
      modified_by
      modified_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.ListPlateSearchesQueryVariables,
  APITypes.ListPlateSearchesQuery
>;
export const syncPlateSearches = /* GraphQL */ `query SyncPlateSearches(
  $filter: ModelPlateSearchFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPlateSearches(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      plate_search_string
      dumb
      created_by
      created_at
      modified_by
      modified_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.SyncPlateSearchesQueryVariables,
  APITypes.SyncPlateSearchesQuery
>;
export const plateSearchByCreatedAt = /* GraphQL */ `query PlateSearchByCreatedAt(
  $dumb: Int!
  $created_at: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPlateSearchFilterInput
  $limit: Int
  $nextToken: String
) {
  plateSearchByCreatedAt(
    dumb: $dumb
    created_at: $created_at
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      plate_search_string
      dumb
      created_by
      created_at
      modified_by
      modified_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.PlateSearchByCreatedAtQueryVariables,
  APITypes.PlateSearchByCreatedAtQuery
>;
export const getBuildSearch = /* GraphQL */ `query GetBuildSearch($id: ID!) {
  getBuildSearch(id: $id) {
    id
    build_search_string
    dumb
    created_by
    created_at
    modified_by
    modified_at
    moho_version
    current_status
    build_result
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedQuery<
  APITypes.GetBuildSearchQueryVariables,
  APITypes.GetBuildSearchQuery
>;
export const listBuildSearches = /* GraphQL */ `query ListBuildSearches(
  $id: ID
  $filter: ModelBuildSearchFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listBuildSearches(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      build_search_string
      dumb
      created_by
      created_at
      modified_by
      modified_at
      moho_version
      current_status
      build_result
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.ListBuildSearchesQueryVariables,
  APITypes.ListBuildSearchesQuery
>;
export const syncBuildSearches = /* GraphQL */ `query SyncBuildSearches(
  $filter: ModelBuildSearchFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncBuildSearches(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      build_search_string
      dumb
      created_by
      created_at
      modified_by
      modified_at
      moho_version
      current_status
      build_result
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.SyncBuildSearchesQueryVariables,
  APITypes.SyncBuildSearchesQuery
>;
export const buildSearchByCreatedAt = /* GraphQL */ `query BuildSearchByCreatedAt(
  $dumb: Int!
  $created_at: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBuildSearchFilterInput
  $limit: Int
  $nextToken: String
) {
  buildSearchByCreatedAt(
    dumb: $dumb
    created_at: $created_at
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      build_search_string
      dumb
      created_by
      created_at
      modified_by
      modified_at
      moho_version
      current_status
      build_result
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.BuildSearchByCreatedAtQueryVariables,
  APITypes.BuildSearchByCreatedAtQuery
>;
export const getPrinterCommand = /* GraphQL */ `query GetPrinterCommand($TransactionID: ID!) {
  getPrinterCommand(TransactionID: $TransactionID) {
    TransactionID
    Acknowledgement
    Command
    DeviceID
    MessageReceivedAt
    RecordCreatedAt
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedQuery<
  APITypes.GetPrinterCommandQueryVariables,
  APITypes.GetPrinterCommandQuery
>;
export const listPrinterCommands = /* GraphQL */ `query ListPrinterCommands(
  $TransactionID: ID
  $filter: ModelPrinterCommandFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listPrinterCommands(
    TransactionID: $TransactionID
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      TransactionID
      Acknowledgement
      Command
      DeviceID
      MessageReceivedAt
      RecordCreatedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.ListPrinterCommandsQueryVariables,
  APITypes.ListPrinterCommandsQuery
>;
export const syncPrinterCommands = /* GraphQL */ `query SyncPrinterCommands(
  $filter: ModelPrinterCommandFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPrinterCommands(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      TransactionID
      Acknowledgement
      Command
      DeviceID
      MessageReceivedAt
      RecordCreatedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.SyncPrinterCommandsQueryVariables,
  APITypes.SyncPrinterCommandsQuery
>;
export const getHelp = /* GraphQL */ `query GetHelp($id: ID!) {
  getHelp(id: $id) {
    id
    helpDocumentName
    helpDocumentComment
    helpDocumentTypeLink
    created_by
    created_at
    modified_by
    modified_at
    helpDocumentType
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedQuery<APITypes.GetHelpQueryVariables, APITypes.GetHelpQuery>;
export const listHelps = /* GraphQL */ `query ListHelps(
  $id: ID
  $filter: ModelHelpFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listHelps(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      helpDocumentName
      helpDocumentComment
      helpDocumentTypeLink
      created_by
      created_at
      modified_by
      modified_at
      helpDocumentType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<APITypes.ListHelpsQueryVariables, APITypes.ListHelpsQuery>;
export const syncHelps = /* GraphQL */ `query SyncHelps(
  $filter: ModelHelpFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncHelps(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      helpDocumentName
      helpDocumentComment
      helpDocumentTypeLink
      created_by
      created_at
      modified_by
      modified_at
      helpDocumentType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<APITypes.SyncHelpsQueryVariables, APITypes.SyncHelpsQuery>;
export const helpByDocumentType = /* GraphQL */ `query HelpByDocumentType(
  $helpDocumentType: String!
  $created_at: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelHelpFilterInput
  $limit: Int
  $nextToken: String
) {
  helpByDocumentType(
    helpDocumentType: $helpDocumentType
    created_at: $created_at
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      helpDocumentName
      helpDocumentComment
      helpDocumentTypeLink
      created_by
      created_at
      modified_by
      modified_at
      helpDocumentType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.HelpByDocumentTypeQueryVariables,
  APITypes.HelpByDocumentTypeQuery
>;
export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    firstName
    lastName
    email
    phoneNo
    roleId
    status
    notifications
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $id: ID
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUsers(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      firstName
      lastName
      email
      phoneNo
      roleId
      status
      notifications
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const syncUsers = /* GraphQL */ `query SyncUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncUsers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      firstName
      lastName
      email
      phoneNo
      roleId
      status
      notifications
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<APITypes.SyncUsersQueryVariables, APITypes.SyncUsersQuery>;
export const getNotification = /* GraphQL */ `query GetNotification($id: ID!) {
  getNotification(id: $id) {
    id
    notificationType
    notificationMsg
    sendText
    sendEmail
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedQuery<
  APITypes.GetNotificationQueryVariables,
  APITypes.GetNotificationQuery
>;
export const listNotifications = /* GraphQL */ `query ListNotifications(
  $id: ID
  $filter: ModelNotificationFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listNotifications(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      notificationType
      notificationMsg
      sendText
      sendEmail
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.ListNotificationsQueryVariables,
  APITypes.ListNotificationsQuery
>;
export const syncNotifications = /* GraphQL */ `query SyncNotifications(
  $filter: ModelNotificationFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncNotifications(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      notificationType
      notificationMsg
      sendText
      sendEmail
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<
  APITypes.SyncNotificationsQueryVariables,
  APITypes.SyncNotificationsQuery
>;
export const getRole = /* GraphQL */ `query GetRole($id: ID!) {
  getRole(id: $id) {
    id
    roleName
    permissions {
      roleChange
      userManagement
      advancedFeatures
      
    }
    advancedFeatures {
      id
      name
      enabled
      
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    
  }
}
` as GeneratedQuery<APITypes.GetRoleQueryVariables, APITypes.GetRoleQuery>;
export const listRoles = /* GraphQL */ `query ListRoles(
  $id: ID
  $filter: ModelRoleFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listRoles(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      roleName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<APITypes.ListRolesQueryVariables, APITypes.ListRolesQuery>;
export const syncRoles = /* GraphQL */ `query SyncRoles(
  $filter: ModelRoleFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncRoles(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      roleName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      
    }
    nextToken
    startedAt
    
  }
}
` as GeneratedQuery<APITypes.SyncRolesQueryVariables, APITypes.SyncRolesQuery>;
