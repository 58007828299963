import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

interface IState {
    show: boolean;
}

const Props = (state: IState, ownProps: any) => {
    const props = {
        isModalOpen: false,
        hideModal: false,
    };

    return props;
};

const Actions = {
};

class Component extends React.Component<any, any> {
    constructor(props) {
        super(props);

        this.state = {
            show: true,
        }
    }

    public render() {
        return (
            <>
                {
                    (this.props.showModal) ?
                        (
                            <>
                                <Modal
                                    show={this.state.show}
                                    backdrop='static'
                                    keyboard={false}
                                    dialogClassName='partScaleWarning'
                                    aria-labelledby='contained-modal-title-vcenter1'
                                    centered>
                                    <Modal.Header>
                                        <Modal.Title>Warning</Modal.Title>
                                        <span className="close-btn"
                                            onClick={() => this.setState({ show: false, })}
                                        ></span>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <div style={{ marginTop: '10px', textAlign: 'center' }}>
                                                {this.props.partName ?? "Part"} will be scaled
                                            </div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer className="justify-content-center">
                                        <Button
                                            className='btn-primary'
                                            onClick={() => this.setState({ show: false, })}
                                            autoFocus>
                                            OK
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </>
                        ) :
                        (null)
                }

            </>
        );
    }
}

const PartScaleWarningModal = withRouter(connect(Props, Actions)(Component));

export default PartScaleWarningModal;