import React, { lazy, Suspense } from "react";
import { Dropdown } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  onCancelBuild,
  onMoveBuildOpenDialog,
  onHighlightBuild,
  onRebuild,
  onEnableSendToPrinter,
} from "../../../Actions/Builds";
import {
  onDownloadGcode,
  onDownloadBundle,
  onDownloadLog,
} from "../../../Actions/Home";
import { IBuild, IDisplayPlate, IPlate, IState } from "../../../Interfaces";
import { onNavigateToBuildReviewPage } from "../../../Actions/Home";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { onNavigateToPlatesPage } from "../../../Actions/Home";
import IPartInfo from "../../../../interfaces/IPartInfo";
import DeleteBuildnModal from "./DeleteBuildModal";
import SendToPrinterRebuildModal from "./SendToPrinterRebuildModal";
// import ConfigDetailsModal from "./ConfigDetailsModal";
import { onDeleteBuild } from "../../../Actions/Builds";
import onGetHighlightedBuild from "../../../Actions/Search/highlightBuild";
import LoadingComponent from "../../../../LoadingComponent/LoadingComponent";
import HorizontalLoader from "../../horizontalLoader";
import { onHighlightPlate } from "../../../Actions/Plates";
import { onHighlightPlateObject } from "../../../Actions/Plates";
import onGetHighlightedPlate from "../../../Actions/Search/highlightPlate";
import { getPlate } from "../../../../graphql/queries";
import awsconfig from "../../../../aws-exports";
import { Amplify, Storage } from "aws-amplify";
import { Menu, MenuItem } from "@material-ui/core";

import { NotificationManager } from "react-notifications";

const BuildConfigDetailsModal = lazy(() => import("./ConfigDetailsModal"));
const Props = (state: IState, ownProps: any) => {
  const loadedPlates = state.data.plates.loadedPlates as any;
  const isSearchEnabled = state.data.search.isSearchEnabled as boolean;
  const highlightBuild = state.data.search.highlightedBuildData as IBuild;
  const selectedFolder = state.data.folders.selectedFolderIds as any;

  const props = {
    loadedPlates: loadedPlates as any[],
    displayPlate: ownProps.displayPlate as IDisplayPlate,
    isSearchEnabled: isSearchEnabled as boolean,
    highlightBuild: highlightBuild as IBuild,
    selectedFolder: selectedFolder as any[],
		creator:state.creator
  };

  return props;
};

const Actions = {
  onHighlightBuild: onHighlightBuild,
  onNavigateToBuildReviewPage: onNavigateToBuildReviewPage,
  onCancelBuild: onCancelBuild,
  onRebuild: onRebuild,
  onDeleteBuild: onDeleteBuild,
  onDownloadGcode: onDownloadGcode,
  onDownloadBundle: onDownloadBundle,
  onDownloadLog: onDownloadLog,
  onMoveBuildOpenDialog: onMoveBuildOpenDialog,
  onNavigateToPlatesPage: onNavigateToPlatesPage,
  onGetHighlightedBuild: onGetHighlightedBuild,
  onHighlightPlate: onHighlightPlate,
  onHighlightPlateObject: onHighlightPlateObject,
  onGetHighlightedPlate: onGetHighlightedPlate,
  onEnableSendToPrinter: onEnableSendToPrinter
};

class Component extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      showDeleteConfirmationModal: false,
      showConfigDetailsModal: false,
      sendToPrinterRebuildModal :false,
      anchorEl: null,
      isConfigDisabled: false
    };
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
    event.currentTarget && !this.props.isSearchEnabled
      ? this.props.onHighlightBuild(this.props.displayBuild.id)
      : this.props.onGetHighlightedBuild(this.props.displayBuild.id);
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };


  editPlate = async (plateToBeEdited) => {
    if (
      plateToBeEdited &&
      plateToBeEdited.parts[0] &&
      JSON.parse(plateToBeEdited.parts[0]).Key ===
        `PlatePartsFiles/${plateToBeEdited.id}.json`
    ) {
        const path = `PlatePartsFiles/${plateToBeEdited.id}.json`;
        const AWSBucketParam = {
            Bucket: Storage["_config"]["AWSS3"]["bucket"],
            Key: path,
            CacheControl: 'no-cache' // or 'max-age=0'
        };
        try {
            const getResult = await Storage.get(AWSBucketParam.Key, { download: true,  cacheControl: 'no-cache' });
            plateToBeEdited.parts = JSON.parse(await (getResult.Body as any).text());

        } catch (error:any) {
            NotificationManager.error('An error occurred during the plate editing process');
        }
    }
  };

  getBuild = async (id) => {
    await this.props.onGetHighlightedBuild(id);
  };
  async componentDidMount() {
    Auth.currentAuthenticatedUser().then((user) => {
      //console.log(user);

      const groups =
        user.signInUserSession.accessToken.payload["cognito:groups"] || [];

      //console.log(groups);

      if (groups[0] == "Operator") {
        this.setState({isConfigDisabled:true})
      } else {
        this.setState({isConfigDisabled:false})
      }
    });
  }

  onNavigateToPlatesPage = async (plateId) => {
    const isPlateAvailable = this.props.loadedPlates.some(
      (lp) => lp.id === plateId
    );
    let plateToBeEdited: IPlate = {} as IPlate;
    if (!isPlateAvailable) {
      try {
        const getSelectedPlate = await API.graphql(
          graphqlOperation(getPlate, {
            id: plateId,
          })
        );
        plateToBeEdited = (getSelectedPlate as any).data.getPlate;
        this.props.onHighlightPlate(plateId);
        await this.editPlate(plateToBeEdited);
      } catch (ex: any) {
        console.log(`Exception ${ex.toString()} getting Plates`);
      }
      this.props.onHighlightPlateObject(plateToBeEdited);
    } else {
      this.props.onHighlightPlate(plateId);
      plateToBeEdited = this.props.loadedPlates.find(
        (lp) => lp.id === plateId
      ) as IPlate;
      await this.editPlate(plateToBeEdited);
    }
    const partIds = plateToBeEdited.parts
      .filter(Boolean)
      .map((stringifiedPart) => JSON.parse(stringifiedPart))
      .filter(Boolean)
      .map((part) => part.properties.PartID)
      .filter(Boolean) as string[];
    const parts = plateToBeEdited.parts
      .filter(Boolean)
      .map((stringifiedPart) => JSON.parse(stringifiedPart))
      .filter(Boolean) as IPartInfo[];
    const partConfigurationIds = plateToBeEdited.parts
      .filter(Boolean)
      .map((stringifiedPart) => JSON.parse(stringifiedPart))
      .filter(Boolean)
      .map((part) => part.properties.PartConfig["originalJson"]["id"])
      .filter(Boolean);
    const partSlicerConfigurationIds = plateToBeEdited.parts
      .filter(Boolean)
      .map((stringifiedPart) => JSON.parse(stringifiedPart))
      .filter(Boolean)
      .map((part) => part.properties.SlicerConfig["originalJson"]["id"])
      .filter(Boolean);
    const plateConfigurationIds = [
      plateToBeEdited.machineId,
      plateToBeEdited.millConfigId,
      plateToBeEdited.materialConfigId,
      plateToBeEdited.recipeId,
    ];

    const ids = {
      partIds,
      parts,
      partConfigurationIds,
      partSlicerConfigurationIds,
      plateConfigurationIds,
    };

    this.props.onNavigateToPlatesPage(ids, this.props.history, false);
  };

  handleModalClose = () =>
    this.setState({ showDeleteConfirmationModal: false });
  handleModalShow = () => this.setState({ showDeleteConfirmationModal: true });
  handleConfigDetailsModalShow = () =>
    this.setState({ showConfigDetailsModal: true });
  handleConfigDetailsModalHide = () =>
    this.setState({ showConfigDetailsModal: false });




  isValidJSON(data: any): boolean {
    if (typeof data !== "string") {
      return false;
    }
    try {
      const json = JSON.parse(data);
      return json && typeof json === "object";
    } catch (error) {
      return false;
    }
  }
  //Rebuild -Send To Printer
    handleRebuild = () => {
      const { displayBuild, highlightBuild} = this.props;
      const sendToPrinterData =!this.props.isSearchEnabled ? displayBuild?.sendToPrinter ? JSON.parse(displayBuild.sendToPrinter) : null : highlightBuild?.sendToPrinter ? JSON.parse(highlightBuild.sendToPrinter) : null;
     
      if (sendToPrinterData && sendToPrinterData.sendToPrinter && sendToPrinterData.selectedPrinter !== "") {
        this.props.onEnableSendToPrinter({ isEnabled: sendToPrinterData.sendToPrinter, selectedGrafanaBundle: sendToPrinterData.selectedPrinter })
        this.handleSendToPrinterRebuildModalShow();
        
      } else {
        this.props.onRebuild(!this.props.isSearchEnabled ? displayBuild.id:this.props.highlightBuild.id);
      }
    }
    
    handleRebuildWoSendToPrinter =  () => {
      const { displayBuild,highlightBuild } = this.props;
      const selectedPrinter =!this.props.isSearchEnabled ? displayBuild?.sendToPrinter ?JSON.parse(displayBuild.sendToPrinter).selectedPrinter :null : highlightBuild?.sendToPrinter ?JSON.parse(highlightBuild.sendToPrinter).selectedPrinter : null;
      if(selectedPrinter){
        this.props.onEnableSendToPrinter({ isEnabled: false, selectedGrafanaBundle: selectedPrinter });
        this.props.onRebuild(!this.props.isSearchEnabled ? displayBuild.id:this.props.highlightBuild.id);
        this.handleSendToPrinterRebuildModalHide()
      }
      
    }
    
    handleRebuildWSendToPrinter = () => {
      const { displayBuild, onEnableSendToPrinter,highlightBuild } = this.props;
      const selectedPrinter =!this.props.isSearchEnabled ? displayBuild?.sendToPrinter ?JSON.parse(displayBuild.sendToPrinter).selectedPrinter :null : highlightBuild?.sendToPrinter ?JSON.parse(highlightBuild.sendToPrinter).selectedPrinter : null;
      if(selectedPrinter){
        this.props.onEnableSendToPrinter({ isEnabled: true, selectedGrafanaBundle: selectedPrinter });
        this.props.onRebuild(!this.props.isSearchEnabled ? displayBuild.id:this.props.highlightBuild.id);
        this.handleSendToPrinterRebuildModalHide()
      }
    }

    handleSendToPrinterRebuildModalShow = () => this.setState({ sendToPrinterRebuildModal: true });
    handleSendToPrinterRebuildModalHide = () => this.setState({ sendToPrinterRebuildModal: false });

    recurrsive = () => {
      this.props.onCancelBuild(this.props.displayBuild.id);
    }

  public render() {
    const { anchorEl } = this.state;
    let associatedPlate = "" as string;

    if (!this.props.isSearchEnabled && this.props.displayBuild?.hasPlates) {
      const plateData = this.props.displayBuild.plates[0];
      associatedPlate = this.isValidJSON(plateData)
        ? JSON.parse(plateData)["name"]
        : plateData;
    }

    return (
      <div>
   
        <Suspense
          fallback={<LoadingComponent visible={false}></LoadingComponent>}
        >
      
         
          <BuildConfigDetailsModal
            showModal={this.state.showConfigDetailsModal}
            hideModal={this.handleConfigDetailsModalHide}
            highlightedBuildId={
              !this.props.isSearchEnabled
                ? this.props.displayBuild.id
                : this.props.highlightBuild.id
            }
            buildPlatedId={
              !this.props.isSearchEnabled
                ? this.props.displayBuild.plates?.name
                : this.props.highlightBuild?.plates?.name
            }
            buildInProgress={
              !this.props.isSearchEnabled
                ? this.props.displayBuild.current_status
                : this.props.highlightBuild?.current_status
            }
            showConfigDetailsModal={this.state.showConfigDetailsModal}
          ></BuildConfigDetailsModal>
        </Suspense>

        {/* <ConfigDetailsModal
          
        ></ConfigDetailsModal> */}



        <SendToPrinterRebuildModal
          showModal={this.state.sendToPrinterRebuildModal}
          hideModal={this.handleSendToPrinterRebuildModalHide}
          handleRebuildWoSendToPrinter ={this.handleRebuildWoSendToPrinter}
          handleRebuildWSendToPrinter={this.handleRebuildWSendToPrinter}
          highlightedBuildId={
            !this.props.isSearchEnabled
              ? this.props.displayBuild.id
              : this.props.highlightBuild.id
          }
        ></SendToPrinterRebuildModal>

        <DeleteBuildnModal
          showModal={this.state.showDeleteConfirmationModal}
          hideModal={this.handleModalClose}
          highlightedBuildId={
            !this.props.isSearchEnabled
              ? this.props.displayBuild.id
              : this.props.highlightBuild.id
          }
          handleDelete={() => {
            this.props.onDeleteBuild(
              !this.props.isSearchEnabled
                ? this.props.displayBuild.id
                : this.props.highlightBuild.id,
              this.props.history
            );
            this.handleModalClose();
          }}
        ></DeleteBuildnModal>

        <div>
          <div
            className="display-list-more-action"
            aria-controls={`simple-menu ${this.props.displayBuild.id}`}
            aria-haspopup="true"
            id="dropdown-basic"
            onClick={this.handleClick}
            style={{ position: "relative" }}
          >
            <div className="options right-justify">
              <div className="more-check option">
                <img className="icon more-check" alt="" />
              </div>
            </div>
          </div>
          <Menu
            id={`simple-menu ${this.props.displayBuild.id}`}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            style={{
              position: "absolute",
              top: "38px",
            }}
            disableScrollLock
          >
            {!this.props.isSearchEnabled && (
              <div>
                <MenuItem
                  onClick={() => {
                    this.handleClose();
                    this.handleRebuild()
                   
                  }}
                >
                  Rebuild
                 
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    this.handleClose();
                    this.handleModalShow();
                  }}
                  disabled={this.props.displayBuild.current_status == "processing" || this.props.displayBuild &&  this.props.displayBuild?.created_by !== this.props.creator}

                >
                  Delete
                </MenuItem>

                <MenuItem
                  disabled={
                    this.props.selectedFolder.length === 0
                      ? this.props.displayBuild.current_status !== "completed"
                      : this.props.displayBuild.status !== "completed"
                  }
                  onClick={() => {
                    this.handleClose();
                    this.props.onNavigateToBuildReviewPage(
                      this.props.displayBuild.id,
                      this.props.history
                    );
                  }}
                >
                  Review Build
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    this.handleClose();
                    this.handleConfigDetailsModalShow();
                  }}
                >
                  Config details
                </MenuItem>
                <MenuItem
                  disabled={!this.props.displayBuild?.hasPlates}
                  onClick={() => {
                    this.handleClose();
                    this.onNavigateToPlatesPage(associatedPlate);
                  }}
                >
                  Plate Details - {associatedPlate}
                </MenuItem>
                <MenuItem
                  disabled={
                    this.props.selectedFolder.length === 0
                      ? ["completed", "fail", "canceled"].includes(
                          this.props.displayBuild.current_status
                        )
                      : ["completed", "fail", "canceled"].includes(
                          this.props.displayBuild.status
                        )
                  }
                  onClick={() => {
                    this.handleClose();
                    this.props.onCancelBuild(this.props.displayBuild.id).then(()=>{
                      this.recurrsive()
                    });
                  }}
                >
                  Stop
                </MenuItem>
                {!this.state.isConfigDisabled ? (
                  <MenuItem
                    disabled={
                      this.props.selectedFolder.length === 0
                        ? this.props.displayBuild.current_status !== "completed"
                        : this.props.displayBuild.status !== "completed"
                    }
                    onClick={() => {
                      this.handleClose();
                      this.props.onDownloadGcode(this.props.displayBuild.id);
                    }}
                  >
                    Download gCode
                  </MenuItem>
                ) : null}
                {!this.state.isConfigDisabled ? (
                  <MenuItem
                    onClick={() => {
                      this.handleClose();
                      this.props.onDownloadBundle(this.props.displayBuild.id);
                    }}
                  >
                    Download Bundle
                  </MenuItem>
                ) : null}
                {!this.state.isConfigDisabled ? (
                <MenuItem
                  disabled={
                    this.props.selectedFolder.length === 0
                      ? !["completed", "fail"].includes(
                          this.props.displayBuild.current_status
                        )
                      : !["completed", "fail"].includes(
                          this.props.displayBuild.status
                        )
                  }
                  onClick={() => {
                    this.handleClose();
                    this.props.onDownloadLog(this.props.displayBuild.id);
                  }}
                >
                  Download Log
                </MenuItem>
                ) : null}
                <MenuItem
                  onClick={() => {
                    this.handleClose();
                    this.props.onMoveBuildOpenDialog(
                      this.props.displayBuild.id
                    );
                  }}
                  disabled={true}
                >
                  Move
                </MenuItem>
              </div>
            )}

            {this.props.isSearchEnabled && (
              <div>
                {Object.keys(this.props.highlightBuild).length === 0 ? (
                  <MenuItem style={{ height: "300px" }}>
                    <HorizontalLoader></HorizontalLoader>
                  </MenuItem>
                ) : (
                  <div>
                    <MenuItem
                      onClick={() => {
                        this.handleClose();
                        this.handleRebuild()
                        
                      //  this.props.onRebuild(this.props.highlightBuild.id);
                      }}
                    >
                      Rebuild
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        this.handleClose();
                        this.handleModalShow();
                      }}
                      disabled={this.props.highlightBuild.current_status == "processing" || this.props.highlightBuild &&  this.props.highlightBuild?.created_by !== this.props.creator}                    
                    >
                      Delete
                    </MenuItem>
                    <MenuItem
                      disabled={
                        this.props.highlightBuild.current_status !== "completed"
                      }
                      onClick={() => {
                        this.handleClose();
                        this.props.onNavigateToBuildReviewPage(
                          this.props.highlightBuild.id,
                          this.props.history
                        );
                      }}
                    >
                      Review Build
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        this.handleClose();
                        this.handleConfigDetailsModalShow();
                      }}
                    >
                      Config details
                    </MenuItem>
                    {/* {this.props.highlightBuild?.plates !== null && (
                      <MenuItem
                        onClick={() => {
                          this.handleClose();
                          this.onNavigateToPlatesPage(
                            JSON.parse(this.props.highlightBuild?.plates[0])[
                              "name"
                            ]
                          );
                        }}
                      >
                        Plate Details -{" "}
                        {
                          JSON.parse(this.props.highlightBuild?.plates[0])[
                            "name"
                          ]
                        }
                      </MenuItem>
                    )} */}

                    <MenuItem
                      disabled={
                        this.props.highlightBuild.current_status ===
                          "completed" ||
                        this.props.highlightBuild.current_status === "fail" ||
                        this.props.highlightBuild.current_status === "canceled"
                      }
                      onClick={() => {
                        this.handleClose();
                        this.props.onCancelBuild(this.props.highlightBuild.id);
                      }}
                    >
                      Stop
                    </MenuItem>
                    {!this.state.isConfigDisabled ? (
                    <MenuItem
                      disabled={
                        this.props.highlightBuild.current_status !== "completed"
                      }
                      onClick={() => {
                        this.handleClose();
                        this.props.onDownloadGcode(
                          this.props.highlightBuild.id
                        );
                      }}
                    >
                      Download gCode
                    </MenuItem>
                    ) : null}
                    {!this.state.isConfigDisabled ? (
                      <MenuItem
                        onClick={() => {
                          this.handleClose();
                          this.props.onDownloadBundle(
                            this.props.highlightBuild.id
                          );
                        }}
                      >
                        Download Bundle
                      </MenuItem>
                    ) : null}
                    {!this.state.isConfigDisabled ? (
                    <MenuItem
                      disabled={
                        this.props.highlightBuild.current_status !==
                          "completed" &&
                        this.props.highlightBuild.current_status !== "fail"
                      }
                      onClick={() => {
                        this.handleClose();
                        this.props.onDownloadLog(this.props.highlightBuild.id);
                      }}
                    >
                      Download Log
                    </MenuItem>
                    ) : null}
                    <MenuItem
                      onClick={() => {
                        this.handleClose();
                        this.props.onMoveBuildOpenDialog(
                          this.props.highlightBuild.id
                        );
                      }}
                      disabled={true}
                    >
                      Move
                    </MenuItem>
                  </div>
                 )}
              </div>
            )}
          </Menu>
        </div>
      </div>
    );
  }
}

const DisplayBuildOptions = withRouter(connect(Props, Actions)(Component));

export default DisplayBuildOptions;
